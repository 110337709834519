import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const BrandContainer = ({ product, setProduct }) => {
  const { auth } = useAuth();
  const axios = useAxiosPrivate();
  const [companyBrandOptions, setCompanyBrandOptions] = useState([]);

  const handleAutocompleteChange = (event, newValue) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        brand: newValue ? newValue.value : '',
      };
    });
  };

  // Get brands for company
  const getBrands = async () => {
    if (!auth.user_info.company) return;
    try {
      const { data, status } = await axios.get(
        `/api/brands/company/${auth.user_info.company}`,
      );
      if (status === 200) {
        setCompanyBrandOptions(
          data.data.map((brand) => {
            return {
              value: brand._id,
              label: brand.brand,
            };
          }),
        );
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getBrands();
  }, [auth]);

  return (
    <FormControl>
      <Typography fontSize="1.2rem" mb={1}>
        Product Brand
      </Typography>
      <Autocomplete
        id="new-product-brand-autocomplete"
        options={companyBrandOptions}
        getOptionLabel={(option) => option.label}
        onChange={handleAutocompleteChange}
        value={
          companyBrandOptions.find(
            (option) => option.value === product.brand,
          ) || null
        }
        renderInput={(params) => <TextField {...params} label="Brand" />}
      />
    </FormControl>
  );
};

export default BrandContainer;
