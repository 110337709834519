import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CreateEditBankAccountValidationSchema } from './validationSchema';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import AccountHolderName from '../../../paymentMethods/AccountHolderName';
import BankAccountHolderTypeField from '../../../paymentMethods/BankAccountHolderTypeField';
import { PayoutAccountDetailsContext } from './PayoutAccountsList';
import { updateConnectPayoutAccount } from '../../../../api/stripe/payoutAccounts';
import DropzoneContainer from '../../../dropzoneContainer/DropzoneContainer';
import { createStripeFile } from '../../../../api/stripe/files';
import useAuth from '../../../../hooks/useAuth';
import { updateStripeConnectAccount } from '../../../../api/stripe/connect';

const ManagePayoutAccount = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation', // Translation Namespace
  transPrefix = 'components.stripe.ManagePayoutAccount', // Translation Prefix
  getPayoutAccounts = () => {},
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transFields } = useTranslation('fields');
  const { auth } = useAuth();
  const theme = useTheme();

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);

  const { existingAccountData, setExistingAccountData } = useContext(
    PayoutAccountDetailsContext,
  );

  const [image, setImage] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const initialValues = {
    account_holder_name: existingAccountData.account_holder_name || '',
    account_holder_type: existingAccountData.account_holder_type || '',
  };

  const validationSchema = CreateEditBankAccountValidationSchema();

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    try {
      let accountDetails = Object.entries({
        account_holder_type: values?.account_holder_type,
        account_holder_name: values?.account_holder_name,
      })
        .filter(
          ([key, value]) =>
            value !== '' && value !== null && value !== undefined,
        ) // Remove invalid values
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      // Upload Verification Document
      let verificationDocument = null;
      if (image) {
        const fileUploadRequest = await createStripeFile({
          file: image,
          fileName: image.name,
          filePurpose: 'account_requirement',
          stripeAccount: stripeUserData?.stripe_connect_data?.id,
          authInfo: auth.auth_info,
        });

        if (fileUploadRequest.statusCode === 200) {
          const { data } = fileUploadRequest;
          verificationDocument = data.id;
        }
      }
      // Update Bank Account
      const updatePayoutAccount = await updateConnectPayoutAccount({
        bankAccountID: existingAccountData.id,
        updateParam: accountDetails,
      });

      const upateConnectedAccount = await updateStripeConnectAccount({
        documents: {
          bank_account_ownership_verification: {
            files: [verificationDocument],
          },
        },
      });

      if (upateConnectedAccount.statusCode === 201) {
        // Update stripe User Information
        getStripeAccountDetails();
      }

      if (updatePayoutAccount?.data.statusCode === 201) {
        const response = updatePayoutAccount.data;
        handleRegularResponse({
          open: true,
          status: response.status,
          statusCode: response.statusCode,
          message: response.message,
        });
        setExistingAccountData({});
        formik.resetForm();
        setTimeout(() => {
          onClose();
          getPayoutAccounts();
        }, 100);
      } else {
        const response = updatePayoutAccount.data;
        handleRegularResponse({
          open: true,
          status: response.status,
          statusCode: response.statusCode,
          message: response.message,
        });
      }
    } catch (error) {
      console.error('Error Updatig Account', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const onDrop = useCallback((file, fileType) => {
    if (fileType !== 'stp') {
      setPreviewURL(URL.createObjectURL(file));
    }
    setImage(file);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialErrors={{ keywords: true }}
    >
      {(formik) => {
        const { values, isSubmitting, isValid, resetForm } = formik;
        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle> {t('title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('description')}</DialogContentText>
              <DialogContent>
                <Grid>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    <Typography variant="h6">
                      {t('account_settings')}
                    </Typography>
                    <Stack spacing={2}>
                      {/* Account Holder Type */}
                      <BankAccountHolderTypeField
                        required
                        isLoadingManual={isSubmitting}
                      />

                      {/* Account Holder Name */}
                      <AccountHolderName
                        required
                        isLoadingManual={isSubmitting}
                      />
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {t('verification_details.title')}
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                      {t('verification_details.description')}
                    </Typography>
                  </Grid>
                  {image ? (
                    <Grid item>
                      {isSubmitting ? (
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          sx={{
                            display: 'block',
                            margin: '0 auto',
                            width: '100%',
                            height: 'auto',
                            maxWidth: '500px',
                            maxHeight: '500px',
                            borderRadius: '4px',
                          }}
                        />
                      ) : (
                        <Box
                          margin="0 auto"
                          width="100%"
                          maxWidth="500px"
                          height="auto"
                        >
                          {image.type === 'application/pdf' ? (
                            <embed
                              src={`${previewURL}#page=1`}
                              type="application/pdf"
                              width="100%"
                              height="500px"
                            />
                          ) : (
                            <Box
                              component="img"
                              src={previewURL}
                              alt="verification document preview"
                              sx={{
                                display: 'block',
                                margin: '0 auto',
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                maxWidth: '500px',
                                maxHeight: '500px',
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <DropzoneContainer onDrop={onDrop} maxFiles={1} />

                      <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                        {transFields('upload_file.description')}
                      </Typography>
                    </>
                  )}
                </Grid>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <IntuIconButton
                      type="cancel"
                      onClick={onClose}
                      tooltipTitle="cancel"
                    />
                    <IntuIconButton
                      type="reset"
                      variant="outlined"
                      onClick={() => {
                        setExistingAccountData({});
                        setImage(null);
                        setPreviewURL(null);
                        resetForm();
                      }}
                      sx={{ color: theme.palette.connect.main }}
                      tooltipTitle="reset"
                    />
                  </Stack>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(values, formik);
                    }}
                    tooltipTitle="Add"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { ManagePayoutAccount };
