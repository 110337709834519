import dayjs from 'dayjs';
import i18next from 'i18next';
import * as Yup from 'yup';

const CreateAddPersonVerificationDocumentValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    verification_document: Yup.object({
      file: Yup.string().required(t('verification_document.file.required')),
      type: Yup.string().required(t('verification_document.type.required')),
    }),
  });
};

export { CreateAddPersonVerificationDocumentValidationSchema };
