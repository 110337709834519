import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const DifferentAddressCheckbox = ({
  isDifferentAddress,
  setIsDifferentAddress,
  setShippingAddress,
  setShippingIndividual,
  billingAddress,
  billingIndividual,
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!isDifferentAddress) {
      // console.log('setting to be the same...');
      setShippingAddress({ ...billingAddress });
      setShippingIndividual({ ...billingIndividual });
      setFieldValue('shippingAddress', { ...billingAddress });
      setFieldValue('shippingIndividual', { ...billingIndividual });
    }
  }, [isDifferentAddress, billingAddress, billingIndividual]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isDifferentAddress}
          onChange={(e) => setIsDifferentAddress(e.target.checked)}
          name="differentAddress"
          color="primary"
        />
      }
      label="Ship to a different address?"
    />
  );
};

export default DifferentAddressCheckbox;
