import * as Yup from 'yup';
import i18next from 'i18next';
import blockedDomainList from '../../../data/BlockedDomains.json';

let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createChangeUserEmailValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    new_email: Yup.string()
      .required(t('validation.email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
    userOTP: Yup.string().when('change_password', {
      is: true,
      then: () =>
        Yup.string()
          .required(t('validation.otp.required'))
          .min(4, t('validation.short')),
    }),
  });
};
