import { useContext, useMemo, useState } from 'react';
import { Avatar, Badge, Box, Button } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import UserContext from '../../context/users/UserInfoProvider';
import ChatDialog from './ChatDialog';
import ChatIcon from '@mui/icons-material/Chat';
import SendersDialog from './SendersDialog';
import IntuIconButton from '../buttons/IntuIconButton';
import CloseIcon from '@mui/icons-material/Close';
import getUserImage from '../../helpers/getUserImage';

const ChatsFooterContainer = () => {
  const {
    customerId,
    conversations,
    markMessagesReadForCustomer,
    selectedChatId,
    setSelectedChatId,
    activeChatIds,
    setActiveChatIds,
    isChatDialogOpen,
    setIsChatDialogOpen,
  } = useContext(UserContext);
  const [isSendersDialogOpen, setIsSendersDialogOpen] = useState(false);
  const [selectedSender, setSelectedSender] = useState(null);

  const handleChatClick = async (chat_id) => {
    setSelectedChatId(chat_id);
    setIsChatDialogOpen(true);
    await markMessagesReadForCustomer(chat_id, customerId);
  };

  const handleChatDialogClose = async () => {
    await markMessagesReadForCustomer(selectedChatId, customerId);
    setSelectedChatId(null);
    setSelectedSender(null);
    setIsChatDialogOpen(false);
  };

  const handleRemoveConversation = async (chat_id) => {
    await markMessagesReadForCustomer(selectedChatId, customerId);
    setSelectedChatId(null);
    setSelectedSender(null);
    setIsChatDialogOpen(false);

    removeConversation(chat_id);
  };

  const selectedChat = useMemo(() => {
    return conversations.find((chat) => chat.chat_id === selectedChatId);
  }, [selectedChatId, conversations]);

  const handleSenderChange = (senderName, sender) => {
    const found = conversations.find((c) => c.sender._id === sender._id);

    setSelectedChatId(found.chat_id);

    setActiveChatIds((prevState) => {
      const set = new Set(prevState);
      set.add(found.chat_id);
      return set;
    });

    setSelectedSender(senderName);

    setIsChatDialogOpen(true);
    setIsSendersDialogOpen(false);
  };

  const removeConversation = (chat_id) => {
    setActiveChatIds((prevState) => {
      const set = new Set(prevState);
      set.delete(chat_id);
      return set;
    });
  };

  if (!conversations.length) return null;
  return (
    <>
      <Box
        position="fixed"
        bottom={0}
        right={16}
        zIndex={1}
        display="flex"
        flexDirection="row-reverse"
      >
        <Button
          // color="primary"
          color="secondary"
          variant="contained"
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 'none',
            minWidth: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 2,
          }}
          onClick={() => setIsSendersDialogOpen(true)}
        >
          <ChatIcon />
        </Button>
        {conversations.map((chat) => {
          const isSelected = activeChatIds.has(chat.chat_id);
          if (!isSelected) return null;
          return (
            <Badge
              key={chat.chat_id}
              badgeContent={chat.unread_count}
              color="primary"
              sx={{ marginRight: 2 }}
            >
              <Button
                key={chat.chat_id}
                color="secondary"
                // color="primary"
                variant="contained"
                onClick={() => handleChatClick(chat.chat_id)}
                sx={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: 'none',
                  minWidth: 240,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Avatar src={getUserImage(chat.sender)} alt="profile picture">
                  <FaceIcon sx={{ color: 'primary.dark' }} />
                </Avatar>
                {chat.sender.name}
                <IntuIconButton
                  size="small"
                  type="add"
                  variant="outlined"
                  // tooltipTitle={transButtons('close')}
                  tooltipTitle={'close'}
                  onClick={(event) => {
                    event.stopPropagation();
                    removeConversation(chat.chat_id);
                  }}
                  IconComponent={CloseIcon}
                />
              </Button>
            </Badge>
          );
        })}
      </Box>
      <ChatDialog
        isOpen={isChatDialogOpen}
        selectedChat={selectedChat}
        handleClose={handleChatDialogClose}
        handleRemoveConversation={handleRemoveConversation}
      />
      <SendersDialog
        isOpen={isSendersDialogOpen}
        handleClose={() => setIsSendersDialogOpen(false)}
        selectedSender={selectedSender}
        handleSenderChange={handleSenderChange}
      />
    </>
  );
};

export default ChatsFooterContainer;
