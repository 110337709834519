import { createContext, useContext, useRef, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Box,
  TextField,
  createFilterOptions,
  Skeleton,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import AddIcon from '@mui/icons-material/Add';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { searchBrands } from '../../../api/brandRoutes.js';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { BrandsContext } from '../../../context/brands/BrandsProvider.jsx';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider.jsx';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import ErrorIcon from '@mui/icons-material/Error';

const BrandFormContext = createContext();

const BrandClaimerConsent = ({
  fieldID, // Determine the Name and ID of the neted Text Field
  subsidiaryIDField, // Field ID of the isSubsuduary Field
  parentFieldID, // ID of the company parent ID field
  transNS, // Namespace for translation Value
  transPrefix, // KeyPrefix which holds label and placeholder
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setNewBrandsList, brandClaimer } = useContext(BrandsContext);

  const { toggleCLaimerConsent, setToggleClaimerConsent } =
    useContext(BrandFormContext);

  return (
    <Dialog
      open={toggleCLaimerConsent}
      // sx={{ backgroundColor: 'white' }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dialog.content', {
            ownerName: brandClaimer?.owner_name,
            brand: brandClaimer?.brand,
            claimer: brandClaimer?.claimer,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          {/* Cancel */}
          <Grid item>
            <IntuIconButton
              type="cancel"
              onClick={() => setToggleClaimerConsent(false)}
              tooltipTitle={t('dialog.cancel')}
              tooltipType={t('owner')}
            />
          </Grid>

          {/* Select Brand Button */}
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IntuIconButton
              type="submit"
              tooltipTitle={t('dialog.confirm')}
              tooltipType={t('owner')}
              onClick={async () => {
                const payload = {
                  key: 'owner_id',
                  values: brandClaimer.owner_id,
                };

                try {
                  const getOwnerIdBrands = await searchBrands(payload);

                  if (getOwnerIdBrands.statusCode === 200) {
                    const brands = getOwnerIdBrands.data;

                    setFieldValue(fieldID, brands);
                    setFieldValue(subsidiaryIDField, true);
                    setFieldValue(parentFieldID, brandClaimer?.owner_id);

                    setNewBrandsList([]);
                  } else {
                    handleRegularResponse(getOwnerIdBrands);
                  }
                } catch (error) {
                  handleErrorResponse(error);
                }

                setTimeout(
                  () => setFieldTouched('company.brands', true, true),
                  100,
                );
                setToggleClaimerConsent(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const CompanyBrandsField = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'company.brands', // Determine the Name and ID of the neted Text Field
  claimerName = 'company.name', // Name of the Owner
  claimerID = 'company._id', // Name of the Owner
  subsidiaryIDField = 'company.isSubsidiary', // ID of the isSubsidiary Field,
  parentFieldID = 'company.parent_id', // ID of the Company Parent ID Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.company.CompanyBrandsField', // KeyPrefix which holds label and placeholder
  transition = true, // Fade In
  disabled = false, //Disables the field
  required = false, // Determines wheter the field is required or not
  variant = 'outlined', // Variant of the Field
  allowSelectBrand = true, // Allows existing brands to be selected
  allowOwnerRemoval = true, // Allows owner to remove their brand
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const brandsRef = useRef();
  const { values, setFieldValue, errors, touched, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const claimerNameValue = getNestedValue(values, claimerName);
  const claimerIDValue = getNestedValue(values, claimerID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const {
    brandOptions,
    setBrandOptions,
    newBrandsList,
    setNewBrandsList,
    setBrandClaimer,
    searchBrandsinDatabase,
  } = useContext(BrandsContext);
  const { companyProfile } = useContext(CompanyProfileContext);
  const { handleRegularResponse } = useResponseHandling();
  const { isLoading } = useContext(ProcessingContext);

  const [isLoadingBrands, setIsLoadingBrands] = useState(false);
  const [toggleCLaimerConsent, setToggleClaimerConsent] = useState(false);
  const [searchBrands, setSearchBrands] = useState('');

  const handleBrandSelectionChange = async (e, options, reason, element) => {
    if (reason === 'createOption') {
      //  Create a new Option when user hits enter
      if (brandOptions.length > 0) {
        return false;
      }
      const index = options.length - 1;
      const newOption = {
        brand: element?.option,
        owner_id: claimerIDValue,
      };
      options[index] = newOption;

      setFieldValue(fieldID, options);
      setNewBrandsList([...newBrandsList, newOption]);
    } else if (reason === 'removeOption') {
      if (
        allowOwnerRemoval ||
        (!allowOwnerRemoval && element.option.owner_id !== companyProfile._id)
      ) {
        const filtered = values.company?.brands.filter(
          (item) => item.brand !== element.option.brand,
        );
        setFieldValue(fieldID, options);
        setNewBrandsList([...newBrandsList, filtered]);
      } else if (element.option.owner_id === companyProfile._id) {
        handleRegularResponse({
          open: true,
          status: 'info',
          message: t('removeOwnerError'),
        });
      }
    } else if (
      reason === 'selectOption' &&
      element.option.brand?.search('Add ') >= 0
    ) {
      // Create a new option when user seects add
      const index = options.length - 1;
      const newOption = {
        brand: element?.option.inputValue,
        owner_id: claimerIDValue,
      };
      options[index] = newOption;

      setFieldValue(fieldID, options);
      setNewBrandsList([...newBrandsList, newOption]);
    } else if (reason === 'selectOption') {
      // check if existing brand belongs to company legal name

      if (element.option.owner_name === claimerNameValue) {
        // Brand owner name equals to company name
        setFieldValue(fieldID, options);
      } else {
        // Brand owner is different from user company, ask if user is a location
        setBrandClaimer({
          brand: element.option.brand,
          owner_name: element.option.owner_name,
          owner_id: element.option.owner_id,
          claimer: claimerNameValue,
        });
        setToggleClaimerConsent(true);
      }
    }
  };

  const filter = createFilterOptions();

  return (
    <BrandFormContext.Provider
      value={{ toggleCLaimerConsent, setToggleClaimerConsent }}
    >
      {/* Brand Selection */}
      {isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <Autocomplete
            freeSolo
            autoComplete={false}
            multiple
            required={required}
            disabled={disabled}
            handleHomeEndKeys
            id={fieldID}
            name={fieldID}
            variant={variant}
            value={fieldValue}
            clearicon={<ClearIcon sx={{ color: 'var(--intu-lightGrey)' }} />}
            options={brandOptions}
            getOptionLabel={(option) => option?.brand}
            renderOption={(props, option, element) => {
              if (allowSelectBrand) {
                return option?.brand && option?.owner_name ? (
                  <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <BrandingWatermarkIcon
                          sx={{ color: 'text.secondary' }}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 'calc(100% - 44px)',
                          wordWrap: 'break-word',
                        }}
                      >
                        <Box key={option.brand} component="span">
                          {option?.brand}
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                          {t('ownedBy', {
                            ownerName: option?.owner_name,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                ) : (
                  <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <AddIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid item>
                        <Box key={option.brand} component="span">
                          {option?.brand}
                        </Box>
                      </Grid>
                    </Grid>
                  </li>
                );
              } else {
                const brandExists = brandOptions.some(
                  (option) =>
                    option.brand.toLowerCase() ===
                    element.inputValue.toLowerCase(),
                );
                if (!brandExists && !option.owner_id) {
                  return (
                    <li {...props} key={props.key}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: 'flex', width: 44 }}>
                          <AddIcon sx={{ color: 'text.secondary' }} />
                        </Grid>
                        <Grid item>
                          <Box key={option.brand} component="span">
                            {option?.brand}
                          </Box>
                        </Grid>
                      </Grid>
                    </li>
                  );
                } else {
                  if (option === brandOptions[0]) {
                    return (
                      <li {...props} key={props.key}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: 'flex', width: 44 }}>
                            <ErrorIcon sx={{ color: 'text.secondary' }} />
                          </Grid>
                          <Grid item>
                            <Box component="span">
                              <Typography>{t('exists')}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const matchedObj = options.find(
                (item) =>
                  item.brand.toLowerCase() === params.inputValue.toLowerCase(),
              );
              if (!matchedObj && params.inputValue) {
                filtered.push({
                  inputValue: params.inputValue,
                  brand: `${t('add')} "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            className="form-select-field"
            onChange={handleBrandSelectionChange}
            onBlur={(e) => {
              handleBlur(e);
              setBrandOptions([]);
            }}
            sx={{ paddingBottom: '50px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                inputRef={brandsRef}
                InputProps={{
                  ...params.InputProps,
                  type: 'text',
                  autoComplete: 'off',
                  endAdornment: (
                    <>
                      {isLoadingBrands ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                value={searchBrands}
                onChange={async (e) => {
                  const { value } = e.target;
                  setSearchBrands(value);
                  if (value.length > 2) {
                    setIsLoadingBrands(true);
                    let brandSearch = setTimeout(async () => {
                      await searchBrandsinDatabase(value);
                      setIsLoadingBrands(false);

                      clearTimeout(brandSearch);
                    }, 100);
                  }
                }}
                placeholder={!disabled ? t('placeholder') : null}
                label={t('label')}
                error={fieldError && fieldTouched}
                helperText={fieldError && fieldTouched ? fieldError : null}
              />
            )}
          />
        </FormikFieldWrapper>
      )}
      {/* Brand Claimer Consent Dialog*/}
      <BrandClaimerConsent
        fieldID={fieldID}
        parentFieldID={parentFieldID}
        subsidiaryIDField={subsidiaryIDField}
        transNS={transNS}
        transPrefix={transPrefix}
      />
    </BrandFormContext.Provider>
  );
};

export default CompanyBrandsField;
