import { useState, Suspense, useEffect, useContext } from 'react';
import { Box, CircularProgress, Slider, Stack } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import {
  PerspectiveCamera,
  PresentationControls,
  OrbitControls,
  SpotLight,
  Environment,
  Html,
  useProgress,
  Center,
  useGLTF,
  PivotControls,
} from '@react-three/drei';
import ArrowControlsContainer from './ArrowControlsContainer';
import ColorControlsContainer from './ColorControlsContainer';
import { ProductMeshContext } from './context/ProductMeshContext';

const CanvasLoader = () => {
  const { progress } = useProgress();
  return (
    <Html
      as="div"
      center
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <span className="canvas-loader"></span>
      <p
        style={{
          fontSize: 14,
          color: '#F1F1F1',
          fontWeight: 800,
          marginTop: 40,
        }}
      >
        {progress.toFixed(2)}%
      </p>
    </Html>
  );
};

const GLTFModel = ({ rotationX, rotationY, image, handleSceneLoaded }) => {
  const { scene } = useGLTF(image, true);

  useEffect(() => {
    if (scene && scene.visible) {
      handleSceneLoaded(scene);
    }
  }, [scene]);

  return (
    <primitive
      object={scene}
      receiveShadow={false}
      position={[0, 10, -50]}
      rotation={[rotationY / 10, rotationX / 10, 0]}
      height={500}
    />
  );
};

const CanvasContainer = ({
  image,
  viewMode,
  showSliders = true,
  minHeight = '400px',
}) => {
  const {
    rotationX,
    setRotationX,
    rotationY,
    setRotationY,
    cameraPosition,
    setIsProductImageDirtied,
  } = useContext(ProductMeshContext);
  const [showingArray, setShowingArray] = useState([]);
  const [localScene, setLocalScene] = useState(null);

  const handleRotationXSliderChange = (e) => {
    setRotationX(e.target.value);
    setIsProductImageDirtied(true);
  };

  const handleRotationYSliderChange = (e) => {
    setRotationY(e.target.value);
    setIsProductImageDirtied(true);
  };

  const handleSceneLoaded = (scene) => {
    setLocalScene(scene);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={minHeight}
    >
      <Canvas shadows style={{ minHeight }}>
        {/* <color attach="background" args={['#eee']} /> */}
        <Suspense fallback={<CanvasLoader />}>
          <Environment preset="warehouse" />
          <GLTFModel
            image={image}
            rotationX={rotationX}
            rotationY={rotationY}
            handleSceneLoaded={handleSceneLoaded}
          />
          <OrbitControls maxDistance={200} />
          <ambientLight intensity={1} />
          {/* <directionalLight position={[5, 5, 5]} intensity={1} /> */}
          <PerspectiveCamera position={cameraPosition} makeDefault />
          {/* <PivotControls rotation={[0, 0, rotation]} /> */}
        </Suspense>
      </Canvas>
      {showSliders ? (
        <>
          <Slider
            defaultValue={0}
            type="range"
            min={0}
            max={63}
            value={rotationX}
            onChange={handleRotationXSliderChange}
            sx={{ width: '50%', bottom: 60 }}
            position="absolute"
          />
          <Stack
            sx={{
              position: 'absolute',
              height: 400,
              bottom: '10vh',
              right: '15vw',
            }}
            spacing={1}
            direction="row"
          >
            <Slider
              defaultValue={0}
              type="range"
              min={0}
              max={63}
              value={rotationY}
              onChange={handleRotationYSliderChange}
              sx={{ width: '20%', height: '20vh' }}
              orientation="vertical"
            />
          </Stack>
        </>
      ) : null}
      {/* <ArrowControlsContainer setCameraPosition={setCameraPosition} /> */}
      {viewMode === 'edit' && localScene ? (
        <ColorControlsContainer
          scene={localScene}
          showingArray={showingArray}
          setShowingArray={setShowingArray}
        />
      ) : null}
    </Box>
  );
};

export default CanvasContainer;
