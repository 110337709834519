import { Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import useRefreshToken from '../../hooks/useRefreshToken';
import Cookies from 'js-cookie';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { CompanyProfileProvider } from '../../context/company/CompanyProfileProvider';
import { UserProfileProvider } from '../../context/users/UserProfileProvider';
import { StripeProvider } from '../../context/providers/stripe/StripeContextProvider';
import AuthNavbarComponent from '../navbar/AuthNavBar';
import NonAuthNavBarComponent from '../navbar/NonAuthNavBar';
import AuthContext from '../../context/auth/AuthProvider';

const CheckAuth = () => {
  const { setIsLoading } = useProcessingHandler();
  const refresh = useRefreshToken();
  const { auth, checkAuth, setCheckAuth } = useContext(AuthContext);

  useEffect(() => {
    if (!refresh || auth?.auth_info) {
      setCheckAuth(true);
      return;
    }

    try {
      const cookies = Cookies.get('intu_session_key');

      if (cookies) {
        const verifyRefreshToken = async () => {
          try {
            await refresh();
          } catch (err) {
            console.log(err);
            return;
          } finally {
            setIsLoading(false);
            // setCheckAuth(true);
          }
        };
        // !auth?.auth_info ? verifyRefreshToken() : setIsLoading(false);
        verifyRefreshToken();
      } else {
        setCheckAuth(true);
      }
    } catch (error) {
      console.log('Error while chekcing auth ', error);
    }
  }, [auth.auth_info]);

  return checkAuth ? (
    auth.auth_info ? (
      <CompanyProfileProvider>
        <UserProfileProvider>
          <StripeProvider>
            <AuthNavbarComponent />
            <Outlet />
          </StripeProvider>
        </UserProfileProvider>
      </CompanyProfileProvider>
    ) : (
      <>
        <NonAuthNavBarComponent />
        <Outlet />
      </>
    )
  ) : null;
};

export default CheckAuth;
