import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CartContext } from '../../context/cart/CartContext';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FaceIcon from '@mui/icons-material/Face';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CanvasContainer from '../../components/new-product/CanvasContainer';
import CartTotalsBox from '../../components/cart/CartTotalsBox';
import { useNavigate } from 'react-router-dom';
import { getFileType } from '../../helpers/getFileType';

const CartPage = () => {
  const { cartProducts, setCartProducts, manufacturers } =
    useContext(CartContext);
  const { setFooterConfig } = useNavigationFooter();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedEstimate, setSelectedEstimate] = useState(null);

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig({
      showFooter: true,
    });

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const handleQuantityIncrease = (id) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (id === product.product._id) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleQuantityDecrease = (id) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (id === product.product._id) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleQuantityChange = (p, newQuantity) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (p.product._id === product.product._id) {
          return {
            ...product,
            quantity: newQuantity,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleRemove = (id) => {
    setCartProducts((prevState) => {
      return prevState.filter((product) => id !== product.product._id);
    });
  };

  const handleProceed = () => {
    navigate('/checkout');
  };

  return (
    <Box mt={4} px={4} minHeight="60dvh">
      <Box display="flex">
        <Typography variant="h2">Your Cart</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} minHeight="60dvh">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={4}
        >
          <Typography width={300}>Product</Typography>
          <Typography flex={1}></Typography>
          <Typography flex={0.25} textAlign="center">
            Price
          </Typography>
          <Typography minWidth={146} flex={0.25} textAlign="center">
            Quantity
          </Typography>
          <Typography flex={0.25} textAlign="center">
            Subtotal
          </Typography>
          <Typography flex={0.25}></Typography>
        </Box>
        {Array.from(manufacturers).map(([key, value]) => {
          return (
            <Box key={value._id}>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                p={4}
                bgcolor="secondary.light"
                gap={4}
                height={70}
              >
                <Typography color="primary.light">
                  Manufacturer: {value.name.toUpperCase()}
                </Typography>
              </Box>

              {Object.keys(value.products).map((key) => {
                const product = value.products[key];
                const subtotal = product.product.list_price * product.quantity;
                const fileType = getFileType(product.product.images[0]);
                let image = '';
                if (
                  product.appointedRep &&
                  product.appointedRep.profile_picture
                ) {
                  image = `data:${product.appointedRep.profile_picture.mimetype};base64,${product.appointedRep.profile_picture.buffer.toString('base64')}`;
                }
                return (
                  <Box
                    key={key}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="4px"
                    p={4}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection={isMedium ? 'column' : 'row'}
                    >
                      {fileType === 'png' ? (
                        <Avatar src={product.product.images[0]}>
                          <PrecisionManufacturingIcon
                            style={{
                              fontSize: 60,
                              color: 'primary.main',
                            }}
                          />
                        </Avatar>
                      ) : fileType === 'gltf' ? (
                        <Box minHeight={80} height={80}>
                          <CanvasContainer
                            image={product.product.images[0]}
                            viewMode="view"
                            showSliders={false}
                            minHeight="80px"
                          />
                        </Box>
                      ) : (
                        <Box
                          width={300}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar src={''}>
                            <PrecisionManufacturingIcon
                              style={{
                                color: 'primary.main',
                              }}
                            />
                          </Avatar>
                        </Box>
                      )}
                      <Typography flex={1} mr={2} minWidth={140}>
                        {product.product.product_name}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={isMedium ? 'column' : 'row'}
                      alignItems="center"
                    >
                      <Avatar src={image} alt="profile picture">
                        <FaceIcon sx={{ color: 'primary.dark' }} />
                      </Avatar>
                      <Box ml={2}>
                        <Typography>
                          {product.appointedRep?.first_name}{' '}
                          {product.appointedRep?.last_name}
                        </Typography>
                        <Typography variant="body2">
                          {product.appointedRep?.address.city},{' '}
                          {product.appointedRep?.address.state}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography flex={0.25} textAlign="center">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(product.product.list_price)}
                    </Typography>
                    <Box
                      flex={0.25}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        disabled={product.quantity === 1}
                        onClick={() =>
                          handleQuantityDecrease(product.product._id)
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        type="number"
                        value={product.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            value,
                            Math.max(1, parseInt(e.target.value) || 1),
                          )
                        }
                        inputProps={{ min: 1, style: { textAlign: 'center' } }}
                        sx={{ mx: 1, minWidth: 50, width: 50 }}
                        InputProps={{
                          sx: {
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                WebkitAppearance: 'none',
                                margin: 0,
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                          },
                        }}
                      />
                      <IconButton
                        onClick={() =>
                          handleQuantityIncrease(product.product._id)
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Typography flex={0.25} textAlign="center">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(subtotal)}
                    </Typography>
                    <Box
                      flex={0.25}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IntuIconButton
                        size="small"
                        type="cancel"
                        variant="outlined"
                        tooltipTitle={'Remove'}
                        onClick={() => handleRemove(product.product._id)}
                        IconComponent={DeleteIcon}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <CartTotalsBox
        selectedEstimate={selectedEstimate}
        setSelectedEstimate={setSelectedEstimate}
      />
      <Box display="flex" justifyContent="center" my={4}>
        <Button
          variant="contained"
          disabled={!cartProducts.length}
          onClick={handleProceed}
        >
          Proceed to Checkout
        </Button>
      </Box>
    </Box>
  );
};

export default CartPage;
