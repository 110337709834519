import React, { useContext } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { toTitleCase } from '../../../helpers/toTitleCase';

const IndividualJobTitleField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'title', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', //MUI variant
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.individual.IndividualJobTitleField', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Set Loading State Manually
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        required={required}
        disabled={disabled}
        id={fieldID}
        name={fieldID}
        aria-invalid={errors[fieldID] ? 'true' : 'false'}
        aria-describedby="uidnote"
        variant={variant}
        label={t('label')}
        placeholder={t('placeholder')}
        type="text"
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        onBlurCapture={(e) => {
          const proper = toTitleCase(e.target.value);
          setFieldValue(e.target.name, proper);
          handleBlur(e);
        }}
        inputProps={{
          autoComplete: 'given-name',
        }}
        onBlur={handleBlur}
        value={fieldValue}
        error={
          showInitialErrors
            ? fieldError
              ? true
              : false
            : fieldTouched && fieldError
              ? true
              : false
        }
        helperText={
          showInitialErrors
            ? fieldError
              ? fieldError
              : null
            : fieldError
              ? fieldError
              : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default IndividualJobTitleField;
