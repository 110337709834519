import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';

const PersonOwnershipField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'percent_ownership', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'PersonOwnershipField', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Manually control the Loading State,
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Field Ref
  const ownershippercentageRef = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        disabled={disabled}
        required={required}
        type="number"
        name={fieldID}
        id={fieldID}
        variant="outlined"
        label={t('label')}
        className="form-input-field"
        aria-invalid={errors[fieldID] ? 'true' : 'false'}
        aria-describedby="uidnote"
        placeholder={t('placeholder')}
        inputRef={ownershippercentageRef}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: {
            autoComplete: 'off',
            min: 0,
            step: 1,
          },
        }}
        inputProps={{
          autoComplete: 'off',
          min: 0,
          step: 1,
        }}
        sx={{ width: '100%' }}
        value={fieldValue}
        error={
          showInitialErrors
            ? fieldError
              ? true
              : false
            : fieldTouched && fieldError
              ? true
              : false
        }
        helperText={
          showInitialErrors
            ? fieldError
              ? fieldError
              : null
            : fieldError
              ? fieldError
              : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default PersonOwnershipField;
