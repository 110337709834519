import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CreateNewBankAccountValidationSchema } from './validationSchema';
import CountryField from '../../../address/CountryField';
import BankAccountNumber from '../../../paymentMethods/BankAccountNumber';
import CurrencySelectionField from '../../../currency/CurrencySelectionField';
import BankRoutingNumber from '../../../paymentMethods/BankRoutingNumber';
import { updateStripeConnectAccount } from '../../../../api/stripe/connect';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import AccountHolderName from '../../../paymentMethods/AccountHolderName';
import countryList from '../../../../data/CountryData.json';
import BankAccountTypeField from '../../../paymentMethods/BankAccountTypeField';

const AddPayoutAccount = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation', // Translation Namespace
  transPrefix = 'components.stripe.AddPayoutAccount', // Translation Prefix
  getPayoutAccounts = () => {},
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);

  const [userCountryDetails, setUserCountryDetails] = useState({
    label: 'United States',
    dialCode: '1',
    code: 'US',
    currency: 'USD',
  });

  // Country Data
  useEffect(() => {
    const countryOptions = countryList;
    const userCountry = stripeUserData?.stripe_connect_data?.country || null;

    if (userCountry) {
      const userCountryData = countryOptions.find(
        (element) => userCountry === element.code,
      );
      setUserCountryDetails(userCountryData);
    }
  }, [stripeUserData?.stripe_connect_data?.count, open]);

  const initialValues = {
    country: userCountryDetails, // Bank account's country
    currency: userCountryDetails?.currency, // Bank account's currency
    routing_number: '', // Bank's routing number
    account_number: '', // Bank account number
    account_holder_name:
      stripeUserData?.stripe_connect_data?.business_profile?.name, // Account holder's name
    account_holder_type: stripeUserData?.stripe_connect_data?.business_type, // 'individual' or 'company'
    account_type: 'checking',
    isIBAN: false,
  };

  const validationSchema = CreateNewBankAccountValidationSchema();

  const stripe = useStripe();

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    try {
      const accountDetails = Object.entries({
        country: values?.country?.code,
        currency: values?.currency,
        account_holder_type: values?.account_holder_type,
        routing_number: values?.routing_number,
        account_number: values?.account_number,
        account_holder_name: values?.account_holder_name,
      })
        .filter(
          ([key, value]) =>
            value !== '' && value !== null && value !== undefined,
        ) // Remove invalid values
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      if (!accountDetails.country) throw new Error('Country code is missing');
      if (!accountDetails.currency) throw new Error('Currency is missing');
      if (!accountDetails.account_number)
        throw new Error('Account number is missing');
      if (!accountDetails.account_holder_name)
        throw new Error('Account holder name is missing');

      const requestToken = await stripe.createToken(
        'bank_account',
        accountDetails,
      );

      if (requestToken?.token) {
        const token = requestToken.token;
        // Create Stripe Account
        const updateParam = {
          external_account: token.id,
        };

        const request = await updateStripeConnectAccount(updateParam);

        if (request?.data.statusCode === 201) {
          const response = request.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });
          formik.resetForm();
          getStripeAccountDetails();
          setTimeout(() => {
            onClose();
            getPayoutAccounts();
          }, 100);
        } else {
          const response = request.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });
        }
      } else {
        // Error Requesting Token
        const response = requestToken?.error;
        handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: 400,
          message: response.message,
        });
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialErrors={{ keywords: true }}
    >
      {(formik) => {
        const { values, isSubmitting, isValid } = formik;
        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle> {t('title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('description')}</DialogContentText>
              <DialogContent>
                <Grid>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    <Typography variant="h6">
                      {t('account_settings')}
                    </Typography>
                    <Stack spacing={2}>
                      {/* Country */}
                      <CountryField required isLoadingManual={isSubmitting} />

                      {/* Currency */}
                      <CurrencySelectionField
                        required
                        isLoadingManual={isSubmitting}
                      />

                      {/* Account Type */}
                      <BankAccountTypeField
                        country={userCountryDetails.code}
                        required
                        isLoadingManual={isSubmitting}
                      />
                    </Stack>
                  </Grid>

                  <Grid item>
                    <Typography variant="h6">{t('account_details')}</Typography>
                    <Stack spacing={2}>
                      {/* Account Holder Name */}
                      <AccountHolderName
                        required
                        isLoadingManual={isSubmitting}
                      />

                      {/* Account Number */}
                      <BankAccountNumber
                        required
                        country={userCountryDetails.code}
                        isLoadingManual={isSubmitting}
                      />

                      {/* Routing Number */}
                      <BankRoutingNumber
                        transition={!values.isIBAN || false}
                        disabled={values.isIBAN || false}
                        required
                        isLoadingManual={isSubmitting}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <IntuIconButton
                    type="cancel"
                    onClick={onClose}
                    tooltipTitle="cancel"
                  />
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(values, formik);
                    }}
                    tooltipTitle="Add"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { AddPayoutAccount };
