import { useTranslation } from 'react-i18next';
import CustomerSubscriptionsDashboard from '../../components/account/customer/CustomerSubscriptionsDashboard';
import IntuPage from '../../components/layout/IntuPage';

const SubscriptionsPage = () => {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.account.Subscriptions.title')}
      pageDescription={t('pages.account.Subscriptions.description')}
      hCentered
      lessMT
    >
      <CustomerSubscriptionsDashboard />
    </IntuPage>
  );
};

export default SubscriptionsPage;
