import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import AuthContext from '../../context/auth/AuthProvider';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../../hooks/useProcessingHandler';

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [isAuthorized, setIsAuthorized] = useState(null);
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const { t } = useTranslation('messages');

  useEffect(() => {
    const checkRole = async () => {
      if (auth?.auth_info) {
        const url = '/api/users/roles/role/verify';
        const params = { roles: allowedRoles };

        try {
          setIsLoading({
            status: true,
            text: t('global.authenticating'),
            type: 'spinner',
          });
          const response = await axios.get(url, {
            params,
          });

          const { data, status } = response;

          if (status === 200) {
            setIsAuthorized(data.authorized);
          } else {
            setIsAuthorized(false);
          }
        } catch (err) {
          console.error(err);
          setIsAuthorized(false);
        } finally {
          setIsLoading({
            status: false,
            text: '',
            type: '',
          });
        }
      } else {
        setIsAuthorized(false);
      }
    };

    checkRole();
  }, [auth, allowedRoles, axios]);

  if (isAuthorized === null) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return !auth.auth_info ? (
    <Navigate to="/account/signin" state={{ from: location }} replace />
  ) : isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default RequireAuth;
