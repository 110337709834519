import React, { useContext, useEffect, useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ibanLengths from '../../data/IBANLength.json';

const BankAccountNumber = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'account_number', // Defines the ID and Name of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'BankAccountNumber', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', // Design standard or
  country = 'DE',
  isLoadingManual = false, // Manually control the Loading State
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Field Ref
  const accountNumberRef = useRef();

  // Context
  const { values, setFieldValue, setValues, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  //   Check on IBAN
  useEffect(() => {
    // Regex to check if the string contains at least 2 alphabetic characters
    const ibanRegex = /[a-zA-Z].*[a-zA-Z]/;

    // Ensure the value is a string
    const accountNumber = String(values.account_number || '');

    // Get the required IBAN length for the user's country
    const requiredLength = ibanLengths[country];

    if (
      ibanRegex.test(accountNumber) &&
      requiredLength && // Ensure the country code exists in the mapping
      accountNumber.length === requiredLength
    ) {
      setValues({
        ...values,
        isIBAN: true,
        routing_number: '',
      });
    } else {
      setFieldValue('isIBAN', false);
    }
  }, [values.account_number]);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        disabled={disabled}
        required={required}
        type="tel"
        name={fieldID}
        id={fieldID}
        variant={variant}
        label={t('label')}
        className="form-select-field"
        placeholder={t('placeholder')}
        inputRef={accountNumberRef}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        sx={{ width: '100%' }}
        value={fieldValue}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default BankAccountNumber;
