import { axiosPrivate } from '../../hooks/axios/axios.js';
import i18n from '../../i18n.js';

async function createStripeCustomerSetUpIntent({
  customer, // Customer ID
  param = null, // Payload (Optional)
}) {
  // Create Stripe Customer Sessions
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/customers/create-customer-setupintent';

  const req_fields = [];

  if (!customer) {
    req_fields.push(
      i18n.t('validations:pram', {
        dataType: i18n.t('types:stripe_customer'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  const payload = {
    ...param,
  };

  try {
    const createSetupIntent = await axiosPrivate.post(url, payload, {
      signal,
    });
    console.log(
      'Result from Creating Customer Customer Intent ->',
      createSetupIntent,
    );
    return createSetupIntent.data;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

export { createStripeCustomerSetUpIntent };
