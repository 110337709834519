import { useContext, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import UserContext from '../../context/users/UserInfoProvider';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Transition from './Transition';
import { useTranslation } from 'react-i18next';
import ChatSenders from '../chat/ChatSenders';

const SendersDialog = ({
  isOpen,
  handleClose,
  selectedSender,
  handleSenderChange,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const { conversations } = useContext(UserContext);
  const theme = useTheme();

  const senders = useMemo(() => {
    if (!conversations || !conversations.length) return new Map();

    const sendersMap = new Map();
    conversations.forEach((conversation) => {
      const { _id, name, profile_picture, last_signin } = conversation.sender;
      if (sendersMap.has(name)) {
        const cur = sendersMap.get(name).unread_count;
        sendersMap.set(name, {
          _id,
          profile_picture,
          last_signin,
          unread_count: cur + conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      } else {
        sendersMap.set(name, {
          _id,
          profile_picture,
          last_signin,
          unread_count: conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      }
    });
    return sendersMap;
  }, [conversations]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
        '& .MuiDialog-paper': {
          margin: '0 16px 0 0',
          width: '100%',
        },
      }}
      hideBackdrop
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 0 }}
      >
        <IntuIconButton
          size="small"
          type="add"
          variant="outlined"
          tooltipTitle={'Close'}
          onClick={handleClose}
          IconComponent={MinimizeIcon}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ overflowY: 'scroll' }}
          maxHeight={600}
        >
          <ChatSenders
            senders={senders}
            handleSenderChange={handleSenderChange}
            selectedSender={selectedSender}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SendersDialog;
