import React, { useState, useContext, useRef, useMemo } from 'react';
import ImageIcon from '@mui/icons-material/Image';

import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivateData } from '../../../../hooks/axios/useAxiosPrivate';
import AuthContext from '../../../../context/auth/AuthProvider';
import {
  Box,
  Button,
  Grid,
  Stack,
  CircularProgress,
  Typography,
  Modal,
  FormControl,
} from '@mui/material';

import { Form, Formik, useFormikContext } from 'formik';
import { imageCreationPluginValidation } from './validationSchema';
import ImageLabelField from '../../../image/fields/ImageLabelField';
import ImageDescriptionField from '../../../image/fields/ImageDescriptionField';
import ImageSizeField from '../../../image/fields/ImageSizeField';
import ImageSizeUnit from '../../../image/fields/ImageSizeUnit';
import ImageAlignmentField from '../../../image/fields/ImageAlignment';
import { useTranslation } from 'react-i18next';

const ImageUploadForm = ({ onChange: handleEditorChange, data }) => {
  // States for Loading and Progress
  const [loading, setLoading] = useState(false);

  // Contexts and Hooks
  const { auth } = useContext(AuthContext);
  const axiosData = useAxiosPrivateData();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { t } = useTranslation();
  const { t: transValidations } = useTranslation('validations');
  const { t: transTypes } = useTranslation('types');

  const { values: parentFormValues, setFieldValue: setParentFieldValue } =
    useFormikContext();

  const handleUploadImage = async (file) => {
    // Guard clauses and pre-upload checks
    let req_fields = [];
    if (!file) {
      req_fields.push(transValidations('file.image_required'));
    }

    if (!file.name) {
      req_fields.push(
        transValidations('file.description', { type: transTypes('image') }),
      );
    }

    if (!auth?.user_info?._id) {
      req_fields.push(transValidations('account.info'));
    }

    if (req_fields.length > 0) {
      return handleRegularResponse({
        open: true,
        status: 'error',
        message: req_fields.join(', '),
      });
    }

    // Check Filesize
    if (file.size > 5120000) {
      let fileSize = Math.round(file.size / 1000);
      return handleRegularResponse({
        open: true,
        status: 'warning',
        message: transValidations('file.file_size_msg', { fileSize: fileSize }),
      });
    }

    try {
      // Create a promise to handle the FileReader process
      const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result); // Resolve when reading is complete
          reader.onerror = (error) => reject(error); // Reject in case of an error
        });
      };

      // Convert the file to base64 string
      const base64File = await fileToBase64(file);
      const base64Content = base64File.split(',')[1]; // Extract the base64 content after the comma

      const url = '/api/aws/content/add';
      const requestData = {
        file: base64Content,
        file_type: file.type,
        file_name: file.name,
        folder: `posts/${parentFormValues._id}/${file.name}`, // Assuming parentFormValues._id is defined
      };

      setLoading(true); // Set loading state to true

      // Make the API call with Axios
      const request = await axiosData.post(url, JSON.stringify(requestData), {
        headers: { 'Content-Type': 'application/json' },
      });

      const response = request?.data;

      if (response?.statusCode === 200) {
        return response; // Return the response on success
      } else {
        handleErrorResponse(response); // Handle error response
        return response; // Still return the response even in error cases
      }
    } catch (err) {
      handleErrorResponse(err); // Handle any caught errors
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  // Initial values calculation outside the render function
  const initialValues = useMemo(
    () => ({
      label: data?.label || '',
      description: data?.description || '',
      imageUrl: data?.imageUrl || '',
    }),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={imageCreationPluginValidation}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form role="form" fullWidth>
            <FormControl fullWidth>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Stack direction="column" spacing={2}>
                    {/* Image */}
                    {values.imageUrl ? (
                      <img
                        style={{ width: '100%' }}
                        src={values.imageUrl}
                        alt="Uploaded Image"
                      />
                    ) : null}

                    <input
                      ref={inputRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={async (e) => {
                        const uploadImage = await handleUploadImage(
                          e.target.files[0],
                        );

                        if (uploadImage.statusCode === 200) {
                          setFieldValue('imageUrl', uploadImage.data);
                          handleEditorChange({
                            ...values,
                            imageUrl: uploadImage.data,
                          });
                        }
                        console.log('Upload Image Response ->', uploadImage);
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleButtonClick}
                      disabled={loading}
                      startIcon={
                        loading ? <CircularProgress size={20} /> : null
                      }
                    >
                      {loading ? `Uploading...` : 'Upload Image'}
                    </Button>

                    {/* Label */}
                    <ImageLabelField
                      disabled={loading}
                      variant="standard"
                      fieldID="label"
                      onChange={(e) => {
                        setFieldValue('label', e.target.value);
                        handleEditorChange({
                          ...values,
                          label: e.target.value,
                        });
                      }}
                    />

                    {/* Description */}
                    <ImageDescriptionField
                      disabled={loading}
                      variant="standard"
                      onChange={(e) => {
                        setFieldValue('description', e.target.value);
                        handleEditorChange({
                          ...values,
                          description: e.target.value,
                        });
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

const ImageSizeForm = ({ onChange: handleEditorChange, data }) => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.pageEditor.plugins.image',
  });

  // Initial values calculation outside the render function
  const initialValues = useMemo(
    () => ({
      unit: data?.unit || '%',
      height: data?.height || 100,
      width: data?.width || 100,
      alignment: data?.alignment || 'flex-start',
    }),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form role="form" fullWidth>
            <FormControl fullWidth>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Stack direction="column" spacing={2}>
                    {/* Unit */}
                    <ImageSizeUnit
                      variant="standard"
                      fieldTitle={t('alignment.title')}
                      onChange={(e) => {
                        setFieldValue('unit', e.target.value);
                        handleEditorChange({
                          ...values,
                          unit: e.target.value,
                        });
                      }}
                    />

                    {/* Width */}
                    <ImageSizeField
                      variant="standard"
                      fieldID="width"
                      transPrefix="ImageWidthField"
                      onChange={(e) => {
                        setFieldValue('width', e.target.value);
                        handleEditorChange({
                          ...values,
                          width: e.target.value,
                        });
                      }}
                    />

                    {/* Height */}
                    <ImageSizeField
                      variant="standard"
                      fieldID="height"
                      transPrefix="ImageHeightField"
                      onChange={(e) => {
                        setFieldValue('height', e.target.value);
                        handleEditorChange({
                          ...values,
                          height: e.target.value,
                        });
                      }}
                    />

                    {/* Alignment */}
                    <ImageAlignmentField
                      variant="standard"
                      onChange={(e) => {
                        setFieldValue('alignment', e.target.value);
                        handleEditorChange({
                          ...values,
                          height: e.target.value,
                        });
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

const imagePlugin = {
  Renderer: (props) => {
    // State to control modal visibility
    const [open, setOpen] = useState(false);

    // Function to handle modal open
    const handleOpen = () => setOpen(true);

    // Function to handle modal close
    const handleClose = () => setOpen(false);

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ marginBottom: '2rem' }}>
              <Box
                onClick={handleOpen}
                style={{ cursor: 'pointer' }}
                display="flex"
                alignItems="center"
                justifyContent={props.data?.alignment || 'center'}
                height="100%"
                sx={{
                  marginTop: '12px',
                  // Additional styling for the image
                  '& img': {
                    height: `${props.data.height}${props.data.unit}`,
                    width: `${props.data.width}${props.data.unit}`,
                    maxHeight: {
                      xs: 233,
                      md: `${props.data.height}${props.data.unit}`,
                    },
                    maxWidth: {
                      xs: 350,
                      md: `${props.data.width}${props.data.unit}`,
                    },
                    objectFit: 'contain',
                  },
                }}
              >
                <img alt={props.data.name} src={props.data.imageUrl} />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body2">
              {props.data.description}
            </Typography>
          </Grid>
        </Grid>

        {/* Modal for Image View */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="enlarged-image"
          aria-describedby="enlarged-image-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              outline: 'none',
              minWidth: '80vw',
              maxWidth: '95vw',
              maxHeight: '95vh',
              marginBottom: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt={props.data.name}
              src={props.data.imageUrl}
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '80vh',
              }}
            />
            <Typography align="center" variant="body2" sx={{ mt: 2 }}>
              {props.data.description}
            </Typography>
          </Box>
        </Modal>
      </>
    );
  },
  id: 'contentImage',
  title: 'Image',
  description: 'Add an Image to your page.',
  icon: <ImageIcon />,
  isInlineable: true,
  version: 1,
  controls: [
    {
      title: 'Image',
      description: 'Add an Image to your page.',
      controls: {
        type: 'custom',
        Component: (props) => <ImageUploadForm {...props} />,
      },
    },
    {
      title: 'Alignment',
      description: 'Set the image size',
      controls: {
        type: 'custom',
        Component: (props) => <ImageSizeForm {...props} />,
      },
    },
  ],
};

export default imagePlugin;
