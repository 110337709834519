import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardHeader,
  Typography,
  useTheme,
  Badge,
} from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CanvasContainer from '../new-product/CanvasContainer';
import { getFileType } from '../../helpers/getFileType';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const ProductCard = ({ product }) => {
  const { t: transButtons } = useTranslation('buttons');
  const theme = useTheme();
  const navigate = useNavigate();

  const imageType = useMemo(() => {
    if (!product || !Array.isArray(product.images) || !product.images.length) {
      return null;
    }
    return getFileType(product.images[0]);
  }, [product]);

  const defaultImage = useMemo(() => {
    if (!product || !Array.isArray(product.images) || !product.images.length) {
      return null;
    }
    return product.images[0];
  }, [product]);

  const description = useMemo(() => {
    if (!product.descriptions || !product.descriptions.length) return '';
    if (
      !product.descriptions[0].description ||
      !product.descriptions[0].description.blocks ||
      !product.descriptions[0].description.blocks.length
    )
      return '';
    return product.descriptions[0].description.blocks[0].text;
  }, [product]);

  const isNewProduct = useMemo(() => {
    const productCreationDate = dayjs(product.created);
    return dayjs().diff(productCreationDate, 'week') < 2;
  }, [product]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <Badge
        badgeContent={isNewProduct ? 'NEW' : null}
        // badgeContent={isNewProduct ? '🚀' : null}
        color="primary"
        sx={{ width: '100%', height: '100%' }}
      >
        <Card
          sx={{
            maxWidth: 345,
            width: 345,
            maxHeight: 460,
            boxShadow: theme.shadows[4],
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardHeader
            title={product.product_name}
            subheader={product.brand_name}
          />
          {imageType !== 'gltf' && defaultImage ? (
            <CardMedia
              sx={{ minHeight: 140 }}
              image={defaultImage}
              title={product.product_name}
            />
          ) : imageType === 'gltf' ? (
            <Box minHeight={140} height={140}>
              <CanvasContainer
                image={defaultImage}
                viewMode="view"
                showSliders={false}
                minHeight="140px"
              />
            </Box>
          ) : (
            <Box
              height={140}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="grey.200"
            >
              <PrecisionManufacturingIcon
                style={{ fontSize: 60, color: theme.palette.primary.main }}
              />
            </Box>
          )}
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              minHeight={100}
              maxHeight={100}
              overflow="scroll"
            >
              {description}
            </Typography>
          </CardContent>
          <CardActions
            sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
          >
            <Typography>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: product.currency || 'USD',
              }).format(product.list_price)}
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate(`/product/${product._id}`)}
            >
              {transButtons('details')}
            </Button>
          </CardActions>
        </Card>
      </Badge>
    </motion.div>
  );
};

export default ProductCard;
