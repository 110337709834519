import { axiosPrivate } from '../../hooks/axios/axios.js';

const listStripeCharges = async ({ customerId = null, accountId = null }) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const url = '/api/stripe/charges/list';
  const query = {};

  if (customerId) {
    query.customer_id = customerId;
  }

  if (accountId) {
    query.account_id = accountId;
  }

  try {
    const { data, status } = await axiosPrivate.get(url, {
      query,
      signal,
    });

    return { data, status };
  } catch (error) {
    console.error('error', error);
  } finally {
    controller.abort(signal);
  }
};

export { listStripeCharges };
