import { useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { appearance } from '../../theme/stripe';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ElementsContainer = ({
  paymentIntentSecret,
  children,
  stripeAccount,
}) => {
  const options = {
    clientSecret: paymentIntentSecret,
    appearance,
    ...(stripeAccount && { stripeAccount }),
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default ElementsContainer;
