import {
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledNumberField } from './ProductForm';

const FulfillmentBox = ({ product, setProduct }) => {
  const { errors, touched, handleBlur } = useFormikContext();
  const { dimensions, weight, lead_time } = product;
  const handleDimensionChange = (e) => {
    const name = e.target.name.split('.')[1];
    setProduct((prevState) => {
      return {
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          [name]: e.target.value,
        },
      };
    });
  };

  const handleWeightChange = (e) => {
    const name = e.target.name.split('.')[1];
    setProduct((prevState) => {
      return {
        ...prevState,
        weight: {
          ...prevState.weight,
          [name]: e.target.value,
        },
      };
    });
  };

  const handleLeadTimeChange = (e) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        lead_time: Number(e.target.value),
      };
    });
  };

  const handleCheckboxChange = () => {
    setProduct((prevState) => {
      return {
        ...prevState,
        stock_with: !prevState.stock_with,
      };
    });
  };

  return (
    <>
      <Typography variant="h4" textTransform="none">
        Fulfillment
      </Typography>
      <Box>
        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label="Stock the Product with IN2TEC for just 0.048 US$ per month"
            onChange={handleCheckboxChange}
          />
        </Box>
        <Typography mb={2}>Packaging Dimensions</Typography>
        <FormControl sx={{ mr: 2 }}>
          <StyledNumberField
            id="new-product-list-price-field"
            label="Length"
            name="dimensions.length"
            type="number"
            variant="outlined"
            onChange={handleDimensionChange}
            value={dimensions.length}
            onBlur={handleBlur}
            error={
              touched.dimensions?.length && Boolean(errors.dimensions?.length)
            }
            helperText={touched.dimensions?.length && errors.dimensions?.length}
          />
        </FormControl>
        <FormControl sx={{ mr: 2 }}>
          <StyledNumberField
            id="new-product-list-price-field"
            label="Width"
            name="dimensions.width"
            type="number"
            variant="outlined"
            onChange={handleDimensionChange}
            value={dimensions.width}
            onBlur={handleBlur}
            error={
              touched.dimensions?.width && Boolean(errors.dimensions?.width)
            }
            helperText={touched.dimensions?.width && errors.dimensions?.width}
          />
        </FormControl>
        <FormControl sx={{ mr: 2 }}>
          <StyledNumberField
            id="new-product-list-price-field"
            label="Height"
            name="dimensions.height"
            type="number"
            variant="outlined"
            onChange={handleDimensionChange}
            value={dimensions.height}
            onBlur={handleBlur}
            error={
              touched.dimensions?.height && Boolean(errors.dimensions?.height)
            }
            helperText={touched.dimensions?.height && errors.dimensions?.height}
          />
        </FormControl>
        <FormControl sx={{ mr: 2 }}>
          <InputLabel id="unit-select">Unit</InputLabel>
          <Select
            label="Unit"
            labelId="unit-select"
            id="select"
            value={dimensions.unit}
            onChange={handleWeightChange}
            name="unit"
            // label="Type"
            defaultValue="in"
            onBlur={handleBlur}
            error={touched.dimensions?.unit && Boolean(errors.dimensions?.unit)}
            sx={{ mb: 2 }}
          >
            <MenuItem value="in">
              {/* {t('components.commission.tiered')} */}
              in
            </MenuItem>
            <MenuItem value="cm">cm</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Typography mb={2}>Gross Weight of Product</Typography>
          <FormControl sx={{ mr: 2 }}>
            <StyledNumberField
              id="new-product-list-price-field"
              label="Weight"
              name="weight.weight"
              type="number"
              variant="outlined"
              onChange={handleWeightChange}
              value={weight.weight}
              onBlur={handleBlur}
              error={touched.weight?.weight && Boolean(errors.weight?.weight)}
              helperText={touched.weight?.weight && errors.weight?.weight}
            />
          </FormControl>
          <FormControl sx={{ mr: 2 }}>
            <InputLabel id="unit-select">Unit</InputLabel>
            <Select
              label="Unit"
              labelId="unit-select"
              id="select"
              value={weight.unit}
              onChange={handleWeightChange}
              name="weight.unit"
              defaultValue="lb"
              sx={{ mb: 2 }}
              onBlur={handleBlur}
              error={touched.weight?.unit && Boolean(errors.weight?.unit)}
            >
              <MenuItem value="lb">
                {/* {t('components.commission.tiered')} */}
                lb
              </MenuItem>
              <MenuItem value="kg">kg</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography>Lead Time</Typography>
          <FormControl sx={{ my: 2, mr: 2 }}>
            <StyledNumberField
              id="new-product-list-price-field"
              label="Enter number of days"
              name="lead_time"
              type="number"
              variant="outlined"
              onChange={handleLeadTimeChange}
              value={lead_time}
              // onBlur={formik.handleBlur}
              // error={formik.errors.list_price && formik.touched.list_price}
              // helperText={
              //   formik.errors.list_price && formik.touched.list_price
              //     ? formik.errors.list_price
              //     : ''
              // }
            />
          </FormControl>
          <FormControl sx={{ my: 2, mr: 2 }}>
            <InputLabel id="unit-select">Days</InputLabel>
            {/* <Select
                  label="Unit"
                  labelId="unit-select"
                  id="select"
                  value={weight.unit}
                  onChange={handleWeightChange}
                  name="weight-unit"
                  defaultValue="lb"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="lb">lb</MenuItem>
                  <MenuItem value="kg">kg</MenuItem>
                </Select> */}
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default FulfillmentBox;
