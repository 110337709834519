import React from 'react';
import { Grid, Typography, Card, CardContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toUpperCase } from '../../../../helpers/toTitleCase';

const StripeBalance = ({ stripeUserData }) => {
  const stripeConnectAccountBalance = stripeUserData?.stripe_connect_data
    ?.balance || {
    available: [],
    pending: [],
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.stripe.StripeBalanace',
  });

  const {
    currency = stripeConnectAccountBalance?.available[0]?.currency,
    total_balance = stripeConnectAccountBalance?.available[0]?.amount / 100,
    available_soon = stripeConnectAccountBalance?.pending[0]?.amount / 100,
  } = stripeConnectAccountBalance;

  const data = [
    { label: t('total_balance'), value: total_balance },
    { label: t('available_soon'), value: available_soon },
  ];

  return (
    <Grid sx={{ marginBottom: '3rem' }}>
      <Typography variant="h5" gutterBottom>
        {t('title')}
      </Typography>
      <Divider sx={{ marginBottom: '2rem' }} />
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item md={6} xs={12} key={index}>
            <Card>
              <CardContent>
                <Grid>
                  <Grid item>
                    <Typography variant="bod1">{item.label}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body">
                      {`${item?.value ? item.value.toFixed(2) : 0} ${currency ? toUpperCase(currency) : 'USD'}`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default StripeBalance;
