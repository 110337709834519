import { useEffect, useState, useMemo, useContext } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getPublicProductById,
  trackProductActivity,
} from '../../api/productsRoutes';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { grey } from '@mui/material/colors';
import useUser from '../../hooks/useUser';
import CanvasContainer from '../../components/new-product/CanvasContainer';
import ProductRepresentatives from '../../components/public-product/ProductRepresentatives';
import MessageDialog from '../../components/public-product/MessageDialog';
import UserContext from '../../context/users/UserInfoProvider';
import PublicProductFiles from '../../components/public-product/PublicProductFiles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { getProducRepresentativesById } from '../../api/productsRoutes';
import BuyDialog from './BuyDialog';
import { CartContext } from '../../context/cart/CartContext';
import WarrantyTerms from '../../components/public-product/WarrantyTerms';
import { ReactComponent as Image3DSVG } from '../../assets/new-product/3d-image.svg';

const CarouselItem = ({ image, viewMode }) => {
  if (image.endsWith('.stp')) return null;
  if (image.endsWith('.gltf')) {
    return (
      <CanvasContainer image={image} viewMode={viewMode} showSliders={false} />
    );
  }
  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: grey[200],
        display: 'flex',
        justifyContent: 'center',
        height: '30vh',
      }}
    >
      <Box
        width="80%"
        height="80%"
        mt={4}
        sx={{ objectFit: 'contain' }}
        component="img"
        src={image}
      />
    </Paper>
  );
};

const PublicProductPage = () => {
  const { id } = useParams();
  const { user } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const { conversations, setConversations } = useContext(UserContext);
  const { cartProducts, setCartProducts } = useContext(CartContext);
  const { setFooterConfig } = useNavigationFooter();
  const params = new URLSearchParams(window.location.href);
  const referralID = params.get('referalID');

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isShort = useMediaQuery('(max-height:950px)');

  const [product, setProduct] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (amount) => {
    setQuantity((prev) => Math.max(1, prev + amount));
  };

  const productId = useMemo(() => {
    let productId = id;
    if (id.includes('&')) {
      productId = id.split('&')[0];
    }
    return productId;
  }, [id]);

  const getProduct = async () => {
    const response = await getPublicProductById(productId);
    setProduct(response);
  };

  const trackProduct = async (productId, user) => {
    await trackProductActivity(productId, user, referralID);
  };

  useEffect(() => {
    //  Send request to acknowledge referral
    if (user.sessionID && productId) {
      trackProduct(productId, user);
    }
  }, [productId, user]);

  useEffect(() => {
    getProduct();
  }, []);

  const handleMessage = (rep) => {
    setSelectedRep(rep);
    setIsMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setSelectedRep(null);
    setIsMessageDialogOpen(false);
  };

  const description = useMemo(() => {
    if (!product || !product.descriptions || !product.descriptions.length)
      return '';
    if (
      !product.descriptions[0].description ||
      !product.descriptions[0].description.blocks ||
      !product.descriptions[0].description.blocks.length
    )
      return '';
    return product.descriptions[0].description.blocks[0].text;
  }, [product]);

  const handleSetChats = (newChats) => {
    setConversations(newChats);
    setIsMessageDialogOpen(false);
  };

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig({
      showFooter: true,
    });

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const handleBuyClick = () => {
    // Check if product already exists in cart
    const productInCart = cartProducts.find(
      (p) => p.product._id === product._id,
    );
    if (productInCart) {
      navigate('/cart');
      setCartProducts((prev) => {
        return prev.map((p) => {
          if (p.product._id === productInCart.product._id) {
            return {
              ...p,
              quantity: p.quantity + quantity,
            };
          }
          return p;
        });
      });
      return;
    }

    setIsBuyDialogOpen(true);
  };

  const [productRepresentatives, setProductRepresentatives] = useState([]);

  const getProductRepresentatives = async () => {
    const response = await getProducRepresentativesById(productId);
    if (referralID) {
      setProductRepresentatives(
        response.filter((rep) => rep._id === referralID),
      );
      return;
    }
    setProductRepresentatives(response);
  };

  useEffect(() => {
    getProductRepresentatives();
  }, []);

  const handleSkip = () => {
    navigate('/cart');
    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: null,
        },
      ];
    });
  };

  const handleBuyConfirm = (rep) => {
    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: rep,
        },
      ];
    });
    navigate('/cart');
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const imageIndicators = useMemo(() => {
    if (!product || !product.images) return null;
    return product.images.map((image) => {
      if (image.endsWith('.gltf')) {
        return <Image3DSVG width="50px" height="50px" />;
      }
      return (
        <img
          key={image}
          alt={image}
          src={image}
          style={{ width: '50px', height: '50px', margin: '0 4px' }}
        />
      );
    });
  }, [product]);

  if (!product) return <Skeleton height="60vh" />;
  return (
    <Box mt={4} px={4} minHeight="80dvh">
      <Typography variant="h1" mt={2}>
        {product.product_name}
      </Typography>
      <Typography variant="h6">{product.author.manufacturer_name}</Typography>
      <Carousel
        style={{ flex: 1, minHeight: '700px' }}
        index={activeIndex}
        animation="slide"
        navButtonsAlwaysVisible={product.images.length > 1}
        autoPlay={false}
        swipe={false}
        IndicatorIcon={imageIndicators}
        indicatorContainerProps={{
          style: {
            background:
              product.images.length > 0
                ? theme.palette.secondary.light
                : 'initial',
            margin: isShort ? '180px auto 0' : '0 auto',
            width: '30%',
            borderRadius: '5px',
            padding: '10px 0',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            zIndex: 100,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            background: theme.palette.primary.main,
          },
        }}
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosNewIcon />}
        navButtonsProps={{
          style: {
            display: product.images.length > 1 ? 'inline-flex' : 'none',
          },
        }}
        width={{ xs: '60vw', md: '100vw' }}
        height="40vh"
        onChange={(now) => setActiveIndex(now)}
      >
        {product.images.map((image, i) => {
          return <CarouselItem key={i} image={image} viewMode="view" />;
        })}
      </Carousel>
      <Box
        display={isSmall ? 'none' : 'flex'}
        flexDirection="row"
        gap={4}
        mt={4}
      >
        {/* Column 1 */}
        <Box flex={0.5}>
          <Typography variant="h6">Documents</Typography>
        </Box>
        {/* Column 2 */}
        <Box flex={1}>
          <Typography variant="h6">Product Description</Typography>
        </Box>
        {/* Column 3 */}
        <Box flex={0.5}>
          <Typography variant="h6">Technical Contact(s)</Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection={isSmall ? 'column' : 'row'}
        gap={4}
        mt={4}
        minHeight="60vh"
      >
        {/* Column 1 */}
        <Box flex={0.5}>
          <PublicProductFiles files={product.files} images={product.images} />
          <Typography mt={2} mb={1} variant="body1">
            Product Applications:
          </Typography>
          <Box>
            {product.product_applications.map((application) => {
              return (
                <Chip
                  key={application}
                  label={application}
                  variant="outlined"
                  sx={{
                    m: 1,
                  }}
                />
              );
            })}
          </Box>
          {/* <Typography mt={2} mb={1} variant="body1">
            Product Types:
          </Typography>
          <Box>
            {product.product_types.map((type) => {
              return (
                <Chip
                  key={type._id}
                  label={type.name_en}
                  variant="outlined"
                  sx={{
                    m: 1,
                  }}
                />
              );
            })}
          </Box> */}
        </Box>
        {/* Column 2 */}
        <Box flex={1}>
          <Typography variant="p">{description}</Typography>
          <Typography variant="h6" textTransform="none" mt={2}>
            Manufacturers Part Number
          </Typography>
          <Typography variant="p">{product.mpn}</Typography>
          <Box mt={4}>
            <WarrantyTerms product={product} />
          </Box>
        </Box>
        {/* Column 3 */}
        <Box flex={0.5}>
          <ProductRepresentatives
            productRepresentatives={productRepresentatives}
            handleMessage={handleMessage}
            chats={conversations}
          />
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isSmall ? 'column' : 'row'}
            mt={2}
            width="100%"
            gap={2}
          >
            <Box display="flex" alignItems="center">
              <IconButton onClick={() => handleQuantityChange(-1)}>
                <RemoveIcon />
              </IconButton>
              <TextField
                type="number"
                value={quantity}
                onChange={(e) =>
                  setQuantity(Math.max(1, parseInt(e.target.value) || 1))
                }
                inputProps={{ min: 1, style: { textAlign: 'center' } }}
                sx={{ mx: 1, minWidth: 50, width: 50 }}
                InputProps={{
                  sx: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  },
                }}
              />
              <IconButton onClick={() => handleQuantityChange(1)}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBuyClick}
              sx={{ ml: 2, width: '100%' }}
            >
              Buy
            </Button>
          </Box>
          <Box
            mt={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="p">
              Starting at:{' '}
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: product.currency,
              }).format(Number(product.list_price).toFixed(2))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <MessageDialog
        isOpen={isMessageDialogOpen}
        selectedRep={selectedRep}
        handleClose={handleMessageDialogClose}
        product={product}
        handleSetChats={handleSetChats}
      />
      <BuyDialog
        isOpen={isBuyDialogOpen}
        handleClose={() => setIsBuyDialogOpen(false)}
        handleConfirm={handleBuyConfirm}
        handleSkip={handleSkip}
        productName={product.product_name}
        quantity={quantity}
        productRepresentatives={productRepresentatives}
      />
    </Box>
  );
};

export default PublicProductPage;
