import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import useResponseHandling from './useResponseHandler';
import { useProcessingHandler } from './useProcessingHandler';
import { useAxiosPrivate } from '../hooks/axios/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../context/providers/IntuThemeProvider';
import UserContext from '../context/users/UserInfoProvider';

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { setUserTheme } = useContext(IntuThemeContext);
  const { setConversations } = useContext(UserContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const axios = useAxiosPrivate();

  const { t: transMessages } = useTranslation('messages');

  async function logoutUser() {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: transMessages('spinner.signout'),
    });
    try {
      const response = await axios.get('/api/users/account/signout', {
        withCredentials: true,
        credentials: 'same-origin',
      });
      const { data, status } = response;
      if (status === 200) {
        setAuth({ auth_info: null, user_info: null });
        setUserTheme({
          active: false,
          logo_url: '',
          logo_alt: 'IN2TEC',
          primary: '#A8C957',
          secondary: '333333',
          tertiary: '#A10976',
          contrastColor: '#ffffff',
          textColor: '#000000',
        });
      }
      navigate('./account/signin');
      handleRegularResponse({
        open: true,
        status: data.status,
        message: data.message,
      });
      setConversations([]);
      setIsLoading({ status: false, type: '', text: '' });
    } catch (err) {
      handleErrorResponse(err);
    }
  }
  return logoutUser; // Return the logoutUser function to call manually
};

export default useLogout;
