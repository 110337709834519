import { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';
import { convertFileToBase64 } from '../../helpers/convertFileToBase64';
import { getFileName } from './ProductFiles';
import ResponseContext from '../../context/providers/ResponseProvider';
import IntuIconButton from '../buttons/IntuIconButton';
import DropzoneContainer from '../dropzoneContainer/DropzoneContainer';

const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

const formatDataLabel = ({ attribute, value }) => {
  return `${formatKey(attribute)} : ${value}`;
};

const findFileInFiles = (files, file) =>
  files.find((f) => getFileName(f) === file);

const UploadDocumentDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  productId,
  productFiles,
  productAttributeOptions,
}) => {
  const { t: transDialog } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const axiosData = useAxiosPrivateData();
  const { setStatusMsg } = useContext(ResponseContext);

  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const onDrop = useCallback((file) => {
    if (file) {
      setFile(file);
      handleFileChange(file);
    }
  }, []);

  const handleFileChange = async (file) => {
    if (!file) return null;

    // Check if product has max 10 documents or if this file already exists on product
    if (
      productFiles.length === 10 ||
      findFileInFiles(productFiles, file.name)
    ) {
      setStatusMsg({
        open: true,
        status: 'error',
        message:
          productFiles.length === 10
            ? 'You can only have 10 files'
            : 'You can not upload duplicate files',
      });
      return null;
    }

    if (file.type === 'application/pdf') {
      try {
        setPreviewURL(URL.createObjectURL(file));
        setFile(file);

        const base64File = await convertFileToBase64(file);

        const requestData = {
          file: base64File,
          file_type: 'pdf',
          file_name: file.name,
          folder: `products/${productId}/documents`,
        };

        const { data, status } = await axiosData.post(
          '/api/aws/content/add',
          JSON.stringify(requestData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (status === 200) {
          setFileUrl(data.data);
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  };

  const closeDialog = () => {
    setPreviewURL(null);
    handleClose();
  };

  const onConfirm = () => {
    setFile(null);
    setPreviewURL(null);
    handleConfirm(fileUrl, null);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth={file ? 'md' : 'sm'}
      fullWidth
    >
      <DialogTitle>{transDialog('UploadDocumentDialog.title')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        {!previewURL ? <DropzoneContainer onDrop={onDrop} /> : null}
        {file && (
          <Typography variant="body2" mt={2}>
            Selected file: {file.name}
          </Typography>
        )}
        <Box display="flex" gap={2} mt={4}>
          {previewURL ? (
            <Box minWidth={400}>
              <embed
                src={`${previewURL}#page=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          paddingBottom: '16px',
          justifyContent: 'space-evenly',
        }}
      >
        <IntuIconButton
          size="small"
          type="cancel"
          variant="outlined"
          tooltipTitle={transButtons('close')}
          onClick={closeDialog}
        />
        <IntuIconButton
          size="small"
          type="submit"
          variant="outlined"
          tooltipTitle={transButtons('confirm')}
          onClick={onConfirm}
          disabled={!file || !fileUrl}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UploadDocumentDialog;
