import { axiosPrivateData } from '../../hooks/axios/axios';
import i18n from '../../i18n.js';

// Create Sripe Customer
async function createStripeFile({
  file,
  fileName,
  filePurpose,
  stripeAccount,
  authInfo,
}) {
  // Create Stripe Customer Sessions
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/files/add';

  const req_fields = [];

  if (!authInfo) {
    req_fields.push(i18n.t('validations:auth'));
  }

  if (!file) {
    req_fields.push(i18n.t('validations:file.file'));
  }

  if (!fileName) {
    req_fields.push(i18n.t('validations:file.file_name'));
  }

  if (!filePurpose) {
    req_fields.push(i18n.t('validations:file.purpose'));
  }

  if (!stripeAccount) {
    req_fields.push(
      i18n.t('validations:id', { type: i18n.t('types:stripe_account') }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', fileName);
  formData.append('purpose', filePurpose);
  formData.append('stripe_account', stripeAccount);

  try {
    const createStripeFile = await axiosPrivateData.post(url, formData, {
      headers: {
        Authorization: `Bearer ${authInfo}`, // Add the Authorization header
      },
      signal,
    });

    return createStripeFile.data;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

export { createStripeFile };
