import { useContext } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Stack,
  Skeleton,
  Fade,
} from '@mui/material';
import { classes } from '../../settings/theme.js';
import countryList from '../../data/CountryData.json';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import ProcessingContext from '../../context/providers/ProcessingProvider.jsx';
import IntuThemeContext from '../../context/providers/IntuThemeProvider.jsx';

const CountryFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'country', //Determines the field ID and Name
  autocompleteID = 'country', //Determines the Autocomplete Values
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'AddressField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the Field transitions fade in, fade out
  disabled = false, // Whether the field is disabled
  required = false, // Whether the Field is required or not
  variant = 'outlined', // Design standard or
  isLoadingManual = false, // Manually control the Loading State
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // SetUp Context
  const { values, setFieldTouched, setFieldValue, errors, touched } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const autocompleteValue = autocompleteID
    ? getNestedValue(values, autocompleteID)
    : {};
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  // Country Data
  const countryOptions = countryList;

  const { isLoading } = useContext(ProcessingContext);

  const { transitionInterval } = useContext(IntuThemeContext);

  return isLoading.status || isLoadingManual ? (
    <Stack spacing={4} style={classes.root}>
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    </Stack>
  ) : (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
            sx={{ marginBottom: '1rem' }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {transition && (
        <Stack spacing={4} style={classes.root}>
          {/* Country */}
          <Fade in={transition} timeout={transitionInterval}>
            <Autocomplete
              required={required}
              disabled={disabled}
              autoComplete={false}
              id={fieldID}
              name={autocompleteValue}
              autoSelect={true}
              disableClearable
              options={countryOptions}
              getOptionLabel={(option) => option?.label || ''}
              isOptionEqualToValue={(option, value) =>
                option?.code === value?.code
              }
              onChange={async (e, option) => {
                setFieldValue(fieldID, option);
              }}
              onBlur={async (e) => {
                setFieldTouched(fieldID, true, true);
              }}
              defaultValue={countryOptions[231]}
              value={fieldValue || null}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  key={option.code}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option?.label}
                </Box>
              )}
              className="form-select-field"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  variant={variant}
                  label={!disabled ? t(`${fieldID}.label`) : null}
                  placeholder={!disabled ? t(`${fieldID}.placeholder`) : null}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  error={
                    showInitialErrors
                      ? fieldError
                        ? true
                        : false
                      : fieldError && fieldTouched
                        ? true
                        : false
                  }
                  helperText={
                    fieldError?.label && fieldTouched ? fieldError.label : null
                  }
                />
              )}
            />
          </Fade>
        </Stack>
      )}
    </>
  );
};

const CountryField = (props) => {
  return <CountryFieldContent {...props} />;
};

export default CountryField;
