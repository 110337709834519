import { useContext, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import OrderTotalsBox from '../../components/checkout/OrderTotalsBox';
import { Formik, useFormikContext } from 'formik';
import AddressField from '../../components/address/AddressField';
import * as Yup from 'yup';
import i18next from 'i18next';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField';
import MobileNumberField from '../../components/account/MobileNumberField';
import EmailField from '../../components/account/fields/email/EmailField';
import { CartContext } from '../../context/cart/CartContext';
import DifferentAddressCheckbox from '../../components/checkout/DifferentAddressCheckbox';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const AddressFieldWrapper = ({ onBlur, ...props }) => {
  const { values } = useFormikContext();
  const handleBlur = () => {
    if (onBlur) {
      onBlur(values.billingAddress);
    }
  };
  return (
    <div onBlur={handleBlur}>
      <AddressField
        labelID={values?.individual?.name}
        fieldID="billingAddress"
        transNS="fields"
        transPrefix="AddressField"
        {...props}
      />
    </div>
  );
};

const FirstNameFieldWrapper = ({ onBlur, ...props }) => {
  const { values } = useFormikContext();
  const handleBlur = () => {
    if (onBlur) {
      const [first, second] = props.fieldID.split('.');
      onBlur(values[first][second]);
    }
  };
  return (
    <Box onBlur={handleBlur} flex={1}>
      <IndividualFirstNameField {...props} />
    </Box>
  );
};

const LasttNameFieldWrapper = ({ onBlur, ...props }) => {
  const { values } = useFormikContext();
  const handleBlur = () => {
    if (onBlur) {
      const [first, second] = props.fieldID.split('.');
      onBlur(values[first][second]);
    }
  };
  return (
    <Box onBlur={handleBlur} flex={1}>
      <IndividualLastNameField {...props} />
    </Box>
  );
};

const MobileNumberFieldWrapper = ({ onBlur, ...props }) => {
  const { values } = useFormikContext();
  const handleBlur = () => {
    if (onBlur) {
      onBlur(values.mobile);
    }
  };
  return (
    <div onBlur={handleBlur}>
      <MobileNumberField {...props} />
    </div>
  );
};

const EmailFieldWrapper = ({ onBlur, ...props }) => {
  const { values } = useFormikContext();
  const handleBlur = () => {
    if (onBlur) {
      const [first, second] = props.fieldID.split('.');
      onBlur(values[first][second]);
    }
  };
  return (
    <div onBlur={handleBlur}>
      <EmailField transTypeID="Customer" {...props} />
    </div>
  );
};

export const createCartTotalsValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    billingAddress: Yup.object({
      line1: Yup.string()
        .min(5, t('short'))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short'))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short'))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short'))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    shippingAddress: Yup.object({
      line1: Yup.string()
        .min(5, t('short'))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short'))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short'))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short'))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    creditCard: Yup.object({
      nameOnCard: Yup.string()
        .required(t('credit_card.name_required'))
        .min(2, t('credit_card.name_min')),
      number: Yup.string()
        .required(t('credit_card.number_required'))
        .matches(/^[0-9]{16}$/, t('credit_card.number_invalid')),
      expiration: Yup.string()
        .required(t('credit_card.expiration_required'))
        .matches(
          /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
          t('credit_card.expiration_invalid'),
        ),
      cvc: Yup.string()
        .required(t('credit_card.cvc_required'))
        .matches(/^[0-9]{3,4}$/, t('credit_card.cvc_invalid')),
    }),
  });
};

const CheckoutPage = () => {
  const axios = useAxiosPrivate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { setFooterConfig } = useNavigationFooter();
  const { cartTotal, manufacturers } = useContext(CartContext);

  const [billingAddress, setBillingAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: { label: 'United States', dialCode: '1', code: 'US' },
  });
  const [shippingAddress, setShippingAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: { label: 'United States', dialCode: '1', code: 'US' },
  });

  const [billingIndividual, setBillingIndividual] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [shippingIndividual, setShippingIndividual] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [mobilePhone, setMobilePhone] = useState({
    info: {},
    value: '',
  });

  const [isDifferentAddress, setIsDifferentAddress] = useState(false);

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig({
      showFooter: true,
    });
    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const handleBillingAddressFieldBlur = (billingAddress) => {
    setBillingAddress({
      ...billingAddress,
    });
  };

  const handleBillingFirstNameFieldBlur = (first_name) => {
    setBillingIndividual({
      ...billingIndividual,
      first_name,
    });
  };

  const handleBillingLasttNameFieldBlur = (last_name) => {
    setBillingIndividual({
      ...billingIndividual,
      last_name,
    });
  };

  const handleEmailFieldBlur = (email) => {
    setBillingIndividual({
      ...billingIndividual,
      email,
    });
  };

  const handleMobileNumberFieldBlur = (number) => {
    setMobilePhone({
      ...mobilePhone,
      ...number,
    });
  };

  const handleShippingFirstNameFieldBlur = (first_name) => {
    setShippingIndividual({
      ...shippingIndividual,
      first_name,
    });
  };

  const handleShippingLastNameFieldBlur = (last_name) => {
    setShippingIndividual({
      ...shippingIndividual,
      last_name,
    });
  };

  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const createPaymentIntent = async () => {
    const productsPayload = Array.from(manufacturers).map(([key, value]) => {
      const products = Object.keys(value.products).map((productKey) => ({
        _id: productKey,
        quantity: value.products[productKey].quantity,
      }));

      const total = products.reduce((sum, product) => {
        const productTotal =
          product.quantity *
          (value.products[product._id].product.list_price * 100);
        return sum + productTotal;
      }, 0);

      return {
        _id: key,
        stripe_acc_id: value.stripe_acc_id,
        products,
        total,
      };
    });

    const payload = {
      amount: Math.round(cartTotal * 100),
      manufacturers: productsPayload,
    };

    const { data, status } = await axios.post(
      `/api/stripe/payment-intents/public/create`,
      payload,
    );

    if (status === 201) {
      setPaymentIntentSecret(data.data.client_secret);
      setOrderId(data.data.order_id);
    }
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  return (
    <Box mt={4} px={2} minHeight="60dvh">
      <Formik
        initialValues={{
          billingAddress,
          shippingAddress,
          mobile: mobilePhone,
        }}
        validationSchema={createCartTotalsValidationSchema()}
      >
        <Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="h2">Checkout</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} minHeight="60dvh">
            <Box
              flex={1}
              display="flex"
              justifyContent="space-evenly"
              flexDirection={isSmall ? 'column' : 'row'}
              gap={2}
              my={2}
            >
              <Box flex={0.4} display="flex" flexDirection="column">
                <Typography
                  variant="p"
                  height={42}
                  display="flex"
                  alignItems="center"
                >
                  Billing Details
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={1}
                  mb={4}
                  gap={2}
                >
                  <FirstNameFieldWrapper
                    fieldID="billingIndividual.first_name"
                    onBlur={handleBillingFirstNameFieldBlur}
                  />
                  <LasttNameFieldWrapper
                    fieldID="billingIndividual.last_name"
                    onBlur={handleBillingLasttNameFieldBlur}
                  />
                </Box>
                <AddressFieldWrapper onBlur={handleBillingAddressFieldBlur} />
                <Box my={4}>
                  <MobileNumberFieldWrapper
                    onBlur={handleMobileNumberFieldBlur}
                  />
                </Box>
                <Box my={1}>
                  <EmailFieldWrapper
                    fieldID="billingIndividual.email"
                    onBlur={handleEmailFieldBlur}
                  />
                </Box>
              </Box>
              <Box flex={0.4}>
                <DifferentAddressCheckbox
                  isDifferentAddress={isDifferentAddress}
                  setIsDifferentAddress={setIsDifferentAddress}
                  setShippingAddress={setShippingAddress}
                  setShippingIndividual={setShippingIndividual}
                  billingAddress={billingAddress}
                  billingIndividual={billingIndividual}
                />
                {isDifferentAddress && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={1}
                      mb={4}
                      gap={2}
                    >
                      <FirstNameFieldWrapper
                        fieldID="shippingIndividual.first_name"
                        onBlur={handleShippingFirstNameFieldBlur}
                      />
                      <LasttNameFieldWrapper
                        fieldID="shippingIndividual.last_name"
                        onBlur={handleShippingLastNameFieldBlur}
                      />
                    </Box>
                    <AddressFieldWrapper />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <OrderTotalsBox
            shippingAddress={shippingAddress}
            paymentIntentSecret={paymentIntentSecret}
            orderId={orderId}
          />
        </Box>
      </Formik>
    </Box>
  );
};

export default CheckoutPage;
