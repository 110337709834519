import { useContext } from 'react';

import { Grid, Container } from '@mui/material';

//  Blog Header & Footer
import PostHeaderContainer from '../containers/PostHeaderContainer.jsx';

// Meta Tags
import { Helmet } from 'react-helmet';

// Convert to Title Case
import { toTitleCase } from '../../../helpers/toTitleCase.js';

// Get Context
import { PostContext } from '../../../context/providers/PostContextProvider.jsx';

import { IntuPageBuilderReadOnly } from '../../pageEditor/IntuPageBuilderReadOnly.jsx';
import { BlogFooterComponent } from '../footers/BlogFooterComponent.jsx';

const PostViewForm = () => {
  const { postInfo } = useContext(PostContext);

  return (
    <>
      <Helmet>
        <title>
          IN2TEC - {postInfo?.title ? toTitleCase(postInfo.title) : ''}
        </title>
        <meta name="description" content={postInfo?.description} />
        <meta
          property="og:title"
          content={postInfo?.title ? toTitleCase(postInfo.title) : ''}
        />
        <meta property="og:image" content={postInfo?.image_url} />
        <meta property="og:description" content={postInfo?.description} />
        <meta property="og:url" content={postInfo?.full_url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@IN2TEC" />
        <meta
          name="twitter:title"
          content={postInfo?.title ? toTitleCase(postInfo.title) : ''}
        />
        <meta name="twitter:description" content={postInfo?.description} />
        <meta name="twitter:image" content={postInfo?.image_url} />
      </Helmet>
      <Container maxWidth="auto" disableGutters>
        {/* Header Container */}
        <PostHeaderContainer />
        {/* <Container maxWidth="auto" className="intu__page"> */}
        {/* Blogs Overview Container */}
        <Grid container direction="column" maxWidth="xl" className="intu__page">
          <IntuPageBuilderReadOnly values={postInfo.content} />
        </Grid>
        <BlogFooterComponent content={postInfo} />
      </Container>
    </>
  );
};

export { PostViewForm };
