import { useRef, useEffect, useState, useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { TextField, CircularProgress, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ProcessingContext from '../../context/providers/ProcessingProvider';

const CompanyTowMonthField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'tow_months', // Defines the Name and ID of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.company.CompanyTowMonthField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Disables the field
  required = false, // Whether the field is required or not
  type, // The type for the translation
  variant = 'outlined', // Variant of the field, standard or outlined
}) => {
  const towMonthRef = useRef();
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { isLoading } = useContext(ProcessingContext);
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    validateField,
    setFieldError,
  } = useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (touched?.fieldID) {
      setLoading(true);
      validateField(fieldID).finally(() => {
        setLoading(false);
      });
    }
  }, [values?.fieldID]);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        required={required}
        disabled={disabled}
        autoComplete="off"
        id={fieldID}
        name={fieldID}
        ref={towMonthRef}
        className="form-select-field"
        variant={variant}
        label={
          !disabled
            ? t('label', {
                type: type ? type : 'personal',
              })
            : ''
        }
        placeholder={
          !disabled
            ? t('placeholder', {
                type: type ? type : 'personal',
              })
            : ''
        }
        sx={{ paddingBottom: '50px' }}
        type="text"
        onChange={(e) => {
          setFieldValue(fieldID, e.target.value);
        }}
        InputProps={{
          endAdornment: loading ? <CircularProgress size={24} /> : null,
        }}
        value={fieldValue || ''}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default CompanyTowMonthField;
