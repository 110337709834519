import { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from '../../hooks/useAuth';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate';
import { createNewProductValidationSchema } from './NewProductValidationSchema';
import CommissionBox from '../../components/commissions/ComissionBox';
import ProductFiles from '../../components/new-product/ProductFiles';
import ProductImageHeader from '../../components/new-product/ProductImageHeader';
import ProductDescription from '../../components/new-product/ProductDescription';
import ProductForm from '../../components/new-product/ProductForm';
import UploadDocumentDialog from '../../components/new-product/UploadDocumentDialog';
import StatusBarComponent from '../../components/status/StatusBar';
import ResponseContext from '../../context/providers/ResponseProvider';
import FulfillmentBox from '../../components/new-product/FulfillmentBox';
import SearchCompanyCommission from '../../components/new-product/SearchCompanyCommission';
import ExtractDataDialog from '../../components/new-product/ExtractDataDialog';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import StatusBox from '../../components/new-product/StatusBox';

const snakeCaseAttribute = (attribute) => {
  return attribute.toLowerCase().split(' ').join('_');
};

const commission_schedule = {
  commission: [
    { id: 0, max_amount: 1_000, commission: 0.45, cost: null },
    { id: 1, max_amount: 10_000, commission: 0.4, cost: null },
    { id: 2, max_amount: 50_000, commission: 0.35, cost: null },
    { id: 3, max_amount: 100_000, commission: 0.3, cost: null },
    { id: 4, max_amount: 250_000, commission: 0.27, cost: null },
    { id: 5, max_amount: 500_000, commission: 0.25, cost: null },
    { id: 6, max_amount: 1_000_000, commission: 0.2, cost: null },
  ],
  type: 'tiered',
};

const defaultProductState = {
  currency: 'USD',
  product_name: '',
  mpn: '',
  brand: null,
  list_price: 0,
  minimum_price: 0,
  dimensions: {
    length: 0,
    width: 0,
    height: 0,
    unit: 'in',
  },
  weight: {
    weight: 0,
    unit: 'lb',
  },
  product_types: [],
  product_attributes: [],
  product_applications: [],
  commission_schedule,
  descriptions: [
    {
      name: 'English',
      code: 'en',
    },
  ],
  images: [],
  files: [],
  lead_time: 0,
  stock_with: false,
  status: 'draft',
};

const StyledNewProductPage = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  width: '100%',
  display: 'grid',
  gridTemplateAreas: `
    "image image image image"
    "form form form docs"
    "description description description description"
    "fulfillment fulfillment fulfillment fulfillment"
    "status status status status"
  `,
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridTemplateRows: '0.5fr 2fr 0.5fr 0.5fr 0.5fr',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateAreas: `
    "image image image image"
    "form form form form"
    "docs docs docs docs"
    "description description description description"
    "fulfillment fulfillment fulfillment fulfillment"
    "status status status status"
  `,
    // gridTemplateRows: '1fr 2fr 0.5fr 0.5fr 0.5fr',
  },
}));

const NewProductPage = ({ isEdit = false }) => {
  const { t: transValidation } = useTranslation('validation');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { auth } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();
  const { setStatusMsg } = useContext(ResponseContext);
  const { setFooterConfig } = useNavigationFooter();

  const [product, setProduct] = useState(defaultProductState);
  const [isFormValid, setIsFormValid] = useState(isEdit ? true : false);
  const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] =
    useState(false);
  const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] = useState(false);
  const [isExtractDataDialogOpen, setIsExtractDataDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const formikRef = useRef(null);

  const initialValues = useMemo(() => ({ ...product }), [product]);
  const validationSchema = createNewProductValidationSchema(transValidation);

  const [convertedListPrice, setConvertedListPrice] = useState(0);
  const [convertedMinimumPrice, setConvertedMinimumPrice] = useState(0);

  const getProductById = async (id) => {
    try {
      const { data, status } = await axios.get(`/api/products/${id}`);
      if (status === 200) {
        setProduct({
          ...data.data,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getProductById(id);
    } else {
      // Fetch company and get commission schedule
      // TODO: check why companyId works locally and not on dev
      return;
      getCommissionTemplate();
    }
  }, []);

  const handleSave = async (currentProduct) => {
    setIsSaving(true);
    // formikRef.current.submitForm();
    const list_price =
      currentProduct.currency === 'USD'
        ? currentProduct.list_price
        : convertedListPrice;

    const minimum_price =
      currentProduct.currency === 'USD'
        ? currentProduct.minimum_price
        : convertedMinimumPrice;
    try {
      const payload = {
        ...currentProduct,
        list_price,
        minimum_price,
      };
      const { data, status } = await axiosData.put(
        `/api/products/update/${id}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setStatusMsg({
          open: true,
          status: data.status,
          message: data.message,
        });
        navigate('/app/manage-products/list');
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: error.message,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Setup navigation footer
  useEffect(() => {
    const confirmLabel = isEdit
      ? transButtons('update', { type: transTypes('product') })
      : transButtons('add', { type: transTypes('product') });

    setFooterConfig({
      confirmLabel,
      ConfirmIcon: SaveAltIcon,
      onConfirm: () => handleSave(product),
      isLoading: false,
      isDisabled: true,
      showFooter: true,
      onBack: handleCancel,
      showConfirm: true,
    });

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  useEffect(() => {
    // Update only isDisabled and isLoading properties
    setFooterConfig((prev) => ({
      ...prev,
      isLoading: isSaving, // Track saving state
      isDisabled: !isFormValid || isSaving, // Track form validity and saving state
    }));
  }, [isFormValid, isSaving]);

  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      onConfirm: () => handleSave(product),
    }));
  }, [product]);

  const getCommissionTemplate = async () => {
    const [companyId] = auth.user_info.company;
    const commission_schedule = await getCompanyCommission(companyId);
    setProduct((prevState) => {
      return {
        ...prevState,
        commission_schedule,
      };
    });
  };

  const getCompanyCommission = async (companyId) => {
    try {
      const { data, status } = await axios.get(
        `/api/companies/company/details/${companyId}`,
        {
          withCredentials: true,
        },
      );

      if (status === 200) {
        // Add ids because datagrid rows require it
        const generatedCommissions =
          data.data.commission_schedule.commission.map((c, i) => {
            return {
              ...c,
              id: i,
            };
          });

        const commission_schedule = {
          commission: generatedCommissions,
          type: data.data.commission_schedule.type,
        };

        return commission_schedule;
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleAddCommission = (commission) => {
    const newCommission = {
      ...commission,
      id: product.commission_schedule.commission.length,
    };
    setProduct({
      ...product,
      commission_schedule: {
        ...product.commission_schedule,
        commission: [...product.commission_schedule.commission, newCommission],
      },
    });
  };

  const handleEditCommission = (commission) => {
    const newCommissions = product.commission_schedule.commission.map((c) =>
      c.id === commission.id ? commission : c,
    );
    setProduct({
      ...product,
      commission_schedule: {
        ...product.commission_schedule,
        commission: [...newCommissions],
      },
    });
  };

  const handleDeleteCommission = (commission) => {
    const newCommissions = product.commission_schedule.commission.filter(
      (c) => c.id !== commission.id,
    );
    setProduct({
      ...product,
      commission_schedule: {
        ...product.commission_schedule,
        commission: newCommissions,
      },
    });
  };

  const handleCommissionTypeChange = (newType) => {
    setProduct({
      ...product,
      commission_schedule: {
        commission: newType === 'tiered' ? commission_schedule.commission : [],
        type: newType,
      },
    });
  };

  const handleDeleteFile = async (file) => {
    const fileName = file.substring(file.lastIndexOf('/') + 1);

    try {
      const payload = {
        fileName,
      };
      // Remove from collection
      const { data, status } = await axios.put(
        `/api/products/update/${id}/delete-file`,
        payload,
      );

      if (status === 200) {
        setProduct((prevState) => {
          return {
            ...prevState,
            files: prevState.files.filter((f) => f !== file),
          };
        });
      }

      const bucketPayload = {
        folder: `products/${id}/documents/${fileName}`,
      };

      // Remove from DO bucket
      await axios.post('/api/aws/content/delete', bucketPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setStatusMsg({
        open: true,
        status: 'success',
        message: data.message,
      });
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: error.message,
      });
    }
  };

  const [productAttributeOptions, setProductAttributeOptions] = useState(
    new Set(),
  );

  const handleSelectedExtractedData = (extractedData) => {
    if (!extractedData) return;
    extractedData.forEach(async (extractedAttribute) => {
      const found = productAttributeOptions.find(
        (attribute) =>
          attribute.attribute ===
            snakeCaseAttribute(extractedAttribute.attribute) &&
          attribute.attribute_type ===
            extractedAttribute.attribute_type.toLowerCase() &&
          attribute.value === extractedAttribute.value,
      );

      if (found) {
        setProduct((prevState) => {
          return {
            ...prevState,
            product_attributes: [...prevState.product_attributes, found],
          };
        });
        return;
      }

      // Make api call to add product attribute to DB collection
      // TODO: change to make one call to /add-many
      const payload = {
        attribute: snakeCaseAttribute(extractedAttribute.attribute),
        attribute_type: extractedAttribute.attribute_type.toLowerCase(),
        value: extractedAttribute.value,
      };

      const { data, status } = await axios.post(
        '/api/product-attributes',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        extractedAttribute._id = data.data.insertedId;
      }

      setProduct((prevState) => {
        return {
          ...prevState,
          product_attributes: [
            ...prevState.product_attributes,
            extractedAttribute,
          ],
        };
      });
    });
  };

  const handleNewImage = (remoteUrl) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        images: [...prevState.images, remoteUrl],
      };
    });
    setIsUploadImageDialogOpen(false);
  };

  const handleEditImage = (remoteUrl, editingImage) => {
    setProduct((prevState) => {
      const filtered = prevState.images.filter((i) => i !== editingImage);
      return {
        ...prevState,
        images: [...filtered, remoteUrl],
      };
    });
    setIsUploadImageDialogOpen(false);
  };

  const handleDeleteImage = async (file) => {
    const fileType = file.substring(file.lastIndexOf('.') + 1, file.length);
    const fileName = file.substring(file.lastIndexOf('/') + 1, file.length);
    const folder = `products/${id}/images/${fileType}/${fileName}`;

    const bucketPayload = {
      folder,
    };

    try {
      const { status, data } = await axios.post(
        '/api/aws/content/delete',
        bucketPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setStatusMsg({
          open: true,
          status: data.status,
          message: data.message,
        });
        setProduct((prevState) => {
          return {
            ...prevState,
            images: prevState.images.filter((img) => img !== file),
          };
        });
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: error.message,
      });
    }
  };

  const handleCancel = async () => {
    if (isEdit) {
      return;
    }

    try {
      // Delete documents and images from DO
      if (product.images.length || product.files.length) {
        const bucketPayload = {
          folder: `products/${id}`,
        };
        await axios.post('/api/aws/content/delete', bucketPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      await axios.delete(`/api/products/${id}`);
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleDocumentUpload = (fileUrl, selectedExtractedData) => {
    handleSelectedExtractedData(selectedExtractedData);
    setIsUploadDocumentDialogOpen(false);
    setProduct((prevState) => {
      return {
        ...prevState,
        files: [...prevState.files, fileUrl],
      };
    });
  };

  const handleExtractDataConfirm = (selectedExtractedData) => {
    handleSelectedExtractedData(selectedExtractedData);
    setIsExtractDataDialogOpen(false);
  };

  const handleSetDefaultImage = (image) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        images: [image, ...prevState.images.filter((img) => img !== image)],
      };
    });
  };

  const isIntutecAdmin = useMemo(() => {
    return auth.user_info.account_type === 'intutec';
  }, [auth.user_info.account_type]);

  const handleExtractData = () => {
    setIsExtractDataDialogOpen(true);
  };

  // console.log('product in prnt**', product);
  // const { brandOptions, brandClaimer } = useContext(BrandsContext);
  return (
    <StyledNewProductPage>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnMount
        enableReinitialize
      >
        <>
          <Box gridArea="image" py={6}>
            <ProductImageHeader
              productImages={product.images}
              handleNewImage={handleNewImage}
              handleEditImage={handleEditImage}
              handleDeleteImage={handleDeleteImage}
              handleSetDefaultImage={handleSetDefaultImage}
              isUploadImageDialogOpen={isUploadImageDialogOpen}
              setIsUploadImageDialogOpen={setIsUploadImageDialogOpen}
              productId={id}
            />
          </Box>
          <Box gridArea="form" py={2} px={4}>
            <ProductForm
              product={product}
              setProduct={setProduct}
              setIsFormValid={setIsFormValid}
              productAttributeOptions={productAttributeOptions}
              setProductAttributeOptions={setProductAttributeOptions}
              handleExtractData={handleExtractData}
              setConvertedListPrice={setConvertedListPrice}
              setConvertedMinimumPrice={setConvertedMinimumPrice}
            />

            {isIntutecAdmin ? (
              <SearchCompanyCommission setProduct={setProduct} />
            ) : null}
            <CommissionBox
              commissions={product.commission_schedule.commission}
              commissionType={product.commission_schedule.type}
              handleCommissionTypeChange={handleCommissionTypeChange}
              handleAddCommission={handleAddCommission}
              handleEditCommission={handleEditCommission}
              handleDeleteCommission={handleDeleteCommission}
            />
          </Box>
          <Box gridArea="docs" py={2} px={4}>
            <ProductFiles
              files={product.files}
              handleDeleteFile={handleDeleteFile}
              setIsUploadDocumentDialogOpen={setIsUploadDocumentDialogOpen}
            />
          </Box>
          <Box gridArea="description" py={2} px={4}>
            <ProductDescription
              descriptions={product.descriptions}
              setProduct={setProduct}
              setIsSaving={setIsSaving}
            />
          </Box>
          <Box gridArea="fulfillment" pt={2} px={4} pb={8}>
            <FulfillmentBox product={product} setProduct={setProduct} />
          </Box>
          <Box gridArea="status" pt={2} px={4} pb={8}>
            <StatusBox product={product} setProduct={setProduct} />
          </Box>
        </>
      </Formik>
      <UploadDocumentDialog
        isOpen={isUploadDocumentDialogOpen}
        handleClose={() => setIsUploadDocumentDialogOpen(false)}
        handleConfirm={handleDocumentUpload}
        productId={id}
        productFiles={product.files}
        productAttributeOptions={productAttributeOptions}
      />
      <ExtractDataDialog
        isOpen={isExtractDataDialogOpen}
        handleClose={() => setIsExtractDataDialogOpen(false)}
        handleConfirm={handleExtractDataConfirm}
        productId={id}
        productAttributeOptions={productAttributeOptions}
      />
      <StatusBarComponent />
    </StyledNewProductPage>
  );
};

export default NewProductPage;
