import * as Yup from 'yup';

export const createNewProductValidationSchema = (t) => {
  return Yup.object({
    product_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .required(t('product.name_required')),
    mpn: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .required(t('product.mpn_required')),
    list_price: Yup.number()
      .positive('Price must be a positive number')
      .required(t('product.price_required')),
    // minimum_price: Yup.number().positive('Price must be a positive number'),
    //   .required(t('product.price_required')),
    currency: Yup.string().required('Required'),
    //   product_types: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.min')),
    //   product_attributes: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.min')),
    //   product_applications: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.app_min')),
    dimensions: Yup.object({
      length: Yup.number()
        .positive('Must be a positive number')
        .required('Required'),
      width: Yup.number()
        .positive('Must be a positive number')
        .required('Required'),
      height: Yup.number()
        .positive('Must be a positive number')
        .required('Required'),
      unit: Yup.string()
        .oneOf(['in', 'cm'], 'Invalid unit')
        .required('Required'),
    }),
    weight: Yup.object({
      weight: Yup.number()
        .positive('Must be a positive number')
        .required('Required'),
      unit: Yup.string()
        .oneOf(['lb', 'kg'], 'Invalid unit')
        .required('Required'),
    }),
    status: Yup.string().required('Required'),
    brand: Yup.string().required('Required'),
    images: Yup.array()
      .of(Yup.string().required('An image is required'))
      .min(1, t('product.image_required')),
  });
};
