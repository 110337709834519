import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { CompanyLeadsList } from '../../components/company/leads/CompanyLeadsList';
import IntuPage from '../../components/layout/IntuPage';

const CompanyLeads = () => {
  const { t } = useTranslation();
  return (
    <IntuPage>
      <IntuForm
        form="Leads Dashboard"
        formTitle={t('pages.company.companyLeads.title')}
      >
        <CompanyLeadsList />
      </IntuForm>
    </IntuPage>
  );
};

export default CompanyLeads;
