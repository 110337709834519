import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  Skeleton,
  useTheme,
  Card,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import { PieChartContext } from '../../../charts/pieChart';
import ClusteredMap from '../../../components/maps/ClusteredMap';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useParams } from 'react-router-dom';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler';
import { CustomerMapContext } from '../../../context/providers/maps/CustomerMapProvider';
import { IntuRichTextEditor } from '../../../components/IntuRichTextEditor/IntuRichtTextEditor';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import { WarrantyTosDialog } from '../../../components/profile/WarrantyTosDialog';
import { ProfileHeaderComponent } from '../../../components/profile/ProfileHeaderComponent';
import { ProfileBlurbComponent } from '../../../components/profile/ProfileBlurbComponent';
import { ProfileAnalyticsChart } from '../../../components/profile/ProfileAnalyticsChart';
import i18n from '../../../i18n';
import ApplyDialog from './ApplyDialog';
import { CompanyPublicProfileContext } from '../../../context/providers/profile/CompanyPublicProfileProvider';
import { PublicProfileContext } from '../../../context/providers/profile/PublicProfileProvider';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PaidIcon from '@mui/icons-material/Paid';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigationFooter } from '../../../context/footer/NavigationFooterContext';

function getPastSixMonths(arr) {
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const filtered = arr?.filter((item) => {
    const itemDate = new Date(item.order_date);
    return itemDate >= sixMonthsAgo;
  });
  return filtered;
}

function getAverageRevenue(arr) {
  let total = 0;
  let count = 0;
  for (let i = 0; i < arr?.length; i++) {
    total += arr[i].grand_total;
    count++;
  }
  return total / count;
}

function getAverageTurnaround(arr) {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  let total_duration = 0;
  let count = 0;
  arr?.forEach((item) => {
    const create_date = new Date(item.created.date);
    const close_date = new Date(item.close_date);
    const create_dateMs = create_date.getTime();
    const close_dateMs = close_date.getTime();

    const difference = Math.abs(create_dateMs - close_dateMs);
    total_duration += Math.round(difference / oneDay);
    count++;
  });
  return total_duration / count;
}

// Get Average Product Commission (Based on $100,000 in sales)
// TODO: CHANGE TO ROLLING COMMISSION (6 MONTHS)
function calculateAverageProductCommission(commission_schedule) {
  let payout = 0;
  let totalSales = 100000;
  commission_schedule?.forEach((tier) => {
    if (tier.max_amount < totalSales) {
      totalSales -= tier.max_amount;
      payout += tier.max_amount * tier.commission;
    } else {
      payout += (tier.max_amount - totalSales) * tier.commission;
    }
  });
  return +(payout / totalSales).toFixed(2);
}

function calculateAverageCompanyCommission(orderItems) {
  let line_totals = 0;
  let total_commissions = 0;
  orderItems?.forEach((orderItem) => {
    line_totals += Number(orderItem.line_total);
    total_commissions += Number(orderItem.commission);
  });
  return ((total_commissions / line_totals) * 100).toFixed(2);
}

export const CompanyPublicProfileContent = () => {
  const {
    publicCompanyInfo,
    setPublicCompanyInfo,
    getCompanyPublicProfile,
    getAssociateIndustries,
    companyOpportunites,
    setCompanyOpportunities,
    associateColumns,
    getRowId,
    handleClose,
    applyToRepresent,
    isOpen,
    setIsOpen,
    companyOrderItems,
    setCompanyOrderItems,
    handleSearchRelationships,
    handleGetAssociates,
    getProductGroup,
    companyProducts,
    setCompanyProducts,
    productColumns,
  } = useContext(CompanyPublicProfileContext);
  const {
    handleSearchOffers,
    handleSearchOrders,
    formatDataByMonth,
    handleSearchOpportunities,
    handleSearchOrderItems,
    orders,
    setOrders,
    offers,
    setOffers,
    isChartLoading,
    setIsChartLoading,
    isLoadingMap,
    setIsLoadingMap,
    orderChartData,
    setOrderChartData,
    offerChartData,
    setOfferChartData,
    opportunityChartData,
    setOpportunityChartData,
    chartSettings,
    activeChartData,
    setActiveChartData,
    updateChartData,
    handleGetProducts,
    handleGetApplications,
  } = useContext(PublicProfileContext);
  const {
    zoomLevel,
    center,
    setMapCenterAndZoom,
    clusteredMapData,
    setClusteredMapData,
  } = useContext(CustomerMapContext);
  const { id } = useParams();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const [isLoadingPieChart, setIsLoadingPieChart] = useState(true);
  const [isLoadingDataGrid, setIsLoadingDataGrid] = useState(true);
  const [conditionType, setConditionType] = useState('');
  const [associateData, setAssociateData] = useState([]);
  const [companyMiscInfo, setCompanyMiscInfo] = useState({
    launch_date: '',
    salesreps: '',
    sales_yoy: 0,
    average_commission: 0,
    average_turnaround: 0,
  });
  const [pieChartData, setPieChartData] = useState([]);
  const [conversionRate, setConversionRate] = useState(0);
  const [averageRevenue, setAverageRevenue] = useState(0);
  const [isApplyDialogOpen, setIsApplyDialogOpen] = useState(false);
  const [rawProducts, setRawProducts] = useState([]);

  const { mobileView, mode } = useContext(IntuThemeContext);
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.PublicProfile',
  });
  const { t: transMessages } = useTranslation('messages', {
    keyPrefix: 'PublicProfile',
  });
  const { setFooterConfig } = useNavigationFooter();

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig({
      showFooter: true,
    });

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  async function preLoadInfo() {
    setIsLoading({
      status: true,
      text: `Loading ${publicCompanyInfo?.name}'s Profile`,
      type: 'spinner',
    });
    const orderArr = [];
    const offerArr = [];
    try {
      const companyInfo = await getCompanyPublicProfile(id);
      const companyOrders = await handleSearchOrders(id, 'manufacturer');
      companyOrders?.forEach((order) => {
        orderArr.push(order._id);
      });
      const orderItems =
        orderArr.length > 0
          ? await handleSearchOrderItems(orderArr, 'parent_id')
          : [];
      const rawProductsResponse = await handleGetProducts(companyInfo.email);

      setRawProducts(rawProductsResponse);
      const productPromises = rawProducts.map(async (product) => {
        const productFamily = await getProductGroup(
          product?.product_types?.product_group,
        );
        const productApplication = await handleGetApplications(
          product?.product_applications?._id,
        );

        return {
          ...product,
          product_types: {
            ...product.product_types,
            product_family: productFamily[0].family_name,
            product_group: productFamily[0].group_name,
          },
          product_applications: {
            application_name: productApplication[0].application_name,
            industry_name: productApplication[0].industry_name,
          },
          commission:
            product.commission_schedule.type === 'progressive'
              ? calculateAverageProductCommission(
                  product?.commission_schedule?.commission,
                )
              : product.commission_schedule.commission,
        };
      });

      const products = await Promise.all(productPromises);
      const companyOffers = await handleSearchOffers(id, 'manufacturer');
      const opportunites = await handleSearchOpportunities(id, 'manufacturer');
      const relationships = await handleSearchRelationships(id);
      const activeRelationships = relationships?.filter(
        (relationship) => relationship.status === 'active',
      );
      const activeAssociates = activeRelationships?.map((relationship) => {
        return relationship.associate;
      });
      const associates = await handleGetAssociates(activeAssociates);
      const associateData = await getAssociateIndustries(associates);
      setCompanyProducts(products);
      setPublicCompanyInfo(companyInfo);
      setCompanyOrderItems(orderItems);
      setCompanyOpportunities(opportunites);
      setAssociateData(associateData);
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsLoading({
        status: false,
        text: '',
        type: 'spinner',
      });
      setIsLoadingDataGrid(false);
    }
  }

  // Preload Info
  useEffect(() => {
    preLoadInfo();
  }, [i18n.language]);

  // Set Company Misc Info
  useEffect(() => {
    setMiscInfo();

    function getTotalYearSales() {
      let total = 0;
      orders?.forEach((order) => {
        total += order.grand_total;
      });

      return total.toLocaleString();
    }

    function setMiscInfo() {
      setCompanyMiscInfo({
        ...companyMiscInfo,
        launch_date: publicCompanyInfo?.created,
        sales_yoy: getTotalYearSales(),
        salesreps: associateData?.length,
        average_commission:
          calculateAverageCompanyCommission(companyOrderItems),
        average_turnaround: getAverageTurnaround(companyOpportunites),
      });
    }
  }, [orders, publicCompanyInfo, companyOpportunites, companyOrderItems]);

  // Format and Set Chart Data
  useEffect(() => {
    setIsChartLoading(true);

    const orderSalesData = formatDataByMonth(orders, 'order');
    updateChartData(orderSalesData, 'order', t('revenue'), setOrderChartData);

    const offerData = formatDataByMonth(offers, 'offer');
    updateChartData(offerData, 'offer', t('offers'), setOfferChartData);

    const opportunityData = formatDataByMonth(
      companyOpportunites,
      'opportunity',
    );
    updateChartData(
      opportunityData,
      'opportunity',
      t('opportunities'),
      setOpportunityChartData,
    );
    const sixMonthsOrderRevenue = getPastSixMonths(orders);

    const averageSixMonthsOrderRevenue = getAverageRevenue(
      sixMonthsOrderRevenue,
    ).toFixed(2);
    setAverageRevenue(averageSixMonthsOrderRevenue.toLocaleString());

    setIsChartLoading(false);
  }, [orders, offers, companyOpportunites, i18n.language]);

  // Get Coordinates and Set Clustered Map Data
  useEffect(() => {
    const coordinatesArr = [];
    const associateMapData = associateData?.map((associate) => {
      coordinatesArr.push({
        lat: associate.address.coordinates.latitude,
        lng: associate.address.coordinates.longitude,
      });
      return {
        name: associate.first_name + ' ' + associate.last_name,
        category: associate.industry[0].industry_name,
        content: associate.industry[0].industry_name,
        coordinates: {
          lat: associate.address.coordinates.latitude,
          lng: associate.address.coordinates.longitude,
        },
      };
    });
    setClusteredMapData(associateMapData);

    if (associateData) {
      if (!coordinatesArr.length) {
        setMapCenterAndZoom([{ lat: 40.7128, lng: -74.006 }]);
      } else {
        setMapCenterAndZoom(coordinatesArr);
      }
      setTimeout(() => {
        setIsLoadingMap(false);
      }, 3000);
    }
  }, [associateData]);

  // Set Pie Chart Data
  useEffect(() => {
    let won = 0;
    let lost = 0;
    if (offers?.length > 0) {
      offers?.forEach((offer) => {
        if (offer.status === 'won') won += 1;
        else if (offer.status === 'lost') {
          lost += 1;
        }
      });
      setPieChartData([
        {
          category: t('won'),
          value: won,
        },
        {
          category: t('lost'),
          value: lost,
        },
      ]);
    }
    setConversionRate(won / (won + lost));
    setTimeout(() => {
      setIsLoadingPieChart(false);
    }, 2000);
  }, [offers, i18n.language]);

  const rawProductColumns = [
    {
      field: 'product_name',
      headerName: t('product_name'),
      flex: 3,
      editable: false,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center">
            <Grid item sx={{ display: mobileView ? 'none' : 'flex' }}>
              <img src={row.product_image} alt="" />
            </Grid>
            <Grid item ml={2}>
              <Typography>{row.product_name}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'mpn',
      headerName: 'MPN',
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.mpn}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_group',
      headerName: t('product_group'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>
                {row.product_attributes
                  .map((attribute) => {
                    return `${attribute.attribute_type} - ${attribute.attribute}`;
                  })
                  .join(', ')}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_applications',
      headerName: t('applications'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              {row.product_applications
                .map((application) => application)
                .join(', ')}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'list_price',
      headerName: t('list_price'),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(row.list_price),
    },
  ];
  return (
    <Grid container>
      {/* Header */}
      <ProfileHeaderComponent
        profileInfo={publicCompanyInfo}
        miscInfo={companyMiscInfo}
        setIsDialogOpen={() => setIsApplyDialogOpen(true)}
        type="manufacturer"
      />
      {/* Map + Bar Chart */}
      <Grid container item mt={4} xs={12}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-around' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {/* Areas Served */}
          <Grid
            container
            item
            md={5.95}
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '16px',
                backgroundColor: theme.palette.profile[mode],
              }}
            >
              <Grid container item>
                <Grid item ml={2}>
                  <Typography variant="h6">{t('current_salesreps')}</Typography>
                </Grid>
                {isLoadingMap ? (
                  <Skeleton variant="rectangular" height={500} />
                ) : (
                  <ClusteredMap
                    locations={clusteredMapData}
                    initialZoom={zoomLevel}
                    popup={true}
                    cluster={false}
                    projection="globe"
                    rounded
                    isDataLoaded={isLoadingMap}
                    center={center}
                    disableBorder
                  />
                )}
              </Grid>
            </Box>
          </Grid>
          {/* Analytics */}
          <Grid
            container
            item
            md={5.95}
            flexDirection="column"
            justifyContent="center"
          >
            <ProfileAnalyticsChart
              activeChartData={activeChartData}
              setActiveChartData={setActiveChartData}
              isChartLoading={isChartLoading}
              chartSettings={chartSettings}
              mobileView={mobileView}
              data1={orderChartData}
              data2={offerChartData}
              data3={opportunityChartData}
              type="manufacturer"
            />
          </Grid>
        </Grid>
        {/* Additional Company Info */}
        <Grid container item xs={12} mt={4}>
          <Box
            mb={4}
            pb={6}
            sx={{
              backgroundColor: theme.palette.profile[mode],
              borderRadius: '25px',
              width: '100%',
            }}
          >
            <Grid
              container
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center', md: 'space-around' }}
            >
              <Grid
                container
                item
                xs={12}
                md={8}
                flexDirection={{ sm: 'column', md: 'row' }}
                justifyContent={{ xs: 'center', md: 'space-between' }}
              >
                {/* Intro */}
                <Grid item xs={12} md={8.5} mt={4} ml={2}>
                  <Typography variant="h6">{t('about_company')}</Typography>
                  <IntuRichTextEditor
                    disabled
                    defaultValue={JSON.stringify(publicCompanyInfo?.intro)}
                  />
                </Grid>
                {/* Averages */}
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  justifyContent="space-around"
                  flexDirection={{ sm: 'row', md: 'column' }}
                >
                  <Grid item size="auto">
                    <ProfileBlurbComponent
                      icon={<GroupAddIcon fontSize="large" />}
                      value={'$' + averageRevenue}
                      description={t('avg_order_revenue')}
                    />
                  </Grid>
                  <Grid item size="auto">
                    <ProfileBlurbComponent
                      icon={<PaidIcon fontSize="large" />}
                      value={companyMiscInfo?.average_commission + '%'}
                      description={t('avg_commission')}
                    />
                  </Grid>
                  <Grid item size="auto">
                    <ProfileBlurbComponent
                      icon={<VerifiedIcon fontSize="large" />}
                      value={
                        companyMiscInfo?.average_turnaround.toFixed(0) +
                        ' ' +
                        t('days')
                      }
                      description={t('avg_turnaround')}
                    />
                  </Grid>
                </Grid>
                {/* Terms & Warranty*/}
                <Grid
                  item
                  container
                  xs={12}
                  md={10}
                  lg={8}
                  flexDirection="row"
                  mt={2}
                  justifyContent="space-around"
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    flexDirection="column"
                    xs={6}
                  >
                    <Grid item>
                      <Typography variant="h6">{t('t&c')}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          setIsOpen(true);
                          setConditionType('tos');
                        }}
                        startIcon={<GavelIcon />}
                        variant="contained"
                      >
                        {t('t&c')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    flexDirection="column"
                    xs={6}
                  >
                    <Grid item>
                      <Typography variant="h6">{t('warranty')}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          setIsOpen(true);
                          setConditionType('warranty');
                        }}
                        startIcon={<WorkspacePremiumIcon />}
                        variant="contained"
                      >
                        {t('warranty')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Pie Chart */}
              <Grid container item xs={12} md={3.5} flexDirection="column">
                <Grid item>
                  {isLoadingPieChart ? (
                    <Skeleton />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: theme.palette.profile[mode],
                        borderRadius: '25px',
                      }}
                    >
                      {pieChartData.length > 1 ? (
                        <PieChartContext
                          data={pieChartData}
                          mobileView={mobileView}
                          chartId="conversion_rate"
                          description={t('rolling_conversion_rate')}
                          centered
                          hideLabels
                        />
                      ) : (
                        <Card>
                          <Grid
                            container
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              {transMessages('chart_no_data')}
                            </Typography>
                            <ErrorIcon sx={{ fontSize: '20rem' }} />
                          </Grid>
                        </Card>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* Company Associates */}
        <Grid container item xs={12}>
          <Typography variant="h5">{t('associates')}</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            {isLoadingDataGrid ? (
              <Skeleton />
            ) : (
              <DataGrid
                getRowId={getRowId}
                rows={associateData ? associateData : []}
                columns={associateColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 3,
                    },
                  },
                }}
                pageSizeOptions={[3]}
                disableRowSelectionOnClick
                rowHeight={97}
                sx={{
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: 'transparent',
                    color: 'white',
                  },
                }}
                columnVisibilityModel={{
                  product_family: mobileView ? false : true,
                  product_group: mobileView ? false : true,
                  product_applications: mobileView ? false : true,
                  commission: mobileView ? false : true,
                }}
              />
            )}
          </Box>
        </Grid>
        {/* Company Products */}
        {/* <Grid container item xs={12} pb={10}>
          <Typography variant="h5">{t('products')}</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            {isLoadingDataGrid ? (
              <Skeleton />
            ) : (
              <DataGrid
                getRowId={getRowId}
                rows={companyProducts ? companyProducts : []}
                columns={productColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 3,
                    },
                  },
                }}
                pageSizeOptions={[3]}
                disableRowSelectionOnClick
                rowHeight={97}
                sx={{
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: 'transparent',
                    color: 'white',
                  },
                }}
                columnVisibilityModel={{
                  product_family: mobileView ? false : true,
                  product_group: mobileView ? false : true,
                  product_applications: mobileView ? false : true,
                  commission: mobileView ? false : true,
                }}
              />
            )}
          </Box>
        </Grid> */}
        <Grid container item xs={12} pb={10}>
          <Typography variant="h5">{t('products')}</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            {!rawProducts.length ? (
              <Skeleton />
            ) : (
              <DataGrid
                getRowId={getRowId}
                rows={rawProducts}
                columns={rawProductColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 3,
                    },
                  },
                }}
                pageSizeOptions={[3]}
                disableRowSelectionOnClick
                rowHeight={97}
                sx={{
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: 'transparent',
                    color: 'white',
                  },
                }}
                columnVisibilityModel={{
                  product_family: mobileView ? false : true,
                  product_group: mobileView ? false : true,
                  product_applications: mobileView ? false : true,
                  commission: mobileView ? false : true,
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {isOpen && conditionType === 'tos' && (
        <>
          <WarrantyTosDialog
            content={publicCompanyInfo?.tos}
            handleClose={handleClose}
            companyName={publicCompanyInfo?.name}
            type={conditionType}
            isOpen
          />
        </>
      )}
      {isOpen && conditionType === 'warranty' && (
        <>
          <WarrantyTosDialog
            content={publicCompanyInfo?.tow}
            handleClose={handleClose}
            companyName={publicCompanyInfo?.name}
            type={conditionType}
            isOpen
          />
        </>
      )}
      {Object.keys(publicCompanyInfo).length ? (
        <ApplyDialog
          isOpen={isApplyDialogOpen}
          companyInfo={publicCompanyInfo}
          handleClose={() => setIsApplyDialogOpen(false)}
          handleConfirm={(newApplication) =>
            applyToRepresent(newApplication, id, publicCompanyInfo)
          }
        />
      ) : null}
    </Grid>
  );
};
