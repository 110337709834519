import { axiosPrivate } from '../../hooks/axios/axios.js';
import i18n from '../../i18n.js';

// List Payout Accounts
async function listAccountPersons(query) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/person/list';

  try {
    const listPersons = await axiosPrivate.get(url, {
      query,
      signal,
    });

    console.log('Persons List ->', listPersons);
    return listPersons;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

async function addAccountPerson(token) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/person/create';

  const req_fields = [];

  if (!token) {
    req_fields.push(
      i18n.t('validations:id', {
        dataType: i18n.t('types:token'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  const payload = {
    person_token: token,
  };

  try {
    const addPersons = await axiosPrivate.post(url, payload, {
      signal,
    });
    console.log('Result Creating Account Person ->', addPersons);
    return addPersons;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

async function updateAccountPerson(token, personID) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/person/update';

  const req_fields = [];

  if (!token) {
    req_fields.push(
      i18n.t('validations:id', {
        dataType: i18n.t('types:token'),
      }),
    );
  }

  if (!personID) {
    req_fields.push(
      i18n.t('validations:id', {
        dataType: i18n.t('types:person'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  const params = {
    person_id: personID,
  };

  const payload = {
    person_token: token,
  };

  try {
    const updatePersons = await axiosPrivate.put(url, payload, {
      params,
      signal,
    });
    return updatePersons;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

export { listAccountPersons, addAccountPerson, updateAccountPerson };
