import { useContext, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SimpleToggle from './SimpleToggle';
import { useTranslation } from 'react-i18next';
import ConfirmActionDialog from '../../confirmActionDialog/ConfrimActionDialog';
import dayjs from 'dayjs';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';

const CustomerSubscriptionBox = ({
  subscription,
  handleSubscriptionSelect,
  handleCancelSubscription,
  handleSetAutoRenew,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { usersActiveSubscriptions } = useContext(StripeContext);

  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isRenewDialogOpen, setIsRenewDialogOpen] = useState(false);

  const handleCancelClick = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setIsCancelDialogOpen(false);
  };

  const handleCancelConfirm = () => {
    setIsCancelDialogOpen(false);
    handleCancelSubscription();
  };

  const handleRenewClick = () => {
    setIsRenewDialogOpen(true);
  };

  const handleRenewDialogClose = () => {
    setIsRenewDialogOpen(false);
  };

  const handleRenewConfirm = () => {
    setIsRenewDialogOpen(false);
    setIsAutoRenew(!isAutoRenew);
    handleSetAutoRenew(!isAutoRenew);
  };

  const handleSelectClick = () => {
    handleSubscriptionSelect(subscription);
  };

  const descriptionSentences = subscription.subscription.description
    .split('.')
    .filter((sentence) => sentence);

  const isEqual = useMemo(() => {
    if (!usersActiveSubscriptions) return null;
    const [first] = usersActiveSubscriptions;
    return first.plan.id === subscription.subscription.default_price;
  }, [usersActiveSubscriptions, subscription]);

  return (
    <Badge
      badgeContent={isEqual ? 'ACTIVE' : null}
      color="primary"
      sx={{ flex: 1 }}
    >
      <Box
        key={subscription.subscription.id}
        bgcolor="secondary.light"
        p={2}
        borderRadius={2}
        boxShadow={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        border="1px solid"
        borderColor="white"
        flex={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="white">
            {subscription.subscription.name}
          </Typography>
          <Typography color="white" fontWeight={600} variant="h5">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }).format(subscription.price.unit_amount / 100)}
            <span style={{ fontSize: '0.8em' }}> per month</span>
          </Typography>
        </Box>
        <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          {descriptionSentences.map((sentence, index) => (
            <ListItem
              key={index}
              style={{
                display: 'list-item',
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ListItemText
                primary={
                  <Typography color="white">{sentence.trim()}</Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        {isEqual ? (
          <>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="body2" color="tertiary.contrastText">
                Subscription Started:
              </Typography>
              <Typography variant="body2" color="tertiary.contrastText">
                {dayjs
                  .unix(usersActiveSubscriptions[0]?.billing_cycle_anchor)
                  .format('MM/DD/YYYY')}
              </Typography>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="body2" color="tertiary.contrastText">
                Current Billing Cycle Ends:
              </Typography>
              <Typography variant="body2" color="tertiary.contrastText">
                {dayjs
                  .unix(usersActiveSubscriptions[0]?.current_period_end)
                  .format('MM/DD/YYYY')}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection={isSmall ? 'column' : 'row'}
              justifyContent="space-between"
              mt={4}
              gap={2}
            >
              <SimpleToggle
                isChecked={isAutoRenew}
                setIsChecked={handleRenewClick}
                text="Auto Renew"
              />
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                color="error"
              >
                {transButtons('cancel', { type: transTypes('subscription') })}
              </Button>
            </Box>
          </>
        ) : (
          <Button onClick={handleSelectClick}>{transButtons('select')}</Button>
        )}
        <ConfirmActionDialog
          open={isCancelDialogOpen}
          onClose={handleCancelDialogClose}
          onConfirm={handleCancelConfirm}
          dialogText="Are you sure you want to cancel your subscription?"
        />
        <ConfirmActionDialog
          open={isRenewDialogOpen}
          onClose={handleRenewDialogClose}
          onConfirm={handleRenewConfirm}
          dialogText="Are you sure you want to change your renewal?"
        />
      </Box>
    </Badge>
  );
};

export default CustomerSubscriptionBox;
