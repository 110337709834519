import {
  useState,
  useEffect,
  memo,
  useMemo,
  useContext,
  createContext,
} from 'react';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import { Add, CheckCircle } from '@mui/icons-material';
import ElementsContainer from '../../../checkout/ElementsContainer.jsx';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import { listAccountPersons } from '../../../../api/stripe/persons.js';
import { ListStatusIcons } from '../../../listStatusIcons/ListStatusIcons.jsx';
import { AddStripePerson } from './AddStripePerson.jsx';
import { ManageStripePerson } from './ManageStripePerson.jsx';
import { VerifyStripePerson } from '../verification/VerifyStripePerson.jsx';
import ListLoader from '../../../status/ListLoader.jsx';

export const PersonDetailsContext = createContext();

export const personDetailsTemplate = {
  id: '',
  first_name: '', // Persons First Name
  last_name: '', // Persons Last Name,
  dob: '', // Date of Birth
  email: '', // Email
  id_number: '', // ID or Passport Number
  phone: {
    value: '+1',
    info: {
      countryCallingCode: '1',
      countryCode: 'US',
      nationalNumber: null,
      numberType: null,
      numberValue: null,
      reason: null,
    },
  }, // Phone Number
  relationship: {
    director: false,
    executive: false,
    owner: false,
    representative: false,
  }, // Relationship to the Company
  ssn_last_4: '', // Last 4 of SSN (US Onle)
  percent_ownership: 0, // Percentage of Company Ownership (also Applies to Individuals)
  title: '', // Job title
  nationality: {
    label: '',
    dialCode: '',
    code: '',
    currency: '',
  }, // Nationality
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: {
      label: 'United States',
      dialCode: '1',
      code: 'US',
      currency: 'USD',
    },
  }, // Address
  political_exposure: '', // Political Exposure,
  ssn_last_4_provided: false,
  id_number_provided: false,
  requirements: {},
};

const createColumns = (
  t,
  transButtons,
  transTypes,
  setManagePersonModal,
  setExistingPersonData,
  setAddVerificationModal,
  theme,
) => {
  return [
    {
      field: 'first_name',
      headerName: t('table.headers.first_name'),
      editable: false,
      flex: 0.2,
      minWidth: 60,
    },
    {
      field: 'last_name',
      headerName: t('table.headers.last_name'),
      editable: false,
      flex: 0.2,
      minWidth: 60,
    },
    {
      field: 'relationship',
      headerName: t('table.headers.title'),
      editable: false,
      flex: 0.1,
      minWidth: 80,
      valueGetter: (param) => {
        return param.row.relationship.title;
      },
    },
    {
      field: 'owner',
      headerName: t('table.headers.owner'),
      editable: false,
      flex: 0.1,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.relationship.owner) {
          return (
            <Grid container justifyContent="center">
              <IconButton>
                <CheckCircle sx={{ color: theme.palette.success.main }} />
              </IconButton>
            </Grid>
          );
        }
      },
    },
    {
      field: 'director',
      headerName: t('table.headers.director'),
      editable: false,
      flex: 0.1,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.relationship.director) {
          return (
            <Grid container justifyContent="center">
              <IconButton>
                <CheckCircle sx={{ color: theme.palette.success.main }} />
              </IconButton>
            </Grid>
          );
        }
      },
    },
    {
      field: 'representative',
      headerName: t('table.headers.representative'),
      editable: false,
      flex: 0.1,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.relationship.representative) {
          return (
            <Grid container justifyContent="center">
              <IconButton>
                <CheckCircle sx={{ color: theme.palette.success.main }} />
              </IconButton>
            </Grid>
          );
        }
      },
    },
    {
      field: 'status',
      headerName: t('table.headers.status'),
      editable: false,
      flex: 0.1,
      // renderCell: (params) => ListStatusIcons(params.row.status, theme),
      renderCell: (params) => {
        const status = params.row.verification.status;
        if (status === 'verified') {
          ListStatusIcons(status, theme);
        }
        return (
          <Grid container justifyContent="center">
            <IntuIconButton
              size="small"
              type="upload"
              iconOnly={true}
              onClick={(prams) => {
                setExistingPersonData(params.row);
                setAddVerificationModal(true);
              }}
              tooltipTitle={transButtons('verify', {
                type: transTypes('person'),
              })}
            />
          </Grid>
        );
      },
    },
    {
      field: 'edit',
      headerName: t('table.headers.edit'),
      sortable: false,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            <IntuIconButton
              size="small"
              type="edit"
              iconOnly={true}
              onClick={() => {
                setExistingPersonData({
                  ...params.row,
                  phone: {
                    ...personDetailsTemplate,
                    value: params.row.phone,
                  },
                });
                setManagePersonModal(true);
              }}
              tooltipTitle={transButtons('edit', {
                type: transTypes('person'),
              })}
            />
          </Grid>
        );
      },
    },
  ];
};

const PersonsList = () => {
  const [accountPersons, setAccountPersons] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.stripe.PersonsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);

  const [addPersonModal, setAddPersonModal] = useState(false);
  const [managePersonModal, setManagePersonModal] = useState(false);
  const [addVerificationModal, setAddVerificationModal] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [existingPersonData, setExistingPersonData] = useState(
    personDetailsTemplate,
  );

  // Close Payout Accounts Modal
  const handlePersonModalCloseState = (action) => {
    if (action === 'add') {
      setAddPersonModal(false);
    } else if (action === 'edit') {
      setManagePersonModal(false);
    } else if (action === 'verify') {
      setAddVerificationModal(false);
    }
  };

  const tableColumns = useMemo(() => {
    return createColumns(
      t,
      transButtons,
      transTypes,
      setManagePersonModal,
      setExistingPersonData,
      setAddVerificationModal,
      theme,
    );
  }, [t, handlePersonModalCloseState]);

  const getPersonsList = async () => {
    setIsListLoading(true);

    try {
      const { data, status } = await listAccountPersons();

      if (status === 200) {
        setAccountPersons(data.data.persons);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getPersonsList();
  }, []);

  return (
    <PersonDetailsContext.Provider
      value={{
        existingPersonData,
        setExistingPersonData,
        personDetailsTemplate,
      }}
    >
      <Grid
        container
        className="content"
        sx={{ marginBottom: '8rem' }}
        spacing={5}
      >
        <Grid item className="content" xs={12}>
          <Typography variant="h5">{t('title')}</Typography>
          <Divider sx={{ marginBottom: '2rem' }} />
          {stripeUserData.stripe_connect_data.business_type !==
            'individual' && (
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <IntuIconButton
                type="subtmit"
                variant="outlined"
                IconComponent={Add}
                onClick={() => setAddPersonModal(true)}
                tooltipTitle={transButtons('add', {
                  type: transTypes('person'),
                })}
              />
            </Box>
          )}
          {isListLoading ? (
            <ListLoader columns={5} rows={4} />
          ) : !accountPersons?.length ? (
            <Grid item className="title content-centered" xs={12}>
              <Typography variant="body1">
                <Trans t={t} i18nKey="able.no_data" />
              </Typography>
            </Grid>
          ) : (
            <Box width="100%">
              <DataGrid
                isCellEditable={(params) => false}
                getRowId={(row) => row.id}
                rows={accountPersons}
                columns={tableColumns}
                hideFooterPagination
                hideFooterSelectedRowCount
                columnVisibilityModel={{
                  list_price: !isMobileView,
                  attributes: !isMobileView && !isMediumView,
                  applications: !isMobileView && !isMediumView,
                  product_group: !isMobileView && !isMediumView,
                  mpn: !isMobileView,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  sorting: {
                    sortModel: [{}],
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{
                  Row: MemoizedRow,
                  ColumnHeaders: MemoizedColumnHeaders,
                }}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 6,
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          )}
        </Grid>

        <ElementsContainer
          stripeAccount={stripeUserData?.stripe_connect_data?.id}
        >
          <AddStripePerson
            open={addPersonModal}
            onClose={() => handlePersonModalCloseState('add')}
            getPersonsList={getPersonsList}
          />

          <ManageStripePerson
            open={managePersonModal}
            onClose={() => handlePersonModalCloseState('edit')}
            getPersonsList={getPersonsList}
          />

          <VerifyStripePerson
            open={addVerificationModal}
            onClose={() => handlePersonModalCloseState('verify')}
            getPersonsList={getPersonsList}
          />
        </ElementsContainer>
      </Grid>
    </PersonDetailsContext.Provider>
  );
};

export default PersonsList;
