import React, { useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import { CardExpiryElement, useElements } from '@stripe/react-stripe-js';
import { StripeElementWrapper, StripeTextField } from '../../mui/inputStyles';

const CreditCardExpirationDateField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'expiration', // Defines the ID and Name of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'CreditCardExpirationDateField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', // Design standard or
  isLoadingManual = false, // Manually control he loading state
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidations } = useTranslation('validations');
  const elements = useElements();

  // Context
  const { values, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <>
      <Skeleton variant="rectangular" width={'100%'} height={60} />
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    </>
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <StripeTextField
        disabled={disabled}
        variant={variant}
        required={required}
        label={t('label')}
        InputLabelProps={{ shrink: true }}
        onBlur={() => {
          const cardExpiryElement = elements.getElement('cardExpiry');

          // Ensure the element exists before accessing its properties
          if (!cardExpiryElement) {
            setFieldError(
              'expiration',
              transValidations('expiration.not_mounted'),
            );
            return;
          }

          // Validate based on Stripe's `_empty` and `_invalid` flags
          if (cardExpiryElement._empty) {
            setFieldError(
              'expiration',
              transValidations('expiration.required'), // Add your translation key
            );
          } else if (cardExpiryElement._invalid) {
            setFieldError(
              'expiration',
              transValidations('expiration.invalid'), // Add your translation key
            );
          } else {
            setFieldError('expiration', ''); // Clear errors if valid
          }

          // Mark the field as touched in Formik
          setFieldTouched('expiration', true);
        }}
        InputProps={{
          inputComponent: StripeElementWrapper,
          inputProps: {
            Component: CardExpiryElement,
            options: {
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  letterSpacing: '0.025em',
                  '::placeholder': { color: '#aab7c4' },
                },
                invalid: { color: '#9e2146' },
              },
            },
          },
        }}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default CreditCardExpirationDateField;
