export const appearance = {
  theme: 'none', // Disables Stripe's default theme
  variables: {
    fontFamily: 'AvenirLight, sans-serif',
    fontSizeBase: '25px',
    fontWeight: 100,
    colorPrimary: '#A8C957', // Matches intuGreen
    colorText: '#2c2c2e', // Matches intuGrey
    colorDanger: '#f44336', // Matches intuRed
    borderRadius: '4px',
    spacingUnit: '10px',
    colorBackground: '#f7f7f8', // Matches white
  },
  rules: {
    '.Input': {
      color: '#2c2c2e', // Matches intuGrey
      border: '1px solid #A8C957', // Matches intuGreen
      fontSize: '5rem',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: '#f7f7f8', // Matches white
    },
    '.Input:hover': {
      borderColor: '#C3DF7F', // Matches intuLightGreen
    },
    'Input:focus': {
      borderColor: '#8AA43B', // Matches intuDarkGreen
    },
    '.Label': {
      fontWeight: 500,
      fontSize: '14px',
      color: '#6F7978', // Matches intuMiddleGrey
    },
    '.Error': {
      color: '#f44336', // Matches intuRed
    },
    '.Input:disabled': {
      backgroundColor: '#ededed', // Matches intuDisabled
      color: '#A2A2A2', // Matches intuHoverGrey
    },
    '.Input:focus': {
      borderColor: '#A8C957', // Matches intuGreen
    },
  },
};
