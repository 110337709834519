import { useContext } from 'react';
import { Box } from '@mui/material';
import { CartContext } from '../../context/cart/CartContext';
import ManufacturerShippingBox from './ManufacturerShippingBox';

const OrderShippingContainer = ({ shippingAddress, selectedCarrier }) => {
  const { manufacturers } = useContext(CartContext);

  return (
    <Box mt={2}>
      <>
        {manufacturers.size
          ? Array.from(manufacturers).map(([key, value]) => {
              return (
                <ManufacturerShippingBox
                  key={key}
                  manufacturer={value}
                  selectedCarrier={selectedCarrier}
                  shippingAddress={shippingAddress}
                />
              );
            })
          : null}
      </>
    </Box>
  );
};
export default OrderShippingContainer;
