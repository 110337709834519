import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';
import ResponseContext from '../../../context/providers/ResponseProvider';
import useResponseHandling from '../../../hooks/useResponseHandler';
import {
  cancelSubscription,
  getSubscriptions,
  updateSubscriptionAutoRenew,
} from '../../../api/stripe/subscriptions';
import ElementsContainer from '../../checkout/ElementsContainer';
import CustomerSubscriptionBox from './CustomerSubscriptionBox';
import CustomerPaymentMethodsList from './CustomerPaymentMethodsList';
import CustomerChargesList from './CustomerChargesList';
import SubscriptionPaymentDialog from './SubscriptionPaymentDialog';

const CustomerSubscriptionsDashboard = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const { stripeUserData, getStripeAccountDetails, usersActiveSubscriptions } =
    useContext(StripeContext);
  const { setErrorDialog } = useContext(ResponseContext);
  const { handleRegularResponse } = useResponseHandling();

  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const fetchSubscriptions = async () => {
    const subscriptions = await getSubscriptions();
    setSubscriptions(subscriptions);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const handleSubscriptionSelect = (s) => {
    if (
      usersActiveSubscriptions &&
      s.price.unit_amount < usersActiveSubscriptions[0].plan.amount
    ) {
      setErrorDialog({
        open: true,
        title: 'Action Not Allowed',
        message: 'You cannot downgrade your account.',
      });
      return;
    }
    setSelectedSubscription(s);
    setIsPaymentModalOpen(true);
  };

  const handlePaymentModalClose = (newSubscription) => {
    setIsPaymentModalOpen(false);
  };

  const usersSubscription = useMemo(() => {
    if (
      !stripeUserData?.stripe_customer_data?.subscriptions?.data ||
      !subscriptions
    )
      return null;

    const activeSubscriptionsIds =
      stripeUserData.stripe_customer_data.subscriptions.data
        .filter((s) => s.status === 'active')
        .map((s) => s.plan.product);

    const [found] = subscriptions.filter(({ subscription }) => {
      return activeSubscriptionsIds.includes(subscription.id);
    });

    return found;
  }, [stripeUserData, subscriptions]);

  useEffect(() => {
    if (!usersSubscription) return;
    setSelectedSubscription(usersSubscription);
  }, [usersSubscription]);

  const handleCancelSubscription = async () => {
    const subscription =
      stripeUserData.stripe_customer_data.subscriptions.data.find(
        (s) => s.status === 'active',
      );

    try {
      const { data, status } = await cancelSubscription(subscription.id);

      if (status === 200) {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
        getStripeAccountDetails();
        setSelectedSubscription(null);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleSetAutoRenew = async (isAutoRenew) => {
    try {
      const subscription =
        stripeUserData.stripe_customer_data.subscriptions.data.find(
          (s) => s.status === 'active',
        );
      const payload = {
        isAutoRenew,
        subscriptionId: subscription.id,
      };
      const { data, status } = updateSubscriptionAutoRenew(payload);
      if (status === 200) {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
        getStripeAccountDetails();
        setSelectedSubscription(null);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <ElementsContainer>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        px={4}
        margin="40px auto 0"
        width="100%"
      >
        <Box display="flex" gap={2} flexDirection={isMedium ? 'column' : 'row'}>
          {subscriptions.map((subscription) => {
            return (
              <CustomerSubscriptionBox
                subscription={subscription}
                handleSubscriptionSelect={handleSubscriptionSelect}
                handleCancelSubscription={handleCancelSubscription}
                handleSetAutoRenew={handleSetAutoRenew}
              />
            );
          })}
        </Box>
        <Box width="100%" my={4}>
          <CustomerPaymentMethodsList />
        </Box>
        <Box width="100%" my={4}>
          <CustomerChargesList />
        </Box>
      </Box>
      <SubscriptionPaymentDialog
        open={isPaymentModalOpen}
        onClose={handlePaymentModalClose}
        selectedSubscription={selectedSubscription}
      />
    </ElementsContainer>
  );
};

export default CustomerSubscriptionsDashboard;
