// TODO: only used in one place, move it there styledInput.js
import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

const IntuIconButton = styled(IconButton)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  background: 'inherit',
  cursor: 'pointer',
}));

const StripeTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '16px',
    fontFamily: 'AvenirLight, sans-serif',
    letterSpacing: '0.025em',
    '::placeholder': {
      // color: theme.palette.text.secondary,
      color: 'red',
      fontWeight: 100,
    },
  },
}));

// Forward ref to support Material-UI's expectations
const StripeElementWrapper = forwardRef(
  ({ Component, options, ...props }, ref) => (
    <Component
      {...props}
      options={options}
      onReady={(element) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(element);
          } else if (ref.current !== undefined) {
            ref.current = element;
          }
        }
      }}
    />
  ),
);

export { IntuIconButton, StripeElementWrapper, StripeTextField };
