import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {
  Drawer,
  Box,
  Grid,
  IconButton,
  Divider,
  List,
  Toolbar,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { motion } from 'framer-motion';
import { NavContainer } from '../../framer-motion/animations';
import AllAccountsNavItems from './navitems/AllAccountsNavItems';
import AdminNavItems from './navitems/AdminNavItems';
import UserNavItems from './navitems/UserNavItems';
import { NavBarDrawerContext } from '../../context/nav/NavBarDrawerContextProvider';
import useUser from '../../hooks/useUser';
import { IntuAppBar } from '../../mui/appBar';
import DrawerFooterContent from './drawer/DrawerFooterContent';
import { DrawerHeaderContent } from './drawer/DrawerHeaderContent';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import useLogout from '../../hooks/useLogout';
import { useTranslation } from 'react-i18next';
import { Logout } from '@mui/icons-material';

const AuthNavbarComponent = () => {
  const { isAdmin } = useAuth();
  const { handleLanguageChange } = useUser();
  const { theme, userTheme, logoThemeDark, logoThemeLight, mode } =
    useContext(IntuThemeContext);
  const navigate = useNavigate();
  const { handleDrawerClose, handleDrawerOpen, drawerOpen } =
    useContext(NavBarDrawerContext);
  const { t } = useTranslation('buttons', { keyPrefix: 'navigation' });

  const logoutUser = useLogout();

  const DrawerBodyContent = () => {
    return (
      <>
        <List>
          <AllAccountsNavItems />
        </List>

        <List sx={theme.components.DrawerListItem}>
          {isAdmin === true ? <AdminNavItems /> : <UserNavItems />}
        </List>
      </>
    );
  };

  return (
    <Box flexGrow={1}>
      <IntuAppBar position="fixed" open={drawerOpen}>
        <Toolbar variant="dense" sx={{ maxWidth: '100%' }}>
          <Box display="flex" alignItems="center" flex={1}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                ...(drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon sx={{ color: theme.components.MuiAppBar.menuIcon }} />
            </IconButton>
            <IconButton
              onClick={() => navigate('/')}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              <Box
                component="img"
                alt={userTheme.logo_alt}
                sx={{
                  width: '100%',
                  height: '60px',
                  margin: '10px 0 10px 0',
                  backgroundColor: 'transparent',
                }}
                src={
                  userTheme.logo_url
                    ? userTheme.logo_url
                    : mode === 'dark'
                      ? logoThemeDark
                      : logoThemeLight
                }
                style={{ backgroundColor: 'transparent' }}
              />
            </IconButton>
          </Box>
        </Toolbar>
        <motion.div>
          <Drawer
            sx={{
              display: 'flex',
            }}
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            variants={NavContainer}
            animate={drawerOpen ? 'visible' : 'hidden'}
            initial="hidden"
          >
            <Grid container direction="column" sx={{ flex: 1 }}>
              <Grid item>
                <DrawerHeaderContent />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <DrawerBodyContent />
              </Grid>
              <Grid item>
                {/* Sign In & SignUp Button */}
                <Box
                  sx={{
                    marginTop: 'auto',
                    padding: 2,
                    borderTop: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    startIcon={
                      <Logout
                        sx={{ color: theme.palette.secondary.backgroundColor }}
                      />
                    }
                    onClick={() => {
                      logoutUser();
                      handleDrawerClose();
                    }}
                  >
                    {t('sign_out')}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <DrawerFooterContent onLanguageChange={handleLanguageChange} />
              </Grid>
            </Grid>
          </Drawer>
        </motion.div>
      </IntuAppBar>
    </Box>
  );
};

export default AuthNavbarComponent;
