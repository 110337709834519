import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import IntuIconButton from '../../buttons/IntuIconButton';
import { AddPaymentMethodValidationSchema } from '../../customers/paymentMethods/validationSchema';
import useResponseHandling from '../../../hooks/useResponseHandler';
import countryList from '../../../data/CountryData.json';
import CreditCardNumberField from '../../paymentMethods/CreditCardNumberField';
import CreditCardExpirationDateField from '../../paymentMethods/CreditCardExpirationField';
import CreditCardCVCField from '../../paymentMethods/CreditCardCVCField';
import CreditCardHolderNameField from '../../paymentMethods/CreditCardHolderNameField';
import AddressField from '../../address/AddressField';
import { createStripeCustomerSetUpIntent } from '../../../api/stripe/setupIntent';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';
import PromotionCodeContainer from '../../../pages/account/PromotionCodeContainer';
import SimpleToggle from './SimpleToggle';

const handleCustomValidation = async (elements, transValidations) => {
  const errors = {};

  if (!elements) {
    errors.card_number = 'Stripe Elements not initialized.';
    return errors;
  }

  // Validate Card Number Element
  const cardNumberElement = elements.getElement('cardNumber');
  if (!cardNumberElement) {
    errors.card_number = transValidations('card_number.missing');
  } else if (cardNumberElement._empty) {
    errors.card_number = transValidations('card_number.required');
  } else if (cardNumberElement._invalid) {
    errors.card_number = transValidations('card_number.invalid');
  }

  // Validate Expiration Date Element
  const cardExpiryElement = elements.getElement('cardExpiry');
  if (!cardExpiryElement) {
    errors.expiration = transValidations('expiration.missing');
  } else if (cardExpiryElement._empty) {
    errors.expiration = transValidations('expiration.required');
  } else if (cardExpiryElement._invalid) {
    errors.expiration = transValidations('expiration.invalid');
  }

  // You can add similar checks for the CVC field if required
  const cardCVCElement = elements.getElement('cardCvc');
  if (!cardCVCElement) {
    errors.cvc = transValidations('cvc.missing');
  } else if (cardCVCElement._empty) {
    errors.cvc = transValidations('cvc.required');
  } else if (cardCVCElement._invalid) {
    errors.cvc = transValidations('cvc.invalid');
  }

  return errors;
};

const CustomerPaymentMethodsDialog = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation',
  transPrefix = 'components.customers.paymentMethods.AddPaymentMethod',
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidations } = useTranslation('validation');
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');

  const stripe = useStripe();
  const elements = useElements();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);
  const [userCountryDetails, setUserCountryDetails] = useState({
    label: 'United States',
    dialCode: '1',
    code: 'US',
    currency: 'USD',
  });
  const [isLoading, setIsLoading] = useState(false);

  // Get user country data
  useEffect(() => {
    const userCountry =
      stripeUserData?.stripe_customer_data?.address?.country || null;

    if (userCountry) {
      const userCountryData = countryList.find(
        (element) => userCountry === element.code,
      );
      setUserCountryDetails(userCountryData);
    }
  }, [stripeUserData?.stripe_customer_data?.address?.country, open]);

  const handleSubmit = async (values, formik) => {
    const cardNumber = elements.getElement('cardNumber');
    const cardExp = elements.getElement('cardExpiry');
    const cvcNumber = elements.getElement('cardCvc');
    const isCardInvalid = cardNumber._invalid;
    const isCVCInvalid = cvcNumber._invalid;
    const isExpInvalid = cardExp._invalid;

    if (
      isCardInvalid ||
      isCVCInvalid ||
      isExpInvalid ||
      !stripeUserData?.stripe_customer_data?.id
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const billingAddress = Object.entries({
        line1: values?.address?.line1,
        line2: values?.address?.line2,
        city: values?.address?.city,
        state: values?.address?.state,
        postal_code: values?.address?.postcode,
        country: values?.address.country?.code,
      })
        .filter(
          ([key, value]) =>
            value !== '' && value !== null && value !== undefined,
        ) // Remove invalid values
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      const req_fields = [];
      if (!billingAddress.line1)
        req_fields.push(transValidations('address.line1_missing'));
      if (!billingAddress.city)
        req_fields.push(transValidations('address.city_missing'));
      if (!billingAddress.state)
        req_fields.push(transValidations('address.state_missing'));
      if (!billingAddress.postal_code)
        req_fields.push(transValidations('address.postcode_missing'));
      if (!billingAddress.country)
        req_fields.push(transValidations('address.country_missing'));
      if (!stripe || !elements) {
        req_fields.push(transValidations('stripe_initialization'));
      }

      if (req_fields.length > 0) {
        const errorMessage = req_fields.join(', ');

        return handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: '251',
          message: transValidations('required_fields', { errorMessage }),
        });
      }

      // Create Setup Intent
      const createCustomerSetUpIntent = await createStripeCustomerSetUpIntent({
        customer: stripeUserData?.stripe_customer_data?.id,
      });

      console.log('createdCustomerSetupintent', createCustomerSetUpIntent);

      if (createCustomerSetUpIntent.statusCode === 200) {
        const { client_secret } = createCustomerSetUpIntent.data;

        // Confirm card setup with a Setup Intent client secret
        const { setupIntent, error } = await stripe.confirmCardSetup(
          client_secret,
          {
            payment_method: {
              card: elements.getElement('cardNumber'),
              allow_redisplay: 'always',
              billing_details: {
                name: values?.name,
                address: {
                  ...billingAddress,
                },
              },
            },
          },
        );

        console.log('SetUp Intent Result -> ', setupIntent);

        // Attach it to the customer as default source
        // if (setupIntent?.payment_method) {
        //   await updateStripeCustomer({
        //     customer: stripeUserData?.stripe_customer_data?.id,
        //     payload: {
        //       invoice_settings: {
        //         default_payment_method: setupIntent?.payment_method,
        //       },
        //     },
        //   });
        // }

        if (error) {
          return handleRegularResponse({
            open: true,
            status: 'error',
            statusCode: 400,
            message: transMessages('actions.add.400', {
              type: transTypes('payment_method'),
              errorMessage: error.message,
            }),
          });
        }

        handleRegularResponse({
          open: true,
          status: 'success',
          statusCode: '200',
          message: transMessages('actions.add.200', {
            type: transTypes('payment_method'),
          }),
        });
        onClose();
        getStripeAccountDetails();
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const validationSchema = AddPaymentMethodValidationSchema();

  const initialValues = {
    name: stripeUserData?.stripe_customer_data?.name || '',
    address: {
      line1: stripeUserData?.stripe_customer_data?.address?.line1 || '',
      line2: stripeUserData?.stripe_customer_data?.address?.line2 || '',
      city: stripeUserData?.stripe_customer_data?.address?.city || '',
      state: stripeUserData?.stripe_customer_data?.address?.state || '',
      postcode:
        stripeUserData?.stripe_customer_data?.address?.postal_code || '',
      country: userCountryDetails || '',
    },
  };

  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [promotion, setPromotion] = useState(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnMount={true}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={async (values) => {
        try {
          // Run Yup validation and collect schema errors
          const schemaErrors = await AddPaymentMethodValidationSchema()
            .validate(values, { abortEarly: false })
            .then(() => ({})) // If validation passes, return an empty object
            .catch((err) => {
              return err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message; // Map field paths to their messages
                return acc;
              }, {});
            });

          // Run custom validation and collect errors
          const customErrors = handleCustomValidation(
            elements,
            transValidations,
          );

          // Merge Yup schema errors with custom validation errors
          return { ...schemaErrors, ...customErrors };
        } catch (err) {
          return {};
        }
      }}
    >
      {(formik) => {
        const { values, isValid, isSubmitting, errors, actions } = formik;
        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('description')}</DialogContentText>
              <Grid>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">{t('card_details')}</Typography>
                  <Stack spacing={2} direction="column">
                    {/* Card Number */}
                    <CreditCardNumberField
                      required
                      isLoadingManual={isSubmitting}
                    />
                    <Stack spacing={2} diection="row">
                      {/* Credit Card Expiration */}
                      <CreditCardExpirationDateField
                        required
                        isLoadingManual={isSubmitting}
                      />

                      {/* Credit Card CVC */}
                      <CreditCardCVCField
                        required
                        isLoadingManual={isSubmitting}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item sx={{ marginBottom: '2rem' }}>
                  <Typography variant="h6">
                    {t('billing_details.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('billing_details.description')}
                  </Typography>
                </Grid>
                <Grid item>
                  {/* Credit Card Details */}
                  <Stack spacing={2}>
                    <CreditCardHolderNameField
                      required
                      isLoadingManual={isSubmitting}
                    />
                    <AddressField required isLoadingManual={isSubmitting} />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <IntuIconButton
                    type="cancel"
                    onClick={onClose}
                    tooltipTitle="cancel"
                  />
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    tooltipTitle="Add"
                    isLoading={isLoading}
                    onClick={() => handleSubmit(values, formik)}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CustomerPaymentMethodsDialog;
