import { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { CartContext } from '../../context/cart/CartContext';

const ManufacturerShippingBox = ({
  manufacturer,
  selectedCarrier,
  shippingAddress,
}) => {
  const axios = useAxiosPrivate();
  const { setCartProducts, manufacturers, setManufacturers } =
    useContext(CartContext);

  const [estimates, setEstimates] = useState([]);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [isEstimatesLoading, setIsEstimatesLoading] = useState(false);
  const [isTaxLoading, setIsTaxLoading] = useState(false);

  const manufacturerProducts = useMemo(() => {
    let products = [];
    Object.keys(manufacturer.products).forEach((key) => {
      products.push(manufacturer.products[key]);
    });
    return products;
  }, [manufacturer]);

  const handleGetShipStationRates = async () => {
    try {
      setIsEstimatesLoading(true);
      const payload = {
        carrier_code: selectedCarrier.code,
        manufacturer_address: manufacturer.address,
        shipping_address: shippingAddress,
        products: manufacturerProducts,
      };

      const { data, status } = await axios.post(
        '/api/ship-station/rates',
        payload,
      );

      if (status === 200) {
        setEstimates(data.data);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsEstimatesLoading(false);
    }
  };

  useEffect(() => {
    if (
      shippingAddress &&
      shippingAddress.country &&
      shippingAddress.country.code &&
      shippingAddress.postcode &&
      shippingAddress.city &&
      shippingAddress.state &&
      selectedCarrier
    ) {
      handleGetShipStationRates();
    }
  }, [shippingAddress, selectedCarrier]);

  const getTax = async () => {
    try {
      setIsTaxLoading(true);
      const payload = {
        manufacturer_address: manufacturer.address,
        address_to: shippingAddress,
        products: manufacturerProducts,
        shipping: selectedEstimate,
      };

      const { data, status } = await axios.post(
        '/api/stripe/tax/get-tax',
        payload,
      );

      // Set tax on manufacturer
      if (status === 200) {
        setManufacturers((prev) => {
          const map = new Map(prev);
          const m = map.get(manufacturer._id);

          map.set(manufacturer._id, {
            ...m,
            tax_total: data.data,
          });
          return map;
        });
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsTaxLoading(false);
    }
  };

  useEffect(() => {
    if (selectedEstimate) {
      getTax();
    }
  }, [selectedEstimate]);

  const handleSelectEstimate = (estimate) => {
    setSelectedEstimate(estimate);

    const productIds = Object.keys(manufacturer.products).map((p) => p);
    // Set on cart context which product gets which shipping option
    setCartProducts((prev) => {
      return prev.map((product) => {
        if (productIds.includes(product.product._id)) {
          return {
            ...product,
            selected_shipping: estimate,
          };
        } else {
          return product;
        }
      });
    });
  };

  return (
    <Box>
      <Box height={40} bgcolor="secondary.light" />
      <Box display="flex" justifyContent="space-between" p={2} minHeight={350}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Typography variant="h6">
            Manufacturer: {manufacturer.name}
          </Typography>
          <Typography variant="body2">
            Shipping out of {manufacturer.address.country.label}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={isEstimatesLoading ? 'center' : 'flex-start'}
          flex={0.8}
          maxHeight={400}
          overflow="scroll"
        >
          {isEstimatesLoading ? (
            <LinearProgress sx={{ width: '90%' }} />
          ) : (
            estimates.map((estimate) => {
              return (
                <Button
                  key={estimate.serviceName}
                  onClick={() => handleSelectEstimate(estimate)}
                  sx={{
                    width: '100%',
                    my: 1,
                    gap: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  variant={
                    selectedEstimate &&
                    selectedEstimate.serviceName === estimate.serviceName
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  <Typography flex={1} align="left" variant="body2">
                    {estimate.serviceName}
                  </Typography>
                  <Typography flex={0.5} align="center" variant="body2">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(estimate.shipmentCost + estimate.otherCost)}
                  </Typography>
                </Button>
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ManufacturerShippingBox;
