import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import useResponseHandling from '../../hooks/useResponseHandler';

const PromotionCodeContainer = ({ promotion, setPromotion }) => {
  const axios = useAxiosPrivate();
  const { handleRegularResponse } = useResponseHandling();
  const [couponCode, setCouponCode] = useState('');

  const handleApplyClick = async () => {
    try {
      const { data, status } = await axios.get(
        `/api/stripe/promotion-codes/${couponCode}`,
      );
      console.log('back', data, status);
      if (status === 200) {
        setPromotion(data.data);
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error getting promotions', error);
    }
  };

  return (
    <Box display="flex" alignItems="center" my={2}>
      <Typography mr={2}>Coupon</Typography>
      <TextField
        placeholder="Enter your coupon code"
        type="text"
        value={couponCode}
        onChange={(event) => setCouponCode(event.target.value)}
        sx={{ width: 250 }}
        disabled={!!promotion}
      />
      <Button
        onClick={handleApplyClick}
        disabled={!couponCode || !!promotion}
        color="primary"
        sx={{ marginLeft: 2 }}
        variant={promotion ? 'outlined' : 'contained'}
      >
        {promotion ? 'Applied' : 'Apply'}
      </Button>
    </Box>
  );
};

export default PromotionCodeContainer;
