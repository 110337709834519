import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

const CurrencyConversionContainer = ({
  exchangeRates,
  fromValue,
  toCurrency,
  customSetter,
}) => {
  const rate = useMemo(() => {
    return exchangeRates[toCurrency];
  }, [exchangeRates, toCurrency]);

  const result = useMemo(() => {
    return rate * fromValue;
  }, [rate, fromValue]);

  useEffect(() => {
    if (!customSetter) return;
    customSetter(result);
  }, [result, customSetter]);

  return (
    <Box>
      <Typography>
        Converts to{' '}
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: toCurrency,
        }).format(result)}
      </Typography>
    </Box>
  );
};

export default CurrencyConversionContainer;
