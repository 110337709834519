import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ManagePersonValidationSchema } from './validationSchema';
import CountryField from '../../../address/CountryField';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import countryList from '../../../../data/CountryData.json';
import PersonRelationshipField from './PersonRelationshipField';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField';
import IndividualLastNameField from '../../individual/IndividualLastNameField';
import IndividualDOBField from '../../individual/IndividualDOBField';
import AddressField from '../../../address/AddressField';
import IndividualMobileNumberField from '../../individual/IndividualMobileNumberField';
import EmailField from '../../fields/email/EmailField';
import IndividualIDField from '../../individual/IndividualIDField';
import IndividualSSNField from '../../individual/IndividualSSNField';
import IndividualPoliticalExposureField from '../../individual/IndividualPoliticalExposureField';
import IndividualJobTitleField from '../../individual/IndividualJobTitleField';
import PersonOwnershipField from './PersonOwnershipField';
import { updateAccountPerson } from '../../../../api/stripe/persons';
import { PersonDetailsContext } from './PersonsList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AccountRequirements } from '../AccountRequirements';

dayjs.extend(utc);
const convertDobToDayjs = (dob) => {
  if (!dob || !dob.year || !dob.month || !dob.day) {
    return null; // Handle invalid input gracefully
  }

  // Construct a dayjs object using the input values
  return dayjs(
    `${dob.year}-${String(dob.month).padStart(2, '0')}-${String(dob.day).padStart(2, '0')}`,
  );
};

const getProperPhoneNumber = (phoneNumber, dialCode, countryCode) => {
  if (!phoneNumber || !dialCode || !countryCode) {
    return {
      value: null,
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    };
  }

  // Remove the '+' at the start of the number
  const numberWithoutPlus = phoneNumber.replace(`+${dialCode}`, '');

  return {
    value: phoneNumber,
    info: {
      countryCallingCode: dialCode,
      countryCode: countryCode,
      nationalNumber: numberWithoutPlus,
      numberType: numberWithoutPlus,
      numberValue: null,
      reason: null,
    },
  };
};

// console.log('Test Full Number', getProperPhoneNumber('+13474168051', '+1'));
// console.log('Test Country Code Only', getProperPhoneNumber('+1', '+1'));
// console.log(
//   'Test Full International Number',
//   getProperPhoneNumber('+4915123542746'),
// );
// console.log('Test No Number', getProperPhoneNumber('', '+1'));
// console.log('Test + Only', getProperPhoneNumber('+', '+1'));
// console.log(
//   'Test Number without Plus',
//   getProperPhoneNumber('13474168051', '+1'),
// );

const ManageStripePerson = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation',
  transPrefix = 'components.stripe.ManagePerson',
  getPersonsList = () => {},
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);
  const { existingPersonData, setExistingPersonData, personDetailsTemplate } =
    useContext(PersonDetailsContext);

  const [userCountryDetails, setUserCountryDetails] = useState({
    label: 'United States',
    dialCode: '1',
    code: 'US',
    currency: 'USD',
  });

  // console.log('Persons Data', existingPersonData);

  // Country Data
  useEffect(() => {
    if (existingPersonData?.address?.country) {
      return;
    }
    const userCountry = existingPersonData?.address?.country || null;

    if (userCountry) {
      const userCountryData = countryList.find(
        (element) => userCountry === element.code,
      );
      setUserCountryDetails(userCountryData);
    }
  }, [existingPersonData?.address?.country]);

  // Seperate Relationship, Owbership & Title
  const { percent_ownership, title, ...filteredRelationship } =
    existingPersonData?.relationship;

  // Remove unsupported upate arameter
  delete filteredRelationship.authorizer;
  delete filteredRelationship.legal_guardian;

  // Get National Number
  let properPhoneNumber = {
    value: '',
    info: {
      countryCallingCode: null,
      countryCode: null,
      nationalNumber: null,
      numberType: null,
      numberValue: null,
      reason: null,
    },
  };

  if (existingPersonData?.phone?.value && userCountryDetails?.dialCode) {
    properPhoneNumber = getProperPhoneNumber(
      existingPersonData?.phone?.value,
      userCountryDetails?.dialCode,
      userCountryDetails?.code,
    );
  }

  const initialValues = {
    id: existingPersonData?.id,
    first_name: existingPersonData?.first_name, // Persons First Name
    last_name: existingPersonData?.last_name, // Persons Last Name,
    dob: convertDobToDayjs(existingPersonData?.dob) || null, // Date of Birth
    email: existingPersonData?.email || '', // Email
    id_number: '', // ID or Passport Number
    phone: properPhoneNumber, // Phone Number
    relationship: filteredRelationship, // Relationship to the Company
    ssn_last_4: '', // Last 4 of SSN (US Onle)
    percent_ownership: percent_ownership || 0, // Percentage of Company Ownership (also Applies to Individuals)
    title: title || '', // Job title
    nationality: userCountryDetails, // Nationality
    address: {
      line1: existingPersonData?.address?.line1,
      line2: existingPersonData?.address?.line2,
      city: existingPersonData?.address?.city,
      state: existingPersonData?.address?.state,
      postcode: existingPersonData?.address?.postal_code,
      country: userCountryDetails,
    }, // Address
    political_exposure: '', // Political Exposure,
    ssn_last_4_provided: existingPersonData?.ssn_last_4_provided, // Whether the SSN has been prvided, important for vlaidation
    id_number_provided: existingPersonData?.id_number_provided, // Whether the ID Number has been provided?
    verification: existingPersonData?.verification, // Verfication Status
    requirements: existingPersonData?.requirements?.currently_due || [], // Person Specifc Requirements
  };

  const validationSchema = ManagePersonValidationSchema();

  const stripe = useStripe();

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    const personID = values?.id;
    if (!personID) {
      return;
    }

    try {
      // Extract values
      const day = values?.dob?.$D;
      const month = values?.dob?.$M + 1; // Add 1 to adjust for zero-indexing
      const year = values?.dob?.$y;

      let accountDetails = Object.entries({
        relationship: {
          ...values?.relationship,
          percent_ownership: values?.percent_ownership
            ? parseFloat(values.percent_ownership)
            : undefined, // Scale to percentage if present
          title: values?.title,
        },
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone: values?.phone?.value,
        email: values?.email,
        address: {
          line1: values?.address?.line1,
          line2: values?.address?.line2,
          postal_code: values?.address?.postcode,
          city: values?.address?.city,
          state: values?.address?.state,
          country: values?.address?.country.code,
        },
        id_number: values?.id_number,
        ssn_last_4: values?.ssn_last_4,
        nationality: values?.nationality?.label,
        dob: {
          day: day,
          month: month,
          year: year,
        },
        political_exposure: values?.political_exposure,
      })
        .filter(([key, value]) => {
          // Filter out null, undefined, and empty string values
          if (value === null || value === undefined || value === '')
            return false;

          // Recursively check nested objects and arrays
          if (typeof value === 'object' && !Array.isArray(value)) {
            const nestedEntries = Object.entries(value).filter(
              ([, nestedValue]) =>
                nestedValue !== null &&
                nestedValue !== undefined &&
                nestedValue !== '',
            );
            return nestedEntries.length > 0; // Include only if the nested object has valid values
          }

          return true; // Retain other valid values
        })
        .reduce((acc, [key, value]) => {
          // Recursively filter nested objects
          if (typeof value === 'object' && !Array.isArray(value)) {
            acc[key] = Object.entries(value)
              .filter(
                ([, nestedValue]) =>
                  nestedValue !== null &&
                  nestedValue !== undefined &&
                  nestedValue !== '',
              )
              .reduce((nestedAcc, [nestedKey, nestedValue]) => {
                nestedAcc[nestedKey] = nestedValue;
                return nestedAcc;
              }, {});
          } else {
            acc[key] = value; // Retain primitive values
          }
          return acc;
        }, {});

      const requestToken = await stripe.createToken('person', accountDetails);

      if (requestToken?.token) {
        const token = requestToken.token;
        // Create Stripe Account

        const updatePerson = await updateAccountPerson(token.id, personID);

        if (updatePerson?.data.statusCode === 201) {
          const response = updatePerson.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });

          getStripeAccountDetails();
          getPersonsList();
          formik.resetForm();
          onClose();
        } else {
          const response = updatePerson.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });
        }
      } else {
        // Error Requesting Token
        const response = requestToken?.error;
        handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: 400,
          message: response.message,
        });
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const requirements = existingPersonData?.currently_due || [];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialErrors={{ keywords: true }}
      initialTouched={initialValues}
    >
      {(formik) => {
        const { values, isSubmitting, isValid } = formik;
        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle>
              {t('title', { name: `${values.first_name} ${values.last_name}` })}
            </DialogTitle>

            <DialogContent>
              <DialogContentText sx={{ marginBottom: '2rem' }}>
                {t('description')}
              </DialogContentText>

              {/* Account Requirements */}
              <AccountRequirements
                requirements={requirements}
                title={
                  <Typography variant="h6">
                    {t('requirements.title')}
                  </Typography>
                }
                divider={false}
              />

              {/* Relationship & functions */}
              <Grid item sx={{ marginBottom: '1rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">{t('person_role.title')}</Typography>
                  <Typography variant="body1">
                    {t('person_role.description')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Stack spacing={2}>
                    {/* Persons Relationship*/}
                    <PersonRelationshipField
                      country={userCountryDetails?.code}
                      required
                      isLoadingManual={isSubmitting}
                      showInitialErrors={true}
                    />

                    {/* Ownership Percentage */}
                    {values?.relationship?.owner && (
                      <PersonOwnershipField
                        required
                        isLoadingManual={isSubmitting}
                        showInitialErrors={true}
                      />
                    )}

                    {/* Job Title */}
                    <IndividualJobTitleField
                      required
                      isLoadingManual={isSubmitting}
                      showInitialErrors={true}
                    />
                  </Stack>
                </Grid>
              </Grid>

              {/* Identity */}
              <Grid item sx={{ marginBottom: '2rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">
                    {t('person_idendity.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('person_idendity.description')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Stack spacing={2}>
                    {/* ID Number */}
                    {values?.nationality?.code === 'US' &&
                    !values?.ssn_last_4_provided ? (
                      <IndividualSSNField
                        required
                        isLoadingManual={isSubmitting}
                        transPrefix="individual.verification.ssn_last_4"
                        showInitialErrors={true}
                      />
                    ) : (
                      !values?.id_number_provided && (
                        <IndividualIDField
                          required
                          isLoadingManual={isSubmitting}
                          transPrefix="individual.verification.id_number"
                          showInitialErrors={true}
                        />
                      )
                    )}

                    {/* Nationality */}
                    <CountryField
                      fieldID="nationality"
                      required
                      isLoadingManual={isSubmitting}
                      showInitialErrors={true}
                    />
                  </Stack>
                </Grid>
              </Grid>

              {/* Personal Details */}
              <Grid item sx={{ marginBottom: '2rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">
                    {t('person_details.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('person_details.description')}
                  </Typography>
                </Grid>

                <Stack spacing={2}>
                  <Stack spacing={2} direction="row">
                    {/* First Name */}
                    <IndividualFirstNameField
                      fieldID="first_name"
                      required
                      isLoadingManual={isSubmitting}
                      showInitialErrors={true}
                    />

                    {/* Last Name */}
                    <IndividualLastNameField
                      fieldID="last_name"
                      required
                      isLoadingManual={isSubmitting}
                      showInitialErrors={true}
                    />
                  </Stack>

                  {/* Date of Birth */}
                  <IndividualDOBField
                    fieldID="dob"
                    required
                    showInitialErrors={true}
                    isLoadingManual={isSubmitting}
                  />

                  {/* Address */}
                  <AddressField
                    fieldID="address"
                    required
                    isLoadingManual={isSubmitting}
                    showInitialErrors={true}
                  />

                  {/* Phone */}
                  <IndividualMobileNumberField
                    fieldID="phone"
                    required
                    isLoadingManual={isSubmitting}
                    transPrefix="individual.verification.phone"
                    showInitialErrors={true}
                  />

                  {/* Email */}
                  <EmailField
                    fieldID="email"
                    required
                    isLoadingManual={isSubmitting}
                    transPrefix="individual.verification.email"
                    showInitialErrors={true}
                  />
                </Stack>
              </Grid>

              {/* Exposure */}
              <Grid item sx={{ marginBottom: '1rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    <Typography variant="h6">
                      {t('person_exposure.title')}
                    </Typography>
                    <Typography variant="body1">
                      {t('person_exposure.description')}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    {/* Political Exposure */}
                    <IndividualPoliticalExposureField
                      isLoadingManual={isSubmitting}
                      transPrefix="individual.verification.political_exposure"
                      showInitialErrors={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <IntuIconButton
                    type="cancel"
                    onClick={() => {
                      onClose();
                      setExistingPersonData(personDetailsTemplate);
                    }}
                    tooltipTitle="cancel"
                  />
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(values, formik);
                    }}
                    tooltipTitle="Add"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { ManageStripePerson };
