import {
  useState,
  useEffect,
  memo,
  useMemo,
  useContext,
  createContext,
} from 'react';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import { toUpperCase } from '../../../../helpers/toTitleCase.js';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import { listBalanceTransactions } from '../../../../api/stripe/balanceTransactions.js';
import dayjs from 'dayjs';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListLoader from '../../../status/ListLoader.jsx';

export const BalanceTransactionDetailsContext = createContext();

const createColumns = (
  t,
  transButtons,
  setManageBalanceDetailsTransactionsModal,
  setBalanceTransactionDetails,
  stripeUserData,
) => {
  return [
    {
      field: 'date',
      headerName: t('table.headers.date'),
      editable: false,
      flex: 0.2,
      minWidth: 50,
      align: 'left',
      valueGetter: (params) => {
        const userLocale = navigator.languages
          ? navigator.languages[0]
          : navigator.language;

        // Create a date object with the adjusted time
        const date = dayjs.unix(params.row.created).toDate();

        // Format the date in the user's local format
        return new Intl.DateTimeFormat(userLocale, {
          dateStyle: 'medium', // Adjust to 'full', 'long', 'medium', or 'short'
          // timeStyle: 'short', // Include time if needed
        }).format(date);
      },
    },
    {
      field: 'description',
      headerName: t('table.headers.description'),
      editable: false,
      flex: 0.3,
      minWidth: 120,
      align: 'left',
    },
    {
      field: 'net',
      headerName: t('table.headers.amount'),
      editable: false,
      flex: 0.1,
      minWidth: 100,
      valueGetter: (params) => {
        const amount = (params.row.net / 100).toFixed(2);
        const formattedAmount = `${amount} ${toUpperCase(params.row.currency)}`;
        return formattedAmount;
      },
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'exchange_rate',
      headerName: t('table.headers.exchange_rate'),
      editable: false,
      flex: 0.1,
      minWidth: 100,
      valueGetter: (params) => {
        const amount = params.row.exchange_rate?.toFixed(3) || null;
        return amount;
      },
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'available_on',
      headerName: t('table.headers.available_on'),
      editable: false,
      flex: 0.1,
      minWidth: 100,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        const userLocale = navigator.languages
          ? navigator.languages[0]
          : navigator.language;

        // Create a date object with the adjusted time
        const date = dayjs.unix(params.row.created).add(30, 'day').toDate();

        // Format the date in the user's local format
        return new Intl.DateTimeFormat(userLocale, {
          dateStyle: 'short', // Adjust to 'full', 'long', 'medium', or 'short'
          // timeStyle: 'short', // Include time if needed
        }).format(date);
      },
    },
    {
      field: 'receipt_number',
      headerName: t('table.headers.receipt'),
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'receipt_url',
      headerName: '',
      sortable: false,
      align: 'left',
      renderCell: (params) => {
        // Handle Receipt View Click
        function handleViewClick(receipt_url) {
          if (!receipt_url) return;
          window.open(receipt_url, '_blank', 'noopener,noreferrer');
        }

        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            onClick={() => handleViewClick(params.row.receipt_url)}
            // tooltipTitle={transButtons('view receipt')}
            tooltipTitle={transButtons('View Receipt')}
            IconComponent={ReceiptIcon}
            iconOnly
          />
        );
      },
    },
    {
      field: 'edit',
      headerName: t('table.headers.payout'),
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        const currentDate = new Date();
        const transactionDate = dayjs.unix(params.row.created).toDate();
        const payoutDate = dayjs
          .unix(params.row.created)
          .add(30, 'day')
          .toDate();
        const daysToPayout = Math.ceil(
          (payoutDate - currentDate) / (1000 * 60 * 60 * 24),
        );

        const payoutEnabled = payoutDate <= transactionDate;
        const stripePayoutEnabled = stripeUserData.payout_enabled;
        console.log('Payout Date ->', payoutDate);
        console.log('Transaction Date ->', transactionDate);
        console.log('Days To Payout ->', daysToPayout);
        console.log('Payout Enabled ->', stripePayoutEnabled);

        return payoutEnabled ? (
          <IntuIconButton
            size="small"
            type="payout"
            variant="outlined"
            iconOnly={true}
            onClick={() => {
              setBalanceTransactionDetails(params.row);
              setManageBalanceDetailsTransactionsModal(true);
            }}
            tooltipTitle={transButtons('payout')}
          />
        ) : (
          <IntuIconButton
            size="small"
            type="warning"
            variant="outlined"
            iconOnly={true}
            tooltipTitle={transButtons('payout_pending', {
              days: daysToPayout,
            })}
          />
        );
      },
    },
  ];
};

const StripeBalanceTransactionsList = () => {
  const [balanceTransactions, setBalanceTransactions] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.stripe.BalanceTransactionsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);
  const [isListLoading, setIsListLoading] = useState(false);

  const [
    manageBalanceDetailsTransactionsModal,
    setManageBalanceDetailsTransactionsModal,
  ] = useState(false);
  const [balanceTransactiondDetails, setBalanceTransactionDetails] = useState(
    {},
  );

  // Close Payout Accounts Modal
  const handleBalanceTransactionModalCloseState = (action) => {
    if (action === 'edit') {
      setManageBalanceDetailsTransactionsModal(false);
    }
  };

  const tableColumns = useMemo(() => {
    return createColumns(
      t,
      transButtons,
      transTypes,
      setManageBalanceDetailsTransactionsModal,
      setBalanceTransactionDetails,
      theme,
      stripeUserData,
    );
  }, [t, handleBalanceTransactionModalCloseState]);

  const getBalanceTransactions = async () => {
    setIsListLoading(true);

    try {
      const { data, status } = await listBalanceTransactions();
      if (status === 200) {
        setBalanceTransactions(data.data.transactions);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getBalanceTransactions();
  }, []);

  return (
    <BalanceTransactionDetailsContext.Provider
      value={{ balanceTransactiondDetails, setBalanceTransactionDetails }}
    >
      <Grid container spacing={5} sx={{ marginBottom: '3rem' }}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('title')}</Typography>
          <Divider sx={{ marginBottom: '2rem' }} />

          {isListLoading ? (
            <ListLoader columns={5} rows={4} />
          ) : !balanceTransactions?.length ? (
            <Grid item className="title content-centered" xs={12}>
              <Typography variant="body1">
                <Trans t={t} i18nKey="table.no_data" />
              </Typography>
            </Grid>
          ) : (
            <Box width="100%">
              <DataGrid
                isCellEditable={() => false}
                getRowId={(row) => row.id}
                rows={balanceTransactions}
                columns={tableColumns}
                hideFooterPagination
                hideFooterSelectedRowCount
                columnVisibilityModel={{
                  list_price: !isMobileView,
                  attributes: !isMobileView && !isMediumView,
                  applications: !isMobileView && !isMediumView,
                  product_group: !isMobileView && !isMediumView,
                  mpn: !isMobileView,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  sorting: {
                    sortModel: [{}],
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{
                  Row: MemoizedRow,
                  ColumnHeaders: MemoizedColumnHeaders,
                }}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 6,
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          )}
        </Grid>

        {/* <ManagePayoutAccount
            open={manageBalanceDetailsTransactionsModal}
            onClose={() => handleBalanceTransactionModalCloseState('edit')}
            getBalanceTransactions={getBalanceTransactions}
          /> */}
      </Grid>
    </BalanceTransactionDetailsContext.Provider>
  );
};

export default StripeBalanceTransactionsList;
