import { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Formik, useFormik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import i18n from '../../i18n.js';
import { getIndustries } from '../../api/industriesRoutes.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { ApplicationsContext } from '../../context/applications/ApplicationsContextProvider.jsx';
import { useTheme } from '@emotion/react';
import { useIntuTheme } from '../../context/providers/IntuThemeProvider.jsx';
import IntuIconButton from '../buttons/IntuIconButton.jsx';

export const NewApplicationDialog = () => {
  const { t } = useTranslation('dialogs', {
    keyPrefix: 'NewApplicationDialog',
  });
  const theme = useTheme();
  const { mode } = useIntuTheme();

  const { setValues: parentFormSetValues } = useFormikContext();

  const {
    newApplicationDialogOpen,
    setNewApplicationDialogOpen,
    applicationNew,
    setApplicationNew,
    newApplicationList,
    setNewApplicationList,
  } = useContext(ApplicationsContext);

  const [industryOptions, setIndustryOptions] = useState([]);
  const { handleErrorResponse } = useResponseHandling();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    //Get Industries from database
    async function handleGetIndustries() {
      try {
        let industriesList = await getIndustries();
        if (industriesList.status === 'success') {
          setIndustryOptions(industriesList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        controller.abort(signal);
      }
    }

    async function preLoadformInfo() {
      await handleGetIndustries();
    }

    preLoadformInfo();
  }, [i18n.language]);

  const formik = useFormik({
    initialValues: applicationNew,
    initialTouched: {},
    values: {},
  });

  const validationSchema = Yup.object();

  return (
    <Formik
      initialValues={applicationNew}
      values={formik.values}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setValues, setFieldValue, resetForm } = formik;
        return (
          <Dialog open={newApplicationDialogOpen}>
            <form>
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t('content')}</DialogContentText>

                {/* Application Name */}
                <TextField
                  autoFocus
                  disabled
                  margin="dense"
                  id=" application_name"
                  className="form-select-field"
                  value={values?.application_name}
                  onChange={(e) => {
                    let properOption = toTitleCase(e.target.value);
                    setFieldValue('application_name', properOption);
                  }}
                  label={t('form.application.label')}
                  placeholder={t('form.application.placeholder')}
                  type="text"
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      '-webkit-text-fill-color':
                        mode === 'dark'
                          ? theme.palette.disabled.main
                          : theme.palette.grey.main,
                    },
                  }}
                />

                {/* Industry Name */}
                <Autocomplete
                  id="industryName"
                  name="industryName"
                  options={industryOptions?.sort(
                    (a, b) => -b.industry_name.localeCompare(a.industry_name),
                  )}
                  getOptionLabel={(option) => option.industry_name}
                  className="form-select-field"
                  sx={{ paddingBottom: '50px' }}
                  onChange={(e, options, reason, element) => {
                    if (reason === 'selectOption') {
                      setValues({
                        ...values,
                        industry_id: element.option.industry_id,
                        industry_name: element.option.industry_name,
                      });
                    } else if (reason === 'clear') {
                      setValues({
                        ...values,
                        industry_id: null,
                        industry_name: null,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="dense"
                      value={values?.application_new?.industry_name}
                      label={t('form.industry.label')}
                      placeholder={t('form.industry.placeholder')}
                      type="text"
                      variant="standard"
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                  {/* Cancel */}
                  <Grid item>
                    <IntuIconButton
                      type="cancel"
                      tooltipTitle={t('buttons.cancel')}
                      onClick={() => {
                        setNewApplicationDialogOpen(false);
                        setApplicationNew({
                          industry_id: '',
                          industry_name: '',
                          application_name: '',
                        });
                      }}
                    />
                  </Grid>

                  {/* Add */}
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <IntuIconButton
                      type="submit"
                      tooltipTitle={t('buttons.add')}
                      disabled={
                        !values?.application_name || !values?.industry_id
                          ? true
                          : false
                      }
                      onClick={async (e) => {
                        // Set New Applications
                        let newApplication = {
                          application_name: values.application_name,
                          industry_id: values.industry_id,
                          industry_name: values.industry_name,
                        };
                        parentFormSetValues((parentformValues) => ({
                          ...parentformValues,
                          applications: [
                            ...parentformValues.applications,
                            newApplication,
                          ],
                        }));

                        setNewApplicationList([
                          ...newApplicationList,
                          newApplication,
                        ]);

                        resetForm();

                        setNewApplicationDialogOpen(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};
