import { useContext, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { CartContext } from '../../context/cart/CartContext.jsx';
import CarriersBox from './CarriersBox.jsx';
import OrderShippingContainer from './OrderShippingContainer.jsx';
import TermsOfServiceBox from './TermsOfServiceBox.jsx';
import ElementsContainer from './ElementsContainer.jsx';
import PlaceOrderButtonContainer from './PlaceOrderButtonContainer.jsx';
import { PaymentElement } from '@stripe/react-stripe-js';
import TaxContainer from './TaxContainer.jsx';

const OrderTotalsBox = ({ shippingAddress, paymentIntentSecret, orderId }) => {
  const { cartProducts, cartSubTotal, cartTotal } = useContext(CartContext);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);

  const onPaymentElementChange = (e) => {
    setIsCardComplete(e.complete);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      my={4}
      width="60%"
      margin="0 auto"
      minWidth={800}
      boxShadow={2}
    >
      <Typography my={2}>Your Order</Typography>
      <Box
        boxShadow={2}
        display="flex"
        flexDirection="column"
        border="1px solid"
        borderColor="secondary.light"
        borderRadius="4px"
        mb={8}
      >
        <Box>
          {cartProducts.map((product) => {
            const subtotal = product.product.list_price * product.quantity;
            return (
              <Box key={product.product._id}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="4px"
                  p={4}
                >
                  <Typography flex={1}>
                    {product.product.product_name}
                  </Typography>
                  <Typography flex={0.25} textAlign="center">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(subtotal)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Box px={4} my={2}>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              my={2}
            >
              <Typography flex={1}>Subtotal</Typography>
              <Typography flex={0.25} textAlign="center">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(cartSubTotal)}
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Box display="flex">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <CarriersBox
              selectedCarrier={selectedCarrier}
              setSelectedCarrier={setSelectedCarrier}
            />
            <OrderShippingContainer
              shippingAddress={shippingAddress}
              selectedCarrier={selectedCarrier}
            />
            <Box p={4} mb={4}>
              {/* <TaxContainer shippingAddress={shippingAddress} /> */}
              <Divider />
              <Box
                mt={8}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" textTransform="none" flex={1}>
                  Total:
                </Typography>
                <Typography variant="body1" flex={0.8} textAlign="center">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(cartTotal)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {paymentIntentSecret ? (
          <ElementsContainer paymentIntentSecret={paymentIntentSecret}>
            <Box px={4}>
              <PaymentElement onChange={onPaymentElementChange} />
            </Box>
            <Box display="flex" justifyContent="space-between" my={4} p={4}>
              <TermsOfServiceBox
                areTermsAccepted={areTermsAccepted}
                setAreTermsAccepted={setAreTermsAccepted}
              />
              <PlaceOrderButtonContainer
                areTermsAccepted={areTermsAccepted}
                isCardComplete={isCardComplete}
                orderId={orderId}
              />
            </Box>
          </ElementsContainer>
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderTotalsBox;
