import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { CompanySalesTeamList } from '../../components/company/salesteam/CompanySalesTeamList';
import IntuPage from '../../components/layout/IntuPage';

function CompanySalesTeam() {
  const { t } = useTranslation();

  return (
    <IntuPage>
      <IntuForm
        form="Sales Team"
        formTitle={t('pages.company.companySalesTeam.title')}
        lessMT
      >
        <CompanySalesTeamList />
      </IntuForm>
    </IntuPage>
  );
}

export default CompanySalesTeam;
