import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function ConfirmActionDialog({ open, onClose, onConfirm, dialogText }) {
  const { t: transButtons } = useTranslation('buttons');
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textTransform="none">Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <Button onClick={onClose} color="primary" variant="outlined">
          {transButtons('no')}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          variant="contained"
        >
          {transButtons('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmActionDialog;
