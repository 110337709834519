import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import { StripeElementWrapper, StripeTextField } from '../../mui/inputStyles';
import { CardCvcElement, useElements } from '@stripe/react-stripe-js';

const StripeCvcField = React.forwardRef((props, ref) => (
  <CardCvcElement
    {...props}
    onReady={(element) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref.current !== undefined) {
          ref.current = element;
        }
      }
    }}
  />
));

const CreditCardCVCField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'cvc', // Defines the ID and Name of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'CreditCardCVCField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', // Design standard or
  isLoadingManual = false, // Manually control he loading state
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidations } = useTranslation('validations');
  const elements = useElements();

  // Field Ref
  const creditCardCVCNumber = useRef();

  // Context
  const { values, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <StripeTextField
        disabled={disabled}
        variant={variant}
        required={required}
        label={t('label')}
        inputRef={creditCardCVCNumber}
        InputLabelProps={{ shrink: true }}
        value={fieldValue}
        onBlur={() => {
          const cardCvcElement = elements.getElement('cardCvc');

          // Ensure the element exists before accessing its properties
          if (!cardCvcElement) {
            setFieldError('cvc', transValidations('cvc.not_mounted')); // Add a translation for this case
            return;
          }

          // Validate based on Stripe's `_empty` and `_invalid` flags
          if (cardCvcElement._empty) {
            setFieldError('cvc', transValidations('cvc.required')); // Add your translation key
          } else if (cardCvcElement._invalid) {
            setFieldError('cvc', transValidations('cvc.invalid')); // Add your translation key
          } else {
            setFieldError('cvc', ''); // Clear errors if valid
          }

          // Mark the field as touched in Formik
          setFieldTouched('cvc', true);
        }}
        InputProps={{
          inputComponent: StripeElementWrapper,
          inputProps: {
            Component: CardCvcElement,
            options: {
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  letterSpacing: '0.025em',
                  '::placeholder': { color: '#aab7c4' },
                },
                invalid: { color: '#9e2146' },
              },
            },
          },
        }}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default CreditCardCVCField;
