import { useEffect, useContext } from 'react';

// Import User Context
import useUser from '../../hooks/useUser.js';

// Process & Reposne Handler
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';

// @React-page
import '@in2tec/page-editor/lib/index.css';

// Get Context
import { PostContext } from '../../context/providers/PostContextProvider.jsx';
import PreLoadContext from '../../hooks/usePreLoadHandler.js';

// Translator
import { useTranslation } from 'react-i18next';

// Routes
import { searchPosts } from '../../api/blogRoutes.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import useAuth from '../../hooks/useAuth.js';
import { PostEditForm } from './forms/PostEditForm.jsx';
import { PostViewForm } from './forms/PostViewForm.jsx';

const PostContent = (props) => {
  const { postInfo, setPostInfo, setPostImage } = useContext(PostContext);
  const { user } = useUser();
  const { auth, isAdmin } = useAuth();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const location = useLocation();
  const { t } = useTranslation();
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');

  // Loading Spinner Status
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useProcessingHandler();

  // Pre Load Form Data
  useEffect(() => {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.loading'),
    });

    const mode = props?.mode;

    // Get post path
    const path = location?.pathname;
    const params = new URLSearchParams(window.location.search);
    const tracker = params.get('tracker') === 'false' ? false : true;

    if (!path) {
      navigate(-1);
    }

    async function getPost(props) {
      const link = path.substring(path.lastIndexOf('/') + 1);

      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const request = await searchPosts(link, auth);

        if (request.status === 'success') {
          let data = request.data[0];
          // Check if Pos has Image and convert it
          const imageUrl = data?.image_url;

          if (imageUrl) {
            const imageResponse = await fetch(imageUrl, { signal });
            if (!imageResponse.ok) {
              throw new Error(
                'Network response was not ok' + imageResponse.statusText,
              );
            }
            const imageBlob = await imageResponse.blob();
            const reader = new FileReader();

            reader.onloadend = () => {
              const imageBase64 = reader.result; // Store the base64 data in a variable

              // Finally, update postInfo with all changes
              // setPostInfo(updatePostInfo);
              setPostImage({
                image: reader.result,
                imageBlob: imageBase64,
              });
              data = {
                ...data,
                image: reader.result,
              };
            };

            reader.readAsDataURL(imageBlob);
          }

          // Set View or Edit Mode
          if (props?.inEditMode === true) {
            // Check if user is admin or author
            if (
              auth?.user_info?.full_name === postInfo?.author.name ||
              isAdmin
            ) {
              data = {
                ...data,
                inEditMode: true,
              };
            }
          }

          // Update postInfo with all changes
          setPostInfo(data);
        } else {
          // handleRegularResponse({
          //   open: true,
          //   status: request.status,
          //   message: request.message,
          // });
          navigate('/missing');
        }
      } catch (err) {
        handleErrorResponse(err);
        navigate('/missing');
      }
    }

    async function trackActivity() {
      const controller = new AbortController();
      const signal = controller.signal;

      if (!postInfo?._id) {
        return;
      }

      try {
        const payload = {
          _id: postInfo._id,
          user: user,
          module: 'posts',
          action: 'Post View',
          tracker,
          additionalParams: {
            title: postInfo.title,
          },
        };
        const url = '/api/tracker/track';
        const request = await axios.post(url, JSON.stringify(payload), {
          signal,
          headers: { 'Content-Type': 'application/json' },
        });
        // let response = request?.data
        // console.log(response)
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    // Get Lead Content
    async function getPostContent() {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: transMessages('spinner.global.fetch', {
          type: transTypes('post'),
        }),
      });
      try {
        if (user.location && user.sessionID) {
          if (mode === 'viewPost') {
            // Content missing, fetching data from server using the path
            // console.log(`Fetching Post`)
            await getPost({ inEditMode: false });
            await trackActivity();
          } else if (mode === 'editPost') {
            await getPost({ inEditMode: true });
          } else if (mode === 'createPost') {
            // let link = props?.link
            await getPost({ inEditMode: true });
          }
          setIsLoading({ status: false, type: '', text: '' });
        }
      } catch (error) {
        console.log(error);
      }
    }

    getPostContent();
  }, [user?.sessionID, user, postInfo._id]);

  return (
    <>
      {isLoading.status === true ? (
        <PreLoadContext />
      ) : postInfo.inEditMode === true ? (
        <PostEditForm />
      ) : (
        <PostViewForm />
      )}
    </>
  );
};

export { PostContent };
