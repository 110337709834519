import { useEffect, useState, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import axios from '../../hooks/axios/axios';
import ProductCard from '../../components/products/ProductCard';
import ProductFilter, {
  PRICE_ASC,
  PRICE_DESC,
} from '../../components/products/ProductsFilter';
import { useTranslation } from 'react-i18next';

const fetchAllProducts = async () => {
  const { data, status } = await axios.get('/api/products/published');
  if (status === 200) {
    return data.data;
  } else {
    return null;
  }
};

const Products = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetchAllProducts()
      .then((res) => setProducts(res))
      .catch((err) => console.warn(err))
      .finally(() => setIsLoading(false));
  }, []);

  const filteredProducts = useMemo(() => {
    if (!products) return null;

    let _products = [...products];

    if (searchFilter) {
      _products = products.filter((product) =>
        searchFilter.toLowerCase().includes(product.product_name.toLowerCase()),
      );
    }

    if (selectedFilterOption === PRICE_ASC) {
      return _products.sort((a, b) => a.list_price - b.list_price);
    }
    if (selectedFilterOption === PRICE_DESC) {
      return _products.sort((a, b) => b.list_price - a.list_price);
    }

    return _products;
  }, [searchFilter, selectedFilterOption, products]);

  const options = useMemo(() => {
    if (!products) return [];

    return products.map((product) => ({
      label: product.product_name,
    }));
  }, [products]);

  const handleAutocompleteChange = (e, values) => {
    if (!values.length) {
      setSearchFilter(null);
      return;
    }

    const newestValue = values.at(-1);
    setSearchFilter((prevFilter) => {
      if (prevFilter) {
        return `${prevFilter} ${newestValue.label}`;
      } else {
        return newestValue.label;
      }
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      mt={4}
    >
      <Typography variant="h1">{t('form.products.label_alt')}</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box width="100%" px={4}>
          <Box display="flex" justifyContent="center">
            <Autocomplete
              multiple
              disablePortal
              id="search-autocomplete"
              getOptionLabel={(option) => option.label}
              options={options}
              selectOnFocus
              handleHomeEndKeys
              sx={{ width: '50%', mr: 2 }}
              onChange={handleAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search for a product"
                  value={searchFilter || ''}
                />
              )}
            />
            <ProductFilter onFilterClick={setSelectedFilterOption} />
          </Box>
          {Array.isArray(filteredProducts) && filteredProducts.length === 0 ? (
            <Box mt={4} display="flex" justifyContent="center">
              <Typography>No results found</Typography>
            </Box>
          ) : null}
          <Box
            width="100%"
            minHeight="100vh"
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            mt={4}
            gap={2}
            mb={10}
          >
            <AnimatePresence>
              {filteredProducts.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </AnimatePresence>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Products;
