import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { Skeleton, Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';

const PersonRelationshipField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'relationship', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Disables the field
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'PersonRelationshipField', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Manually control the Loading State,
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Field Ref
  const relationshipRef = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID) || {};
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  // Options for the Autocomplete field
  const options = [
    { label: t('options.representative'), value: 'representative' },
    { label: t('options.director'), value: 'director' },
    { label: t('options.executive'), value: 'executive' },
    { label: t('options.owner'), value: 'owner' },
  ];

  // Map fieldValue keys to selected options
  const selectedOptions = options.filter(
    (option) => fieldValue[option.value] === true,
  );

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <Autocomplete
        multiple
        disableClearable
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={selectedOptions}
        onChange={(event, newValue) => {
          // Create an object with true for selected values
          const updatedValue = options.reduce((acc, option) => {
            acc[option.value] = newValue.some(
              (selected) => selected.value === option.value,
            );
            return acc;
          }, {});
          setFieldValue(fieldID, updatedValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            variant="outlined"
            label={t('label')}
            placeholder={t('placeholder')}
            error={
              showInitialErrors
                ? fieldError
                  ? true
                  : false
                : fieldTouched && fieldError
                  ? true
                  : false
            }
            helperText={
              showInitialErrors
                ? fieldError
                  ? fieldError
                  : null
                : fieldError
                  ? fieldError
                  : null
            }
          />
        )}
        sx={{ width: '100%' }}
      />
    </FormikFieldWrapper>
  );
};

export default PersonRelationshipField;
