import {
  DataGrid,
  GridToolbar,
  GridFooterContainer,
  GridFooter,
} from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { createContext, memo, useContext, useState } from 'react';
import { CompanyProfileContext } from '../../../../context/company/CompanyProfileProvider';
import { Fade, Skeleton, Typography } from '@mui/material';
import IntuThemeContext from '../../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import { EmployeeDetailsDialog } from './EmployeeDetailsDialog';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import IntuIconButton from '../../../buttons/IntuIconButton';

export const CompanyEmployeesListContext = createContext();

const CompanyEmployeesList = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
}) => {
  const { isLoading } = useContext(ProcessingContext);
  const { setEmployeeDetails } = useContext(CompanyProfileContext);
  const { transitionInterval, mobileView } = useContext(IntuThemeContext);

  // Translator
  const { t } = useTranslation('fields');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { companyProfile } = useContext(CompanyProfileContext);

  //   Employee Details Dialog
  const [employeeDialog, setEmployeeDialog] = useState({
    open: false,
    type: null,
  });

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  // Lead actvivity list
  const employeeColumns = [
    {
      field: 'name',
      headerName: t('global.label.name'),
      sortable: true,
      editable: false,
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: 'email',
      headerName: t('global.label.email'),
      sortable: true,
      editable: false,
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'role',
      headerName: t('global.label.role'),
      editable: false,
      sortable: true,
    },
    {
      field: 'edit',
      headerName: t('global.label.edit'),
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          const contactContent = params.row;
          setEmployeeDetails(contactContent);
          setTimeout(
            setEmployeeDialog({
              open: true,
              action: 'edit',
            }),
            '3000',
          );
        };
        return (
          <IntuIconButton type="edit" onClick={onClick} tooltipTitle="edit" />
        );
      },
    },
  ];

  const handleAddUserOnClick = () => {
    setTimeout(
      setEmployeeDetails({
        _id: '',
        email: '',
        name: '',
        role: '',
      }),
      setEmployeeDialog({
        open: true,
        action: 'add',
      }),
      '3000',
    );
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <IntuIconButton
          type="add"
          tooltipTitle={transButtons('add', { type: transTypes('employee') })}
          onClick={handleAddUserOnClick}
        />
        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    companyProfile.isAdmin &&
    (isLoading.status ? (
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    ) : (
      <CompanyEmployeesListContext.Provider
        value={{
          employeeDialog,
          setEmployeeDialog,
        }}
      >
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {fieldTitle}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {fieldDescription}
            </Typography>
          </Fade>
        )}
        <DataGrid
          isCellEditable={(params) => false}
          isReadOnly={true}
          getRowId={(row) => row._id}
          rows={companyProfile.employees}
          columns={employeeColumns}
          columnVisibilityModel={{
            email: !mobileView,
            role: !mobileView,
          }}
          components={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                first_name: !mobileView,
              },
            },
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: 20,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'orange',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
              borderRadius: 2,
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
            footer: CustomFooter,
          }}
        />
        <EmployeeDetailsDialog />
      </CompanyEmployeesListContext.Provider>
    ))
  );
};

export default CompanyEmployeesList;
