import { useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Formik, useFormik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import { StyledNumberInput } from '../../../../mui/styledNumberInput.js';
import { CompanyProfileContext } from '../../../../context/company/CompanyProfileProvider.jsx';

export const CompanyPaymentTermsDialog = () => {
  const { t } = useTranslation('dialogs', {
    keyPrefix: 'NewPaymentTermDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { setValues: parentFormSetValues, values: parentFormValues } =
    useFormikContext();
  const {
    newPaymentTermDialogOpen,
    setNewPaymentTermDialogOpen,
    newPaymentTerm,
    setNewPaymentTerm,
    newPaymentTermsList,
    setNewPaymentTermsList,
  } = useContext(CompanyProfileContext);

  const formik = useFormik({
    initialValues: newPaymentTerm,
    initialTouched: {},
    values: {},
  });

  const validationSchema = Yup.object();

  return (
    <Formik
      initialValues={newPaymentTerm}
      values={formik.values}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setValues, setFieldValue, resetForm } = formik;
        return (
          <Dialog open={newPaymentTermDialogOpen}>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogContent>
              <Grid item mb={3}>
                <DialogContentText>{t('content')}</DialogContentText>
              </Grid>
              <Grid item>
                <StyledNumberInput
                  aria-label="Demo number input"
                  placeholder={t('placeholder')}
                  value={values.payment_terms}
                  onChange={(event, val) => setFieldValue('payment_term', val)}
                  min={0}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <IntuIconButton
                    type="cancel"
                    tooltipTitle={transButtons('cancel')}
                    onClick={() => {
                      setNewPaymentTermDialogOpen(false);
                      setNewPaymentTerm({
                        payment_term: '',
                      });
                      setValues('');
                    }}
                  />
                </Grid>
                {/* Add */}
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    tooltipTitle={transButtons('add')}
                    disabled={!values?.payment_term ? true : false}
                    onClick={async (e) => {
                      const newPaymentTerm = {
                        payment_term: `${values.payment_term} ${t('days_net')}`,
                      };

                      if (parentFormValues.payment_terms.length > 0) {
                        parentFormSetValues((parentformValues) => ({
                          ...parentformValues,
                          payment_terms: [
                            ...parentformValues?.payment_terms,
                            newPaymentTerm,
                          ],
                        }));
                      } else {
                        parentFormSetValues((parentformValues) => ({
                          ...parentformValues,
                          payment_terms: [newPaymentTerm],
                        }));
                      }

                      setNewPaymentTermsList([
                        ...newPaymentTermsList,
                        newPaymentTerm,
                      ]);

                      resetForm();

                      setNewPaymentTermDialogOpen(false);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};
