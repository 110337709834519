import IntuPage from '../../components/layout/IntuPage.jsx';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';
import { CreditsExplainerComponent } from '../../components/credits/CreditsExplained.jsx';

const CreditsExplainer = () => {
  const { t } = useTranslation();
  return (
    <IntuPage
      pageTitle={i18n.format(t('credits.title'), 'uppercase')}
      hCentered
    >
      <CreditsExplainerComponent />
    </IntuPage>
  );
};

export default CreditsExplainer;
