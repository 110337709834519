import { Stack, Skeleton } from '@mui/material';

const ListLoader = ({ columns = 5, rows = 5 }) => {
  // Helper to create an array of specific length
  const createArray = (length) => Array.from({ length }, (_, i) => i);

  return (
    <Stack direction="column" spacing={2}>
      {createArray(rows).map((_, rowIndex) => (
        <Stack key={rowIndex} direction="row" spacing={1} height={50}>
          {createArray(columns).map((_, colIndex) => (
            <Skeleton
              key={colIndex}
              variant="rectangular"
              animation="wave"
              width={`${100 / columns}%`} // Dynamically adjust column width
              height={30}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default ListLoader;
