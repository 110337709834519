import { axiosPrivate } from '../../hooks/axios/axios.js';
import i18n from '../../i18n.js';

// List Payout Accounts
async function listPayoutAccount(query) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/externalAccounts/list';

  try {
    const listPayoutAccounts = await axiosPrivate.get(url, {
      query,
      signal,
    });
    return listPayoutAccounts;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

// Update Payout Account
async function updateConnectPayoutAccount({
  bankAccountID = null, // ID of connected bank account
  stripeAccountId = null, // ID of connected stripe account (optional)
  updateParam = {}, // Update Parameters
}) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/externalAccounts/update';

  const req_fields = [];

  if (!bankAccountID) {
    req_fields.push(
      i18n.t('validations:id', {
        dataType: i18n.t('types:bank_account'),
      }),
    );
  }

  if (!updateParam) {
    req_fields.push(
      i18n.t('validations:pram', {
        dataType: i18n.t('types:stripe_account'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const updateConnectPayoutAccount = await axiosPrivate.put(
      url,
      JSON.stringify(updateParam),
      {
        params: {
          bank_account: bankAccountID,
          stripe_account: stripeAccountId || null,
        },
        signal,
      },
    );

    console.log(
      'Result from updating External Account',
      updateConnectPayoutAccount,
    );

    return updateConnectPayoutAccount;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}
export { listPayoutAccount, updateConnectPayoutAccount };
