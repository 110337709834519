import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getProductCategories,
  getProductGroups,
} from '../../api/productsRoutes';

const NewProductTypeDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  selectedAttribute,
}) => {
  const { t } = useTranslation();
  const [newProductType, setNewProductType] = useState('');
  const [productGroups, setProductGroups] = useState([]);
  const [productFamilies, setProductFamilies] = useState([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [selectedProductFamily, setSelectedProductFamily] = useState(null);

  // useEffect(() => {
  //   setNewProductType({ ...selectedAttribute });
  // }, [selectedAttribute]);

  useEffect(() => {
    fetchProductGroups();
    fetchProductFamilies();
  }, []);

  const fetchProductGroups = async () => {
    const { data, status } = await getProductGroups();
    if (status === 'success') {
      setProductGroups(data);
    }
  };

  const fetchProductFamilies = async () => {
    const { data, status } = await getProductCategories();
    if (status === 'success') {
      setProductFamilies(data);
    }
  };

  const handleInputChange = (e) => {
    setNewProductType(e.target.value);
  };

  const handleProductGroupChange = (e) => {
    setSelectedProductGroup(e.target.value);
  };

  const handleProductFamilyChange = (e) => {
    setSelectedProductFamily(e.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>New Product Type</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <Select onChange={handleProductGroupChange}>
            {productGroups.map((group) => {
              return (
                <MenuItem key={group.group_id} value={group}>
                  {group.group_name}
                </MenuItem>
              );
            })}
          </Select>
          <Select onChange={handleProductFamilyChange} sx={{ my: 2 }}>
            {productFamilies.map((family) => {
              return (
                <MenuItem key={family.family_id} value={family}>
                  {family.family_name}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            label="New Product Type Value"
            variant="outlined"
            size="small"
            name="productTypeValue"
            value={newProductType}
            onChange={handleInputChange}
            // error={
            //   newProductType.productTypeValue < 0 ||
            //   newProductType.productTypeValue > 100
            // }
            // helperText={'Value must be between 0 and 100'}
            sx={{ mb: 2 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button onClick={handleClose} color="secondary" variant="contained">
          {t('buttons.close')}
        </Button>
        <Button
          onClick={() =>
            handleConfirm({
              productFamily: selectedProductFamily,
              productGroup: selectedProductGroup,
              newProductType: newProductType,
            })
          }
          color="primary"
          type="submit"
          disabled={false}
          variant="contained"
        >
          {t('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewProductTypeDialog;
