import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { StripeContext } from '../../context/providers/stripe/StripeContextProvider';
import ResponseContext from '../../context/providers/ResponseProvider';
import useAuth from '../../hooks/useAuth';

const defaultStatusOptions = [
  { value: 'draft', label: 'Draft' },
  { value: 'archived', label: 'Archive' },
  { value: 'published', label: 'Publish' },
];

const StatusBox = ({ product, setProduct }) => {
  const formik = useFormikContext();
  const { auth } = useAuth();
  const { stripeUserData } = useContext(StripeContext);
  const { setErrorDialog } = useContext(ResponseContext);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if user is admin and has stripe account completed
    if (
      value === 'published' &&
      !stripeUserData.account_complete &&
      auth.user_info.account_type !== 'intutec'
    ) {
      setErrorDialog({
        open: true,
        title: 'Action Not Allowed',
        message: 'You cannot publish without a complete Stripe account.',
      });
      return;
    }

    formik.handleChange(e);
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box>
      <FormControl sx={{ flex: 0.4 }}>
        {/* <Typography mb={2}>{transFields('products.currency')}</Typography> */}
        <Typography mb={2}>Status</Typography>
        <Select
          id="new-product-currency-label-select"
          onChange={handleChange}
          // label={transFields('products.currency')}
          label="label"
          name="status"
          defaultValue={defaultStatusOptions[0].value}
          error={formik.errors.status && formik.touched.status}
          value={product.status}
          sx={{ minWidth: 100 }}
        >
          {defaultStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StatusBox;
