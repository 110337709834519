// Is providing context to the lead sign up forms and how it works for leads sales reps, manufacturers, affiliate and investor pages]

import { createContext, useContext, useEffect, useState } from 'react';
import { useAnalyticsPageViewTracker } from '../../../hooks/useAnalyticsTracker.jsx';
import UserContext from '../../users/UserInfoProvider.jsx';
import ProcessingContext from '../ProcessingProvider.jsx';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import { searchIndustries } from '../../../api/industriesRoutes.js';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import i18n from 'i18next';
import IntuThemeContext from '../IntuThemeProvider.jsx';
import ResponseContext from '../ResponseProvider.jsx';
import { blankLeadForm } from './blankLeadForm.js';
import { formatValueAsStringWithSign } from '../../../helpers/formatValueAsStringWithSign.js';
import { IndustriesContext } from '../../industries/IndustriesContextProvider.jsx';

export const LeadContext = createContext({});

export const LeadProvider = ({ children }) => {
  // Axios
  const axios = useAxiosPrivate();

  // Translator & Language
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks',
  });

  const { t: transMessages } = useTranslation('messages');

  // Context
  const { host_url, user, lng } = useContext(UserContext);
  const { setIsLoading } = useContext(ProcessingContext);
  const { handleGetIndustries } = useContext(IndustriesContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setStatusContext } = useContext(ResponseContext);

  const [leadType, setLeadType] = useState();
  const [leadContext, setLeadContext] = useState({
    benefits: [],
    how_it_works: {},
    additional_content: [
      {
        theme: '',
        layout: '',
        header: ``,
        size_column_1: [],
        content_column_1: ``,
        size_column_2: [],
        content_column_2: '',
        size_additional_component: [],
        additional_component: '',
        title_additional_component: '',
      },
    ],
  });

  // Revenue Commission
  const [revenueCommission, setRevenueCommission] = useState({
    market_share: 10,
    revenue: 0,
    commission_order_percent: 0,
    commission_order: 0,
    commissions_per_order_percent: 0,
    commissions: [],
    commission_schedule: [],
  });

  // Global States shared accross components
  const leadForm = blankLeadForm({ lng });
  const [leadInfo, setLeadInfo] = useState(leadForm);
  const [leadDetailsDialog, setLeadDetailsDialog] = useState({
    open: false,
    lead_type: '',
    title: 'Title',
    text: 'Description',
    primaryButtonText: '',
    secondaryButtonText: '',
    dialogAction: '',
  });

  // google page view tracking tracking
  let { gaPageViewTracker } = useAnalyticsPageViewTracker();

  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'How it works Page (Sutomized)',
  });

  const { mode } = useContext(IntuThemeContext);

  // Get Specific Lead Info
  async function getLeadDetails({ referralID, tracker = true }) {
    if (!referralID) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/intutec/leads/visit';

    try {
      const payload = {
        code: referralID,
        user: user,
      };
      const response = await axios.post(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
      });

      const { data, status } = response;
      const { data: leadData, commissions: commissionData } = data;

      if (status === 200) {
        // restructure rates for calulator
        const leadType = leadData.lead_type;
        let commissionsTable = [];
        let commissionsData;
        if (leadData.active) {
          commissionsData = commissionData.map((commission) => ({
            _id: commission._id,
            maxAmount: commission.max_amount,
            rate:
              leadType === 'salesrep'
                ? commission.sales_commission
                : commission.manufacturer_commission,
            intu_rate:
              leadType === 'investor' ? commission.intu_commission : null,
          }));

          for (let i = 0; i < commissionsData.length; i++) {
            const commission = commissionsData[i];
            const rate = commission.rate * 100;
            const intu_rate = commission.intu_rate * 100;
            let bracket;

            if (commission.maxAmount > 0) {
              bracket = commission.maxAmount.toLocaleString('en-US');
            } else {
              const lastBracket = commissionsData[i - 1].maxAmount;
              bracket = `> ${lastBracket}`;
            }
            commissionsTable.push({
              _id: commission._id,
              maxAmount: formatValueAsStringWithSign(bracket, 'US$'),
              rate: formatValueAsStringWithSign(rate, '%'),
              intu_rate: formatValueAsStringWithSign(intu_rate, '%'),
              amount: '',
            });
          }

          let industryNice;
          if (leadData?.industries && leadData.industries.length > 0) {
            const industriesByName = await searchIndustries(
              leadData.industries,
            );
            if (industriesByName?.length > 1) {
              const resultsArray = industriesByName.map(
                (industry) => industry.industry_name,
              );
              industryNice = resultsArray?.join(', ');
            }
          }

          setLeadInfo({ ...leadData, industry: industryNice });
          setRevenueCommission({
            ...revenueCommission,
            commissions: commissionsData,
            commission_schedule: commissionsTable,
          });

          // Track Lead Visit
          if (tracker === true) {
            await trackLeadActivity({ tracker: tracker, lead_info: leadData });
          }
        } else {
          // Lead is not active yet
          setLeadInfo(leadData);
          setStatusContext({
            open: true,
            title: `${t('status.inactive_lead_title')} ${leadData?.first_name}!`,
            content: leadData?.email
              ? t('status.inactive_lead_content_email', {
                  firstName: leadData?.first_name,
                  email: leadData?.email,
                })
              : t('status.inactive_lead_content', {
                  firstName: leadData?.first_name,
                }),
            action: null,
          });
        }
      } else if (status === 231) {
        // Lead is not active yet
        setLeadInfo(leadData);
        setStatusContext({
          open: true,
          title: t('status.inactive_lead_title', {
            firstName: leadData.first_name,
          }),
          content: leadData?.email
            ? t('status.inactive_lead_content_email', {
                firstName: leadData.first_name,
                email: leadData.email,
              })
            : t('status.inactive_lead_content', {
                firstName: leadData.first_name,
                email: leadData.email,
              }),
          action: null,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: t('status.invalid_code'),
        });
        setTimeout(function () {
          // Redirect to the desired URL
          window.location.href = `https://${host_url}/how-it-works`;
        }, 1000); // Adjust the delay time as needed
      }
    } catch (error) {
      handleErrorResponse(error);
      setLeadInfo({ ...leadInfo, lead_type: 'none' });
      console.error('error', error);
    } finally {
      controller.abort(signal);
    }
    return leadInfo;
  }

  // Track activity
  async function trackLeadActivity({ tracker = true, lead_info = leadInfo }) {
    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/tracker/track';

    if (!lead_info || !user?.cookieSettings?.trck) {
      return;
    }

    try {
      const payload = {
        _id: lead_info._id,
        user: user,
        module: 'intutec_leads',
        action: 'Lead Visit',
        tracker,
        additionalParams: {
          company: lead_info?.company,
        },
      };
      await axios.post(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      controller.abort(signal);
    }
  }

  // Preload Form
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referralID = params.get('referralID');
    const tracker = params.get('tracker') === 'false' ? false : true;

    // Guard Clause if there is no referal ID
    if (!referralID) {
      return;
    }

    // Get Lead Content
    async function getLeadContent() {
      await handleGetIndustries();

      if (referralID && user.location && user.sessionID) {
        // TODO: this runs and disrupts signup userflow
        setIsLoading({
          status: true,
          type: 'spinner',
          text: transMessages('spinner.leads'),
        });
        await getLeadDetails({ referralID: referralID, tracker: tracker });
        setIsLoading({ status: false, type: '', text: '' });
        window.scrollTo(0, 0);
      } else {
        setLeadInfo({
          ...leadInfo,
          lead_type: 'none',
        });
      }
    }
    getLeadContent();

    return () => {};
  }, [user, i18n.language, user?.sessionID, mode]);

  return (
    <LeadContext.Provider
      value={{
        leadInfo,
        setLeadInfo,
        revenueCommission,
        setRevenueCommission,
        leadContext,
        setLeadContext,
        leadType,
        setLeadType,
        leadDetailsDialog,
        setLeadDetailsDialog,
        trackLeadActivity,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};
