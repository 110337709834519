import i18next from 'i18next';
import * as Yup from 'yup';

const CreateNewBankAccountValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    country: Yup.object({
      label: Yup.string().required(t('country.required')),
      dialCode: Yup.number(t('country.dial_code.invalid'))
        .required(t('country.dial_code.required'))
        .typeError(t('country.dial_code.error')),
      code: Yup.string()
        .required(t('country.code.required'))
        .min(2, t('country.code.min')),
    }),
    account_type: Yup.string().required(t('account_type.required')),
    account_number: Yup.string()
      .min(8, t('account_number.short'))
      .max(34, t('account_number.long'))
      .required(t('account_number.required')),
    account_holder_name: Yup.string()
      .min(2, t('account_name.short'))
      .max(50, t('account_name.long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('account_name.invalid'))
      .required(t('account_name.required')),
    routing_number: Yup.string().when('isIBAN', {
      is: false,
      then: () => Yup.string().required(t('routing_number.required')),
    }),
    currency: Yup.string().required(t('currency.required')),
  });
};

const CreateEditBankAccountValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    account_holder_type: Yup.string().required(t('account_type.required')),
    account_holder_name: Yup.string()
      .min(2, t('account_name.short'))
      .max(50, t('account_name.long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('account_name.invalid'))
      .required(t('account_name.required')),
  });
};

export {
  CreateNewBankAccountValidationSchema,
  CreateEditBankAccountValidationSchema,
};
