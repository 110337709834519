import i18next from 'i18next';
import * as Yup from 'yup';
import blockedDomainList from '../../../../data/BlockedDomains.json';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createBusinessDetailsFormValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    account_type: Yup.string().required(t('account_type_required')),
    business_type: Yup.string().required(t('business_type_required')),
    company: Yup.object().when('business_type', {
      is: 'company',
      then: () =>
        Yup.object({
          name: Yup.string().required(t('company.name_required')),
          brands: Yup.array().when('account_type', {
            is: 'manufacturer',
            then: () => {
              Yup.array().min(1, t('brand_min'));
            },
          }),
          address: Yup.object({
            line1: Yup.string()
              .min(5, t('short'))
              .required(t('address.line1_required')),
            city: Yup.string()
              .min(3, t('short'))
              .required(t('address.city_required')),
            state: Yup.string()
              .min(2, t('short'))
              .required(t('address.state_required')),
            postcode: Yup.string()
              .min(3, t('short'))
              .required(t('address.postcode_required')),
            country: Yup.object({
              label: Yup.string().required(t('country.required')),
              dialCode: Yup.number(t('country.dial_code.invalid'))
                .required(t('country.dial_code.required'))
                .typeError(t('country.dial_code.error')),
              code: Yup.string()
                .required(t('country.code.required'))
                .min(2, t('country.code.min')),
            }),
          }),
          phone: Yup.object({
            value: Yup.string().required(t('company.phone_required')),
            info: Yup.object({
              countryCallingCode: Yup.number(t('phone.country_code.invalid'))
                .required(t('phone.country_code.required'))
                .typeError(t('phone.country_code.error'))
                .test({
                  message: t('country.code.no_match'),
                  test: (value, context) => {
                    return (
                      value ===
                      Number(
                        context.options.context.company.address.country
                          .dialCode,
                      )
                    );
                  },
                }),
              nationalNumber: Yup.string()
                .required(t('phone.required'))
                .min(8, t('phone.min'))
                .max(15, t('phone.max')),
            }),
          }),
          website: Yup.string()
            .matches(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              t('website_invalid'),
            )
            .url(t('website_invalid'))
            .required(t('website_required')),
          email: Yup.string()
            .required(t('email.user_required'))
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t('email.invalid'),
            )
            .test(
              'is-not-burner-email',
              t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
              (value) => {
                if (!value) return true; // Skip validation for empty values
                const domain = value.split('@')[1]?.toLowerCase();
                return !blockedDomains.includes(domain);
              },
            ),
        }),
    }),
  });
};

export const createApplicationsProductsValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    applications: Yup.array().min(1, t('application_min')),
    product_types: Yup.array().min(1, t('product_min')),
  });
};

export const createPersonalDetailsValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    individual: Yup.object({
      address: Yup.object({
        line1: Yup.string()
          .min(5, t('short'))
          .required(t('address.line1_required')),
        city: Yup.string()
          .min(3, t('short'))
          .required(t('address.city_required')),
        state: Yup.string()
          .min(2, t('short'))
          .required(t('address.state_required')),
        postcode: Yup.string()
          .min(3, t('short'))
          .required(t('address.postcode_required')),
        country: Yup.object({
          label: Yup.string().required(t('country.required')),
          dialCode: Yup.number(t('country.dial_code.invalid'))
            .required(t('country.dial_code.required'))
            .typeError(t('country.dial_code.error')),
          code: Yup.string()
            .required(t('country.code.required'))
            .min(2, t('country.code.min')),
        }),
      }),
      phone: Yup.object({
        value: Yup.string().required(t('phone.required')),
        info: Yup.object({
          countryCallingCode: Yup.number(t('phone.country_code.invalid'))
            .required(t('phone.country_code.required'))
            .typeError(t('phone.country_code.error')),
          nationalNumber: Yup.string()
            .required(t('phone.required'))
            .min(8, t('phone.min'))
            .max(15, t('phone.max')),
        }),
      }),
      profile_link: Yup.string()
        .required(1, t('profile_required'))
        .url(t('website_invalid'))
        .required(t('website_required')),
      email: Yup.string()
        .required(t('email.user_required'))
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t('email.invalid'),
        )
        .test(
          'is-not-burner-email',
          t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
          (value) => {
            if (!value) return true; // Skip validation for empty values
            const domain = value.split('@')[1]?.toLowerCase();
            return !blockedDomains.includes(domain);
          },
        ),
    }),
  });
};

export const createTermsOfServiceValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    tos_accepted: Yup.object({
      accepted: Yup.boolean().oneOf([true], t('tos.accepted')),
      ip: Yup.string().required(t('tos.ip')),
      date: Yup.string().required(t('tos.date')),
      unixTimeStamp: Yup.string().required(t('tos.time_stamp')),
      agent: Yup.string().required(t('agent_required')),
    }),
    ppa_accepted: Yup.object({
      accepted: Yup.boolean().oneOf([true], t('tos.accepted')),
      ip: Yup.string().required(t('tos.ip')),
      date: Yup.string().required(t('tos.date')),
      unixTimeStamp: Yup.string().required(t('tos.time_stamp')),
      agent: Yup.string().required(t('agent_required')),
    }),
    ssa_accepted: Yup.object({
      accepted: Yup.boolean().oneOf([true], t('ssa.accepted')),
      ip: Yup.string().required(t('ssa.ip')),
      date: Yup.string().required(t('ssa.date')),
      unixTimeStamp: Yup.string().required(t('ssa.time_stamp')),
      agent: Yup.string().required(t('agent_required')),
    }),
    otp: Yup.string().when('otpSent', {
      is: true,
      then: () =>
        Yup.string()
          .required(t('otp.required'))
          .min(4, t('otp.short'))
          .max(4, t('otp.long')),
    }),
    mobile_verified: Yup.object({
      accepted: Yup.boolean().oneOf([true], t('mobile.accepted')),
      ip: Yup.string().required(t('mobile.ip')),
      date: Yup.string().required(t('mobile.date')),
      unixTimeStamp: Yup.string().required(t('mobile.time_stamp')),
      agent: Yup.string().required(t('agent_required')),
    }),
    mobile: Yup.object({
      value: Yup.string().required(t('mobile.required')),
      info: Yup.object({
        countryCallingCode: Yup.number(t('mobile.country_code.invalid'))
          .required(t('mobile.country_code.required'))
          .typeError(t('mobile.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('mobile.required'))
          .min(8, t('mobile.min'))
          .max(15, t('mobile.max')),
      }),
    }),
  });
};
