import React, { useContext } from 'react';

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Skeleton,
  TextField,
  useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

const LeadTypeTypeField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'lead_type', // ID of the Field
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  variant = 'outlined', // Field Variant
}) => {
  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'LeadTypeField',
  });

  const theme = useTheme();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl disabled={disabled}>
        <TextField
          select
          required={required}
          disabled={disabled}
          id={fieldID}
          name={fieldID}
          label={t('label')}
          placeholder={t('placeholder')}
          className="form-select-field"
          aria-invalid={errors?.fieldID ? 'true' : 'false'}
          aria-describedby="uidnote"
          variant={variant}
          type="text"
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          onBlur={handleBlur}
          inputProps={{
            IconComponent: disabled ? () => null : ArrowDropDownIcon,
          }}
          value={fieldValue}
          error={fieldError && fieldTouched ? true : false}
        >
          <MenuItem key={0} value="">
            {t('options.select')}
          </MenuItem>
          <MenuItem key={1} value="manufacturer">
            {t('options.manufacturer')}
          </MenuItem>
          <MenuItem key={2} value="salesrep">
            {t('options.salesrep')}
          </MenuItem>
          <MenuItem key={3} value="affiliate">
            {t('options.affiliate')}
          </MenuItem>
        </TextField>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors[fieldID] && touched[fieldID] && errors[fieldID]}
        </FormHelperText>
      </FormControl>
    </FormikFieldWrapper>
  );
};

export default LeadTypeTypeField;
