import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Autocomplete, Skeleton, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import UserContext from '../../context/users/UserInfoProvider';
import { toTitleCase } from '../../helpers/toTitleCase';

const SingleLanguageSelectionfield = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'language', // Define the Fields Name and ID
  autocompleteID = 'language', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'SingleLanguageSelectionfield', // KeyPrefix which holds label and placeholder
  transTypeID = 'user', // ID of the field type e.g. lead, user
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined',
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transTypes } = useTranslation('types');

  // Languages Context
  const { languages } = useContext(UserContext);

  // Ref
  const { languageFieldRef } = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur, handleChange } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          required={required}
          disabled={disabled}
          disableClearable
          value={getNestedValue(values, autocompleteID)}
          id={fieldID}
          name={fieldID}
          options={languages?.sort(
            (a, b) => -b.nativeName.localeCompare(a.nativeName),
          )}
          getOptionLabel={(option) =>
            option?.nativeName ? toTitleCase(option.nativeName) : ''
          }
          isOptionEqualToValue={(option, value) => {
            // console.log(option, value); // Debugging
            return option?.code === value.code && option?.name === value.name;
          }}
          className="form-select-field"
          ref={languageFieldRef}
          onChange={(e, options, reason, element) => {
            if (reason === 'selectOption') {
              setFieldValue(fieldID, element.option);
            } else if (reason === 'clear') {
              setFieldValue(fieldID, '');
            }
          }}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant}
              label={!disabled ? t('label') : null}
              placeholder={
                !disabled
                  ? t('placeholder', { type: transTypes(transTypeID) })
                  : null
              }
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
        />
      </FormikFieldWrapper>
    </>
  );
};

export default SingleLanguageSelectionfield;
