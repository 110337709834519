import { axiosPrivate } from '../../hooks/axios/axios';
import i18n from '../../i18n.js';

async function createStripeConnectAccount(createParam) {
  // Update Stripe Account
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/create';

  const req_fields = [];

  if (!createParam) {
    req_fields.push(
      i18n.t('validations:pram', {
        dataType: i18n.t('types:stripe_account'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const createConnectAccount = await axiosPrivate.put(
      url,
      JSON.stringify(createParam),
      {
        signal,
      },
    );
    console.log('Result from Creating Accout ->', createParam);
    return createConnectAccount;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

async function updateStripeConnectAccount(updateParam) {
  // Update Stripe Account
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/connect/update';

  const req_fields = [];

  if (!updateParam) {
    req_fields.push(
      i18n.t('validations:pram', {
        dataType: i18n.t('types:stripe_account'),
      }),
    );
  }

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const updateConnectAccount = await axiosPrivate.put(
      url,
      JSON.stringify(updateParam),
      {
        signal,
      },
    );

    return updateConnectAccount;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

export { createStripeConnectAccount, updateStripeConnectAccount };
