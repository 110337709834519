import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { MenuItem, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';

const VerificationFileTypeField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'verification_document.type', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'individual.verification.file', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Manually control the Loading State
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Field Ref
  const businessTypeRef = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        select
        disabled={disabled}
        required={required}
        type="text"
        name={fieldID}
        id={fieldID}
        variant="outlined"
        label={t('label')}
        className="form-select-field"
        placeholder={t('placeholder')}
        labelid="account-type-label"
        inputRef={businessTypeRef}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        sx={{ width: '100%' }}
        value={fieldValue}
        error={
          showInitialErrors
            ? fieldError
              ? true
              : false
            : fieldError && fieldTouched
              ? true
              : false
        }
        helperText={fieldError && fieldTouched ? fieldError : null}
      >
        <MenuItem key="company_authorization" value="company_authorization">
          {t('options.company_authorization')}
        </MenuItem>

        <MenuItem key="passport" value="passport">
          {t('options.passport')}
        </MenuItem>

        <MenuItem key="visa" value="visa">
          {t('options.visa')}
        </MenuItem>
      </TextField>
    </FormikFieldWrapper>
  );
};

export default VerificationFileTypeField;
