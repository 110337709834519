import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import { classes } from '../../../settings/theme.js';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import { useTheme } from '@mui/material/styles';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';

const IndividualSSNField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  type = 'Enter', // Define the Password Type Enter, Define, New etc....
  fieldID = 'ssn_last_4', // ID of the Field
  transNS = 'fields', // Translations Namespace,
  transPrefix = 'individual.verification.ssn_last_4', // Trnslatio prefix
  isLoadingManual = false, // Set Loading State Manually,
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  // Translations
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const idRef = useRef();

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return (
    <>
      {isLoading.status || isLoadingManual ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <TextField
            required={required}
            disabled={disabled}
            ref={idRef}
            style={classes.root}
            id={fieldID}
            name={fieldID}
            className="form-select-field"
            aria-invalid={errors[fieldID] ? 'true' : 'false'}
            aria-describedby="uidnote"
            variant="outlined"
            label={transFields('label', { type: transTypes(type) })}
            type="number"
            placeholder={transFields('placeholder', {
              type: transTypes(type),
            })}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            inputProps={{
              autoComplete: 'off',
            }}
            onBlur={handleBlur}
            value={fieldValue}
            error={
              showInitialErrors
                ? fieldError
                  ? true
                  : false
                : fieldTouched && fieldError
                  ? true
                  : false
            }
            helperText={fieldError ? fieldError : null}
          />
        </FormikFieldWrapper>
      )}
    </>
  );
};

export default IndividualSSNField;
