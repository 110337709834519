import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import DropzoneContainer from '../../../dropzoneContainer/DropzoneContainer';
import { PersonDetailsContext } from '../person/PersonsList';
import { updateAccountPerson } from '../../../../api/stripe/persons';
import { createStripeFile } from '../../../../api/stripe/files';
import useAuth from '../../../../hooks/useAuth';
import VerificationFileTypeField from './VerificationFilePurposeField';
import { CreateAddPersonVerificationDocumentValidationSchema } from './validationSchema';
import IndividualIDField from '../../individual/IndividualIDField';

const VerifyStripePerson = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation',
  transPrefix = 'components.stripe.ManagePerson',
  getPersonsList = () => {},
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transFields } = useTranslation('fields');
  const theme = useTheme();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const [image, setImage] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);
  const { auth } = useAuth();
  const { existingPersonData } = useContext(PersonDetailsContext);

  // Seperate Relationship, Owbership & Title
  const { percent_ownership, title, ...filteredRelationship } =
    existingPersonData?.relationship;

  // Remove unsupported upate arameter
  delete filteredRelationship.authorizer;
  delete filteredRelationship.legal_guardian;

  const initialValues = {
    id: existingPersonData?.id,
    id_number: '',
    verification_document: {
      file: '',
      type: '',
    },
  };

  const validationSchema =
    CreateAddPersonVerificationDocumentValidationSchema();

  const stripe = useStripe();

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    const personID = values?.id;
    if (!personID || !image) {
      return;
    }

    try {
      // Upload Verification Document

      const fileUploadRequest = await createStripeFile({
        file: image,
        fileName: image.name,
        filePurpose: 'account_requirement',
        stripeAccount: stripeUserData?.stripe_connect_data?.id,
        authInfo: auth.auth_info,
      });

      console.log('Upload Verification Document Result -> ', fileUploadRequest);

      if (fileUploadRequest.statusCode === 200) {
        const { data } = fileUploadRequest;
        const verificationDocumentType = values.verification_document.type;
        const verificationDocument = data.id;

        // add File to Payload
        const accountDetails = {
          id_number: values?.id_number,
          documents: {
            [verificationDocumentType]: {
              files: verificationDocument,
            },
          },
        };

        console.log('Account Details Payload ->', accountDetails);

        const requestToken = await stripe.createToken('person', accountDetails);

        if (requestToken?.token) {
          const token = requestToken.token;
          // Create Stripe Account

          const addPerson = await updateAccountPerson(token.id, personID);

          console.log('Result Updating Person ->', addPerson);

          if (addPerson?.data.statusCode === 201) {
            const response = addPerson.data;
            handleRegularResponse({
              open: true,
              status: response.status,
              statusCode: response.statusCode,
              message: response.message,
            });
            getStripeAccountDetails();
            setImage(null);
            getPersonsList();
            formik.resetForm();
            onClose();
          } else {
            const response = addPerson.data;
            handleRegularResponse({
              open: true,
              status: response.status,
              statusCode: response.statusCode,
              message: response.message,
            });
          }
        } else {
          // Error Requesting Token
          const response = requestToken?.error;
          handleRegularResponse({
            open: true,
            status: 'error',
            statusCode: 400,
            message: response.message,
          });
        }
      } else {
        // Error Uploading File
        const response = fileUploadRequest?.error;
        handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: 400,
          message: response.message,
        });
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialTouched={initialValues}
    >
      {(formik) => {
        const { values, isSubmitting, isValid, resetForm } = formik;

        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle>
              {' '}
              {t('title', { name: `${values.first_name} ${values.last_name}` })}
            </DialogTitle>

            <DialogContent>
              <DialogContentText sx={{ marginBottom: '2rem' }}>
                {t('description')}
              </DialogContentText>

              {/* Document Type */}
              <Stack spacing={2} sx={{ marginBottom: '1rem' }}>
                <VerificationFileTypeField
                  required={image}
                  isLoadingManual={isSubmitting}
                />

                <IndividualIDField
                  required
                  isLoadingManual={isSubmitting}
                  transPrefix="individual.verification.id_number"
                  showInitialErrors={true}
                />
              </Stack>

              {/* Preview */}
              {image ? (
                <Grid item sx={{ margin: 0, padding: 0 }}>
                  {isSubmitting ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        display: 'block',
                        margin: 0, // Removed auto
                        width: '100%',
                        height: '200px',
                        maxWidth: '500px',
                        maxHeight: '500px',
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        margin: 0, // Removed auto
                        width: '100%',
                        maxWidth: '500px',
                        height: 'auto',
                      }}
                    >
                      <Box
                        margin="0 auto"
                        width="100%"
                        maxWidth="500px"
                        height="auto"
                      >
                        {image.type === 'application/pdf' ? (
                          <embed
                            src={`${previewURL}#page=1`}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                          />
                        ) : (
                          <Box
                            component="img"
                            src={previewURL}
                            alt="verification document preview"
                            sx={{
                              display: 'block',
                              margin: '0 auto',
                              width: '100%',
                              height: 'auto',
                              objectFit: 'contain',
                              maxWidth: '500px',
                              maxHeight: '500px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              ) : (
                <Grid item sx={{ margin: 0, padding: 0 }}>
                  <DropzoneContainer
                    onDrop={(file, fileType) => {
                      const supportedImageTypes = ['jpg', 'jpeg', 'png'];
                      if (
                        supportedImageTypes.includes(fileType) ||
                        fileType === 'pdf'
                      ) {
                        setPreviewURL(URL.createObjectURL(file)); // Update preview
                        formik.setFieldValue(
                          'verification_document.file',
                          file,
                        ); // Update Formik
                        setImage(file); // Update local state if needed
                      } else {
                        console.error('Unsupported file type');
                      }
                    }}
                    maxFiles={1}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: '1rem', marginBottom: 0 }} // Adjust margin
                  >
                    {transFields('upload_file.description')}
                  </Typography>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <IntuIconButton
                      type="cancel"
                      onClick={() => {
                        setImage(null);
                        resetForm();
                        onClose();
                      }}
                      tooltipTitle="cancel"
                    />

                    {image && (
                      <IntuIconButton
                        type="reset"
                        variant="outlined"
                        onClick={() => {
                          setImage(null);
                          setPreviewURL(null);
                        }}
                        sx={{ color: theme.palette.connect.main }}
                        tooltipTitle="reset"
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(values, formik);
                    }}
                    tooltipTitle="Add"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { VerifyStripePerson };
