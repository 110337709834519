import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { UserProfileForm } from '../../components/users/UserProfileForm';
import IntuPage from '../../components/layout/IntuPage';

function UserProfile() {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.users.UserProfilePage.title')}
      pageDescription={t('pages.users.UserProfilePage.description')}
      hCentered
      lessMT
    >
      <IntuForm>
        <UserProfileForm />
      </IntuForm>
    </IntuPage>
  );
}

export default UserProfile;
