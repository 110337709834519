import { CheckCircle, Error, Warning } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const SUCCESS_STATUSES = new Set(['validated', 'verified', 'succeeded']);

const ListStatusIcons = (status, theme) => {
  if (SUCCESS_STATUSES.has(status)) {
    return (
      <IconButton style={{ color: theme.palette.success.main }}>
        <CheckCircle sx={{ color: theme.palette.success.main }} />
      </IconButton>
    );
  } else if (status === 'new') {
    return (
      <IconButton style={{ color: theme.palette.warning.main }}>
        <Warning style={{ color: theme.palette.warning.main }} />
      </IconButton>
    );
  } else {
    return (
      <IconButton style={{ color: theme.palette.error.main }}>
        <Error sx={{ color: theme.palette.error.main }} />
      </IconButton>
    );
  }
};

export { ListStatusIcons };
