import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AddCommissionDialog from './AddCommissionDialog';
import EditCommissionDialog from './EditCommissionDialog';
import IntuIconButton from '../buttons/IntuIconButton';

const isDigit = (value) => {
  const charCode = String(value).charCodeAt(0);
  // Check if the character code is between 48 (code for '0') and 57 (code for '9')
  return (charCode >= 48 && charCode <= 57) || charCode === 117;
};

// const commission_schedule = {
//   commission: [
//     { id: 0, max_amount: 1000, commission: 0.45, cost: null },
//     { id: 1, max_amount: 10000, commission: 0.4, cost: null },
//     { id: 2, max_amount: 50000, commission: 0.35, cost: null },
//     { id: 3, max_amount: 100000, commission: 0.3, cost: null },
//     { id: 4, max_amount: 250000, commission: 0.27, cost: null },
//     { id: 5, max_amount: 500000, commission: 0.25, cost: null },
//     { id: 6, max_amount: 1000000, commission: 0.2, cost: null },
//   ],
//   type: 'tiered',
// };

// const handleCommissionTypeChange = (newType) => {
//   setCommissionSchedule({
//     ...commission,
//     type: newType,
//   });
// };

// const handleAddCommission = (commission) => {
//   const newCommission = {
//     ...commission,
//     id: commission_schedule.commission.length,
//   };
//   setCommissionSchedule({
//     commissions: [...product.commission_schedule.commission, newCommission],
//   });
// };

// const handleEditCommission = (commission) => {
//   const newCommissions = product.commission_schedule.commission.map((c) =>
//     c.id === commission.id ? commission : c,
//   );
//   setCommissionSchedule({
//     commissions: newCommissions,
//   });
// };

// const handleDeleteCommission = (commission) => {
//   const newCommissions = product.commission_schedule.commission.filter(
//     (c) => c.id !== commission.id,
//   );
//   setCommissionSchedule({
//     commission_schedule: {
//       ...commission_schedule.type,
//       commission: newCommissions,
//     },
//   });
// };

const PercentageInput = () => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (
      newValue === '' ||
      (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100)
    ) {
      setValue(newValue);
    }
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      inputProps={{
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
      }}
      sx={{ minWidth: 120 }}
    />
  );
};

const CommissionBox = ({
  commissions, // Array of commission objects with id, max_amount, commission, and cost commissions === commission_schedule.commission
  commissionType, // String of commissionType, 'flat' | 'tierd', commissionType === commission_schedule.type
  handleCommissionTypeChange = (t) => {}, // Callback to handle changing of commission type
  handleAddCommission = (c) => {}, // Callback to handle adding a new commission
  handleEditCommission = (c) => {}, // Callback to handle editing an existing commission
  handleDeleteCommission = (c) => {}, // Callback to handle deleting an existing commission
  viewMode = 'edit', // The view mode, 'view' or 'edit'
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const [isAddCommissionDialogOpen, setIsAddCommissionDialogOpen] =
    useState(false);
  const [isEditCommissionDialogOpen, setIsEditCommissionDialogOpen] =
    useState(false);
  const [selectedCommission, setSelectedCommission] = useState({
    max_amount: null,
    commission: null,
  });
  const [exampleRevenue, setExampleRevenue] = useState(25000);

  const handleAddCommissionConfirm = (commission) => {
    setIsAddCommissionDialogOpen(false);
    handleAddCommission(commission);
  };

  const handleEditCommissionClick = (commission) => {
    setSelectedCommission(commission);
    setIsEditCommissionDialogOpen(true);
  };

  const handleEditCommissionConfirm = (commission) => {
    setIsEditCommissionDialogOpen(false);
    handleEditCommission(commission);
    setSelectedCommission({
      max_amount: null,
      commission: null,
    });
  };

  const handleDeleteCommissionClick = (commission) => {
    handleDeleteCommission(commission);
  };

  const columns = [
    {
      field: 'max_amount',
      headerName: t('components.commission.table.max_amount'),
      type: 'currency',
      flex: 1,
      minWidth: 150,
      editable: true,
      currencyModel: 'USD',
      valueFormatter: (params) =>
        t('components.commission.table.max_amount_value', {
          amount: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(params.value),
        }),
    },
    {
      field: 'commission',
      headerName: t('components.commission.table.rate'),
      type: 'number',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => `${params.value * 100}%`,
    },
    {
      field: 'cost',
      headerName: t('components.commission.table.cost'),
      type: 'currency',
      flex: 1,
      minWidth: 110,
      currencyModel: 'USD',
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value),
    },
    {
      field: 'action',
      headerName: t('components.commission.table.action'),
      sortable: false,
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',

      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="space-evenly" flex={1}>
            <IntuIconButton
              size="small"
              type="edit"
              variant="outlined"
              tooltipTitle={transButtons('edit')}
              onClick={() => handleEditCommissionClick(params.row)}
            />
            <IntuIconButton
              size="small"
              type="error"
              variant="outlined"
              tooltipTitle={transButtons('delete')}
              onClick={() => handleDeleteCommissionClick(params.row)}
            />
          </Box>
        );
      },
    },
  ];

  const calculatedCommissions = useMemo(() => {
    if (!commissions) return null;
    return commissions.map((commission, index, array) => {
      if (!exampleRevenue)
        return {
          ...commission,
          cost: 0,
        };
      if (index === 0 && exampleRevenue > array[0].max_amount) {
        return {
          ...commission,
          cost: commission.max_amount * commission.commission,
        };
      }

      if (index === 0 && exampleRevenue) {
        return {
          ...commission,
          cost: exampleRevenue * commission.commission,
        };
      }

      // Check if cost is in commission range
      if (
        exampleRevenue <= commission.max_amount &&
        exampleRevenue > array[index - 1].max_amount
      ) {
        return {
          ...commission,
          cost:
            (exampleRevenue - array[index - 1].max_amount) *
            commission.commission,
        };
      }

      if (exampleRevenue > commission.max_amount) {
        return {
          ...commission,
          cost:
            (commission.max_amount - array[index - 1].max_amount) *
            commission.commission,
        };
      }

      return {
        ...commission,
        cost: null,
      };
    });
  }, [commissions, exampleRevenue]);

  const cost = useMemo(() => {
    if (!calculatedCommissions) return 0;
    return calculatedCommissions.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cost || 0,
      0,
    );
  }, [calculatedCommissions]);

  const percentCommission = useMemo(() => {
    if (!exampleRevenue) return 0;
    return (cost / exampleRevenue).toFixed(2) * 100;
  }, [exampleRevenue, cost]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="h4" textTransform="none" mb={0}>
          {t('components.commission.title')}
        </Typography>
        <Typography variant="body1" textTransform="none">
          Define your company commission model and commission rates. When
          selecting tiered, (which we recommend), you have to set the commission
          per bracket
        </Typography>
      </Box>
      <Box width="50%" my={2} display="flex" gap={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Select
            labelId="commission-select"
            id="select"
            value={commissionType}
            onChange={(e) => handleCommissionTypeChange(e.target.value)}
            name="type"
            label="Type"
            defaultValue="tiered"
            disabled={viewMode === 'view'}
            IconComponent={viewMode === 'view' ? () => null : undefined}
            sx={{ minWidth: 100 }}
          >
            <MenuItem value="tiered">
              {t('components.commission.tiered')}
            </MenuItem>
            <MenuItem value="flat">{t('components.commission.flat')}</MenuItem>
          </Select>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {commissionType === 'flat' ? <PercentageInput /> : null}
        </Box>
        {viewMode === 'edit' && commissionType === 'tiered' ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              onClick={() => setIsAddCommissionDialogOpen(true)}
              sx={{
                textTransform: 'none',
                borderRadius: '24px',
                '& .MuiSvgIcon-root': {
                  color: (theme) => theme.palette.primary.main,
                },
                width: 180,
                height: 40,
              }}
            >
              {t('components.commission.add')}
            </Button>
          </Box>
        ) : null}
      </Box>
      {commissionType === 'tiered' ? (
        <>
          <Typography mb={2}>
            Test your Commission structure by playing around with example
            revenues. Tip: The higher the revenue the less the effective
            commission
          </Typography>
          <TextField
            value={exampleRevenue.toLocaleString()}
            onChange={(e) => {
              if (!isDigit(e.target.value.split('').at(-1))) return;
              setExampleRevenue(Number(e.target.value.replace(/,/g, '')));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            sx={{ width: 120 }}
          />
          <Box height={400} width="100%">
            <DataGrid
              rows={calculatedCommissions}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              autoWidth
              disableClickEventBubbling
              disableColumnMenu
              getRowId={(row) => row.commission}
              columnVisibilityModel={{
                action: viewMode === 'edit' ? true : false,
              }}
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
          </Box>
          <Typography variant="p" color="primary">
            {t('components.commission.description', {
              cost: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cost),
              percentCommission,
            })}
          </Typography>
          <AddCommissionDialog
            commissions={commissions}
            isOpen={isAddCommissionDialogOpen}
            handleClose={() => setIsAddCommissionDialogOpen(false)}
            handleConfirm={handleAddCommissionConfirm}
          />
          <EditCommissionDialog
            commissions={commissions}
            isOpen={isEditCommissionDialogOpen}
            handleClose={() => setIsEditCommissionDialogOpen(false)}
            handleConfirm={handleEditCommissionConfirm}
            selectedCommission={selectedCommission}
          />
        </>
      ) : null}
    </Box>
  );
};

export default CommissionBox;
