// Provides Post Context

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAnalyticsPageViewTracker } from '../../hooks/useAnalyticsTracker';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthContext from '../auth/AuthProvider';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import { convertFileToBase64 } from '../../helpers/convertFileToBase64';
import ResponseContext from './ResponseProvider';

export const PostContext = createContext({});

export const PostProvider = ({ children }) => {
  const { t } = useTranslation();

  // Get Context
  const { auth, isAdmin } = useContext(AuthContext);
  const { handleRegularResponse } = useContext(ResponseContext);

  // Set States
  const [postInfo, setPostInfo] = useState({
    _id: '',
    link: '',
    description: '',
    tags: [],
    activity: [],
    clicked: null,
    content: {},
    title: '',
    created: '',
    modified: '',
    image_url: '',
    full_url: '',
    author: {
      _id: '',
      email: '',
      name: '',
    },
    status: 'draft',
    inEditMode: false,
  });

  const [postImage, setPostImage] = useState({
    image: '',
    imageBlob: '',
  });

  // Error Dialog States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMsg, setDialogMsg] = useState('');

  // Viewport
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  // Edit Post Handler
  const handleEditPost = (edit, values) => {
    if (edit === false) {
      // setIsEditMode(false)
      setPostInfo({
        ...values,
        inEditMode: false,
      });
    } else if (edit === true) {
      if (auth?.user_info?.full_name === postInfo?.author?.name || isAdmin) {
        // setIsEditMode(true)
        setPostInfo({
          ...postInfo,
          inEditMode: true,
        });
      }
    }
  };

  //handle Post Image Upload
  async function handlePostImageChange(e, values) {
    // get selected file from input
    const file = e.target.files[0];

    if (file) {
      //check if filesize is less than 1mb
      if (file.size > 5120000) {
        let fileSize = Math.round(file.size / 1000);
        setDialogTitle(t('validation.file.file_size_title'));
        setDialogMsg(
          t('validation.file.file_size_msg', {
            fileSize: { fileSize },
            maxFileSize: '5MB',
          }),
        );
        setDialogOpen(true);

        return;
      } else {
        const reader = new FileReader();
        reader.onloadend = function () {
          // console.log('RESULT', reader.result)
          setPostImage({
            imageBlob: reader.result,
            image: file,
          });
          return reader.result;
        };
        const image = reader.readAsDataURL(file);

        const base64File = await convertFileToBase64(file);

        // Upload Post Image to aws bucket to make it public
        let fileUrl = '/api/aws/content/add';
        const requestData = {
          file: base64File,
          file_type: file.type,
          file_name: file.name,
          folder: `posts/${values.link}/images`,
        };

        const awsRequest = await axiosData.post(
          fileUrl,
          JSON.stringify(requestData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const awsResponse = awsRequest?.data;

        if (awsRequest.status === 200) {
          values.image_url = awsResponse.data;
          values.image = file;
        } else {
          return handleRegularResponse({
            open: true,
            status: awsResponse.status,
            message: awsResponse.message,
          });
        }
      }
    }
  }

  // handle Error Dialog
  function handleConfirmError() {
    setDialogTitle('');
    setDialogMsg('');
    setDialogOpen(false);
  }

  // SetUp Axios
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  // Location hook
  const location = useLocation();

  // Navigation
  const host_url = window.location.host;

  // google page view tracking tracking
  let { gaPageViewTracker } = useAnalyticsPageViewTracker();

  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'How it works Page (Sutomized)',
  });

  return (
    <PostContext.Provider
      value={{
        postInfo,
        setPostInfo,
        postImage,
        setPostImage,
        mobileView,
        setMobileView,
        handleEditPost,
        dialogMsg,
        dialogTitle,
        dialogOpen,
        setDialogMsg,
        setDialogOpen,
        setDialogTitle,
        setPostInfo,
        handlePostImageChange,
        handleConfirmError,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
