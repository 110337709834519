import { useRef, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Stack,
  FormControl,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { classes } from '../../settings/theme.js';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../context/company/CompanyProfileProvider.jsx';
import ApplicationsField from '../../components/applications/ApplicationsField.jsx';
import ProductTypeField from '../../components/product-type/ProductTypeField.jsx';
import { createCompanyProfileValidationSchema } from './ValidationSchema.js';
import CompanyProfilePicture from '../../components/account/company/CompanyProfilePicture.jsx';
import CompanyBrandsField from '../../components/account/company/CompanyBrandsField.jsx';
import CompanyIntroField from '../../components/account/company/CompanyIntroField.jsx';
import CompanyNameField from '../../components/account/company/CompanyNameField.jsx';
import CompanyEmployeesList from '../../components/account/company/employees/EmployeesList.jsx';
import CompanyTowField from '../../components/account/company/CompanyTowField.jsx';
import CompanyTosField from '../../components/account/company/CompanyTosField.jsx';
import CompanyThemeColors from '../../components/account/company/CompanyThemeColors.jsx';
import EmailField from '../../components/account/fields/email/EmailField.jsx';
import WebsiteField from '../../components/account/WebsiteField.jsx';
import PhoneField from '../../components/account/PhoneField .jsx';
import AddressField from '../../components/address/AddressField.jsx';
import CompanyPaymentMethodField from '../../components/account/company/payment/CompanyPaymentMethodField.jsx';
import CompanyPaymentTermsField from '../../components/account/company/payment/CompanyPaymentTerms.jsx';
import CompanyTowMonthField from '../../components/account/CompanyTowMonthField.jsx';

const CompanyProfileForm = () => {
  const { t } = useTranslation();
  const { t: transTypes } = useTranslation('types');
  const { t: transMessages } = useTranslation('messages');
  const { t: transButtons } = useTranslation('buttons');

  const { companyProfile, updateCompanyProfile } = useContext(
    CompanyProfileContext,
  );

  const validationSchema = createCompanyProfileValidationSchema();
  const formRef = useRef(null);

  const [isReinitialize, setIsReinitialize] = useState(true);

  return (
    <Grid className="content" sx={{ marginBottom: '8rem' }}>
      <Grid item className="title" xs={12}>
        <Box className="section__content_header green-text content-centered padding_after">
          <Typography variant="h6">
            {companyProfile.isAdmin === true
              ? t('form.company.auth_info')
              : t('form.company.non_auth_info')}
          </Typography>
        </Box>
      </Grid>
      <Grid item className="content" xs={12}>
        <Formik
          values={companyProfile}
          initialValues={companyProfile}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={isReinitialize}
          innerRef={formRef}
        >
          {(formik) => {
            const { values, touched, resetForm, isValid, setTouched, errors } =
              formik;
            return (
              <Form className="form-horizontal intu__form" role="form">
                <FormControl fullWidth>
                  <Stack
                    alignItems="stretch"
                    spacing={4}
                    style={classes.root}
                    sx={{ paddingBottom: '30px' }}
                  >
                    <Grid
                      container
                      justifyContent={{
                        sm: 'space-around',
                      }}
                      alignItems={{
                        xs: 'stretch',
                        md: 'center',
                      }}
                      direction={{
                        xs: 'column',
                        sm: 'row',
                        md: 'row',
                      }}
                    >
                      {/* compay Profile Picture */}
                      <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          marginBottom: { xs: '2rem', xl: 0 },
                        }}
                      >
                        <CompanyProfilePicture
                          disabled={!companyProfile.isAdmin}
                          setReinitialize={setIsReinitialize}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Stack
                          spacing={3}
                          direction={{ xs: 'column' }}
                          // style={classes.root}
                          alignItems="stretch"
                        >
                          <Stack
                            spacing={0}
                            alignItems="center"
                            direction={{ xs: 'column', lg: 'row' }}
                          >
                            <Grid item xs={12} sx={{ width: '100%' }}>
                              <CompanyNameField
                                disabled={true}
                                editable={true}
                                showNameChange={true}
                                fieldID="name"
                                autocompleteID="name"
                              />
                            </Grid>
                          </Stack>

                          {/* Email Webste */}
                          <Stack
                            spacing={{ xs: 3, sm: 3, md: 1 }}
                            direction={{
                              xs: 'column',
                              sm: 'column',
                              md: 'row',
                            }}
                            style={classes.root}
                            alignItems="stretch"
                          >
                            <EmailField
                              required={true}
                              disabled={!companyProfile.isAdmin}
                              fieldID="email"
                              transNS="fields"
                              transPrefix="account.company.EmailField"
                            />
                            <WebsiteField
                              required={true}
                              disabled={!companyProfile.isAdmin}
                              fieldID="website"
                              transNS="fields"
                              transPrefix="account.WebsiteField"
                              type={transTypes('company')}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>

                    <CompanyIntroField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.intro.title',
                      )}
                      fieldDescription={
                        companyProfile.initial_setup === false
                          ? transMessages('global.preparing')
                          : t(
                              'context.company.CompanyProfileForm.form.intro.description',
                            )
                      }
                      // required={true}
                      disabled={!companyProfile.isAdmin}
                      showIntroAssistant={true}
                      initialSetupComplete={companyProfile.initial_setup}
                    />
                    <AddressField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.address.title',
                      )}
                      fieldID="address"
                      autocompleteID="address"
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.address.description',
                      )}
                      required={true}
                      disabled={!companyProfile.isAdmin}
                    />
                    <PhoneField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.phone.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.phone.description',
                      )}
                      fieldID="phone"
                      disabled={!companyProfile.isAdmin}
                      transNS="fields"
                      transPrefix="account.company.PhoneField"
                    />
                    <ApplicationsField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.applications.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.applications.description',
                      )}
                      required={true}
                      disabled={!companyProfile.isAdmin}
                    />
                    <ProductTypeField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.products.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.products.description',
                      )}
                      required={true}
                      disabled={!companyProfile.isAdmin}
                    />
                    <CompanyBrandsField
                      fieldID="brands"
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.brands.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.brands.description',
                      )}
                      required={true}
                      disabled={
                        !companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      transition={
                        companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      allowSelectBrand={false}
                      allowOwnerRemoval={false}
                    />
                    <CompanyEmployeesList
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.employees.title',
                        { name: companyProfile.name },
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.employees.description',
                      )}
                      transition={companyProfile.isAdmin}
                    />
                    <CompanyTowMonthField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.tow.title',
                        { name: companyProfile.name },
                      )}
                    />
                    <CompanyTowField
                      fieldID="tow"
                      fieldDescription={
                        companyProfile.initial_setup === false
                          ? transMessages('global.preparing')
                          : t(
                              'context.company.CompanyProfileForm.form.tow.description',
                            )
                      }
                      transNS="fields"
                      transPrefix="account.company.CompanyTowField"
                      required={companyProfile.type === 'manufacturer'}
                      disabled={
                        !companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      transition={companyProfile.type === 'manufacturer'}
                      initialSetupComplete={companyProfile.initial_setup}
                    />
                    <CompanyTosField
                      fieldID="tos"
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.tos.title',
                        { name: companyProfile.name },
                      )}
                      fieldDescription={
                        companyProfile.initial_setup === false
                          ? transMessages('global.preparing')
                          : t(
                              'context.company.CompanyProfileForm.form.tos.description',
                            )
                      }
                      transNS="fields"
                      transPrefix="account.company.CompanyTosField"
                      required={companyProfile.type === 'manufacturer'}
                      disabled={
                        !companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      transition={companyProfile.type === 'manufacturer'}
                      initialSetupComplete={companyProfile.initial_setup}
                    />
                    <CompanyPaymentMethodField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.paymentMethods.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.paymentMethods.description',
                      )}
                      required={companyProfile.type === 'manufacturer'}
                      disabled={
                        !companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      transition={companyProfile.type === 'manufacturer'}
                    />
                    <CompanyPaymentTermsField
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.paymentTerms.title',
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.paymentTerms.description',
                      )}
                      required={companyProfile.type === 'manufacturer'}
                      disabled={
                        !companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                      transition={companyProfile.type === 'manufacturer'}
                    />
                    <CompanyThemeColors
                      fieldTitle={t(
                        'context.company.CompanyProfileForm.form.brandColors.title',
                        { name: companyProfile.name },
                      )}
                      fieldDescription={t(
                        'context.company.CompanyProfileForm.form.brandColors.description',
                      )}
                      required={companyProfile.type === 'manufacturer'}
                      disabled={!companyProfile.isAdmin}
                      transition={
                        companyProfile.isAdmin &&
                        companyProfile.type === 'manufacturer'
                      }
                    />
                  </Stack>
                  {companyProfile?.isAdmin ? (
                    <Grid container>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="warning"
                          disabled={touched.length === 0}
                          onClick={(e) => {
                            resetForm();
                          }}
                          sx={{ mr: 1 }}
                        >
                          {t('buttons.cancel_changes')}
                        </Button>
                      </Grid>
                      <Grid item sx={{ marginLeft: 'auto' }}>
                        <Button
                          variant="contained"
                          type="button"
                          // disabled={!isValid}
                          onClick={(e) => {
                            const update = updateCompanyProfile(values, formik);
                            if (update.status === 'error ') {
                              resetForm();
                            } else {
                              setTouched({}, false);
                            }
                          }}
                        >
                          {transButtons('update', {
                            type: transTypes('profile'),
                          })}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </FormControl>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export { CompanyProfileForm };
