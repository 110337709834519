// Brands Context Provider holds the authenticated users Companies Brand Information

import React, { createContext, useState } from 'react';
import { addBrands, searchBrands } from '../../api/brandRoutes';
import useResponseHandling from '../../hooks/useResponseHandler';

export const BrandsContext = createContext({});

export const BrandsProvider = ({ children }) => {
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const [brandOptions, setBrandOptions] = useState([]);
  const [newBrandsList, setNewBrandsList] = useState([]);
  const [brandClaimer, setBrandClaimer] = useState({
    brand: '',
    owner_name: '',
    owner_id: '',
    claimer: '',
  });

  const addNewBrandsToDatabase = async (companyInfo) => {
    if (newBrandsList.length === 0 || !companyInfo) {
      return;
    }

    try {
      const response = await addBrands(newBrandsList, companyInfo);

      if (response.statusCode === 200) {
        setNewBrandsList([]);
        return response.data;
      } else {
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: response.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const searchBrandsinDatabase = async (input) => {
    // Guard Clause
    if (!input || input.length <= 2) {
      return;
    }
    try {
      const payload = {
        key: 'brand',
        values: input,
      };
      const searchBrand = await searchBrands(payload);

      if (searchBrand.statusCode === 200) {
        const brands = searchBrand.data;
        setBrandOptions(brands);
      }

      return searchBrands;
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  return (
    <BrandsContext.Provider
      value={{
        brandOptions,
        setBrandOptions,
        newBrandsList,
        setNewBrandsList,
        brandClaimer,
        setBrandClaimer,
        addNewBrandsToDatabase,
        searchBrandsinDatabase,
      }}
    >
      {children}
    </BrandsContext.Provider>
  );
};
