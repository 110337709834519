import { useContext } from 'react';
import { Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ResponseContext from '../../context/providers/ResponseProvider';
import { useTranslation } from 'react-i18next';

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  minHeight: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const DropzoneContainer = ({
  onDrop, // on Drop Action
  maxFiles = 1, // Amount of Files
  transNS = 'translation',
  transPrefix = 'components.dropzoneContainer',
}) => {
  const { setStatusMsg } = useContext(ResponseContext);
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const onDropHandler = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileType = file.name.substring(
        file.name.lastIndexOf('.') + 1,
        file.name.length,
      );

      // Check if the file size exceeds 30 MB
      const maxSizeInBytes = 30 * 1024 * 1024; // 30 MB in bytes
      if (file.size > maxSizeInBytes) {
        setStatusMsg({
          open: true,
          status: 'error',
          message: t('messages.file_size'),
        });
        return;
      }

      onDrop(file, fileType);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropHandler,
    maxFiles,
  });

  return (
    <div {...getRootProps()} style={dropzoneStyles}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography>{t('title')}</Typography>
      ) : (
        <Typography>{t('instructions')}</Typography>
      )}
    </div>
  );
};

export default DropzoneContainer;
