import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker';
import { useTranslation } from 'react-i18next';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';

const footerStyles = {
  bottom: 'unset',
  position: 'relative',
  marginBottom: '60px',
};

const FooterComponent = () => {
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.footer',
  });
  const { footerConfig } = useNavigationFooter();

  return (
    <footer>
      <Grid
        container
        className="intu__footer dark-text"
        justifyContent="center"
        alignItems="flex-start"
        style={footerConfig.showFooter ? footerStyles : {}}
      >
        {/* FollowUs */}
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          alignItems="center"
          textAlign="center"
        >
          <Grid item>
            <Typography variant="h6">{t('follow_us')}</Typography>
          </Grid>

          <Grid item>
            <Stack spacing={0} justifyContent="center" direction="row">
              <IconButton
                aria-label="linkedin"
                target="_blank"
                className="social"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent', // Removes the background on hover
                  },
                }}
                href="https://www.linkedin.com/company/intutec/"
                onClick={() =>
                  gaEventTracker({
                    category: 'Social',
                    action: 'click',
                    label: 'LinkedIn',
                  })
                }
              >
                <LinkedInIcon
                  className="footer-link"
                  fontSize="large"
                  sx={{ color: 'grey.main' }}
                />
              </IconButton>
              <IconButton
                aria-label="youtube"
                target="_blank"
                className="social"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent', // Removes the background on hover
                  },
                }}
                href="https://www.youtube.com/@In2tecIO"
                onClick={() =>
                  gaEventTracker({
                    category: 'Social',
                    action: 'click',
                    label: 'YouTube',
                  })
                }
              >
                <YouTubeIcon
                  fontSize="large"
                  sx={{ color: 'grey.main' }}
                  className="footer-link"
                />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        {/* Contact Us */}
        <Grid
          container
          item
          xs={12}
          md={4}
          container
          direction="column"
          alignItems="center"
          textAlign="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">{t('contact_us')}</Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="body1" color="grey.main">
                <IconButton
                  aria-label="call"
                  href="tel:+1-888-471-3736"
                  className="social"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent', // Removes the background on hover
                    },
                  }}
                >
                  <CallIcon
                    sx={{ color: 'grey.main' }}
                    className="footer-link"
                  />
                </IconButton>
                +1 (888) 471 3736
              </Typography>

              <Typography variant="body1" color="grey.main">
                <IconButton
                  aria-label="email"
                  href="mailto:info@in2tec.io"
                  className="social"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent', // Removes the background on hover
                    },
                  }}
                >
                  <MailIcon
                    sx={{ color: 'grey.main' }}
                    className="footer-link"
                  />
                </IconButton>
                info@in2tec.io
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Links */}
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          alignItems="center"
          textAlign="center"
        >
          <Grid item>
            <Typography variant="h6">{t('resources')}</Typography>
          </Grid>
          <Grid item>
            <Link
              className="footer-link"
              to="privacy"
              onClick={() =>
                gaEventTracker({
                  category: 'Navigation',
                  action: 'click',
                  label: 'Privacy',
                })
              }
            >
              {t('privacy')}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className="footer-link"
              to="terms-of-use"
              onClick={() =>
                gaEventTracker({
                  category: 'Navigation',
                  action: 'click',
                  label: 'Terms of Service',
                })
              }
            >
              {t('tos')}
            </Link>
          </Grid>
        </Grid>

        {/* Bottom Section */}
        <Grid
          item
          xs={12}
          className="intu__footer-bar light-text"
          textAlign="center"
        >
          <Typography variant="body1">
            {t('copyright', {
              year: new Date().getFullYear(),
            })}{' '}
            - INTUTEC LLC · 3101 Park Blvd. · Palo Alto · CA 94306
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default FooterComponent;
