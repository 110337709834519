import { LeadProvider } from '../../context/providers/leads/LeadContextProvider';
import './howitworks.css';
import { HowItWoksInvestorContent } from '../../components/howitworks/HowItWoksInvestorContent';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider';

const Investor = () => {
  const { t } = useTranslation('translation');
  const { setLayoutTitle } = useContext(LayoutBodyContext);

  useEffect(() => {
    setLayoutTitle(t('pages.Investor.title'));
  }, []);

  return (
    <LeadProvider>
      <HowItWoksInvestorContent />
    </LeadProvider>
  );
};

export default Investor;
