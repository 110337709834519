import { useContext, useEffect } from 'react';
import HomeHeaderContainer from '../../components/home/headers/HomeHeaderContainer.jsx';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider.jsx';
import { useTranslation } from 'react-i18next';
import { HomeComponent } from '../../components/home/HomeComponent.jsx';
import { LeadProvider } from '../../context/providers/leads/LeadContextProvider.jsx';

function Home() {
  const { setLayoutTitle } = useContext(LayoutBodyContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home',
  });

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  return (
    <LeadProvider>
      {/* Header */}
      <HomeHeaderContainer />

      {/* Content */}
      <HomeComponent />
    </LeadProvider>
  );
}

export default Home;
