import { createContext, useContext } from 'react';
import { Grid, Button, Typography, Chip } from '@mui/material';
import { CompanyProfileContext } from '../../company/CompanyProfileProvider';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import dayjs from 'dayjs';
import languagesDataList from '../../../data/LanguagesData.json';
import { searchCustomers } from '../../../api/customerRoutes';
import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../auth/AuthProvider';
import { PublicProfileContext } from './PublicProfileProvider';
import { Link } from 'react-router-dom';
import IntuThemeContext from '../IntuThemeProvider';
import { searchRelationships } from '../../../api/relationshipsRoutes';

export const UserPublicProfileContext = createContext({});

export const UserPublicProfileProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const { getCompanyProfile } = useContext(CompanyProfileContext);
  const { handleGetApplications, handleGetProductTypes } =
    useContext(PublicProfileContext);
  const axios = useAxiosPrivate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.PublicProfile',
  });
  const { handleErrorResponse } = useResponseHandling();
  const { mobileView } = useContext(IntuThemeContext);

  // Search for Language Details
  function handleGetLanguages(languagesByCode) {
    // Guard Clause
    if (languagesByCode?.length === 0) {
      return [];
    }

    // Get Language Details
    let userLanguagess = [];

    languagesByCode.forEach((element) => {
      const languageInfo = languagesDataList.filter(
        (languageInfo) => element === languageInfo.code,
      );
      userLanguagess.push(...languageInfo);
    });

    return userLanguagess;
  }

  // Get User Profile Info
  async function getUserInfo(id) {
    // Guard Clause
    if (!auth.auth_info) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const url = `/api/users/account/profile/user/${id}`;
      const response = await axios.get(url, { signal });

      const { data, status } = response;

      if (status === 200) {
        const userData = data.data;
        let image = '';
        if (userData?.profile_picture?.mimetype) {
          image = `data:${userData.profile_picture.mimetype};base64,${userData.profile_picture.buffer.toString('base64')}`;
        }

        let companyData = {};
        if (userData?.company?.length > 0) {
          companyData = await getCompanyProfile(userData?.company);
        }

        return {
          _id: userData?._id,
          email: userData?.email,
          company: {
            _id: companyData?._id,
            name: companyData?.name,
          },
          linkedin: userData?.linkedin,
          languages: userData?.languages
            ? handleGetLanguages(userData?.languages)
            : [],
          dob: userData?.dob ? dayjs(userData?.dob) : '',
          profile_link: userData?.profile_link || '',
          address: userData?.address,
          initials: `${userData?.first_name.charAt(0).toUpperCase()} ${userData?.last_name
            .charAt(0)
            .toUpperCase()}`,
          name: `${userData?.first_name} ${userData?.last_name}`,
          product_types:
            (await handleGetProductTypes(userData?.product_types)) || [],
          applications:
            (await handleGetApplications(userData?.applications)) || [],
          intro: userData?.intro,
          intro_rte: userData?.intro || getMuiRichTextEditorContent(' '),
          account_type: userData?.account_type,
          profile_picture_view_url: image,
          created: userData.created,
          brands: userData.brands || [],
        };
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    }
  }

  async function handleSearchForAssociatedCustomers(customerArray) {
    if (!customerArray || customerArray.length === 0) {
      return;
    }

    const payload = {
      key: '_id',
      values: customerArray,
    };

    try {
      const data = await searchCustomers(payload);

      const filteredData = data.map((customer) => {
        return {
          industry: customer?.industry,
          city: customer?.address?.city,
          state: customer?.address?.state,
          country: customer?.address?.country?.label,
          location: customer?.address?.location,
        };
      });

      return filteredData;
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  }

  async function getBrandParentCompanyInfo(ownerIdArr) {
    const companiesData = await Promise.all(
      ownerIdArr?.map(async (owner) => {
        const payload = {
          _id: owner,
        };
        const url = `/api/companies/company/public/`;

        try {
          const { data, status } = await axios.post(
            url,
            JSON.stringify(payload),
            {
              headers: { 'Content-Type': 'application/json' },
            },
          );

          if (status === 200) {
            let image = '';
            if (data.data.profile_picture?.mimetype) {
              image = `data:${data.data.profile_picture.mimetype};base64,${data.data.profile_picture.buffer.toString('base64')}`;
            }
            let date;
            if (data.data.created) {
              date = new Date(data.data.created?.date);
            } else {
              date = new Date();
            }
            const companyInfo = {
              id: data.data._id,
              name: data.data.name,
              logo: image,
              region: {
                state: data.data.address.state,
                country: data.data.address.country.label,
              },
              website: data.data.website,
              product_types: await handleGetProductTypes(
                data.data.product_types,
              ),
              start_date: date.toLocaleDateString(),
            };
            return companyInfo;
          }
        } catch (error) {
          console.error(error);
          handleErrorResponse(error);
        }
      }),
    );
    // console.log('returning...', companiesData);
    return companiesData;
  }

  const columns = [
    {
      field: 'companyName',
      headerName: t('company'),
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center">
            {!mobileView && (
              <Grid item>
                <img
                  src={row.logo}
                  alt=""
                  style={{ maxHeight: '100px', maxWidth: '100px' }}
                />
              </Grid>
            )}
            <Grid item ml={2}>
              <Typography>{row.name}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'products',
      headerName: t('products'),
      flex: 3,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container spacing={2}>
            {row.product_types?.map((product) => {
              return (
                <Grid item key={Math.random()}>
                  <Chip label={product.type_name} />
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      field: 'start_date',
      headerName: t('start_date'),
      type: 'number',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'website',
      headerName: t('website'),
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Button
              component={Link}
              to={`/app/company-profile/${row.id}`}
              variant="contained"
              sx={{
                display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {row.name}
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <UserPublicProfileContext.Provider
      value={{
        handleSearchForAssociatedCustomers,
        getUserInfo,
        columns,
        getBrandParentCompanyInfo,
      }}
    >
      {children}
    </UserPublicProfileContext.Provider>
  );
};
