import React, { useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { BackgroundVideo } from '../../../plugins/BackgroundVideoPlayer.jsx';

// Banners
import salesreps from '../../../assets/how-it-works/salesreps.png';
import manufacturer from '../../../assets/how-it-works/manufacturers.png';
import investorBanner from '../../../assets/how-it-works/manufacturers.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { Grid, Typography, Button } from '@mui/material';

// Context
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';

// Translator
import { useTranslation } from 'react-i18next';
import { useIntuTheme } from '../../../context/providers/IntuThemeProvider.jsx';
import { VideoPlayer } from '../../../plugins/VideoPlayer.js';

const LeadHeaderContent = () => {
  const { leadInfo, leadContext } = useContext(LeadContext);
  const [isOpen, setIsOpen] = useState(false);

  // Translator
  const { t } = useTranslation('translation', { keyPrefix: 'context.headers' });
  const { t: transButtons } = useTranslation('buttons');

  // Theme
  const { mobileView } = useIntuTheme();

  let image;
  if (leadInfo?.company_banner_url) {
    image = leadInfo.company_banner_url;
  } else {
    if (
      leadInfo?.lead_type === 'salesrep' ||
      leadInfo?.lead_type === 'affiliate'
    ) {
      image = salesreps;
    } else if (leadInfo?.lead_type === 'manufacturer') {
      image = manufacturer;
    } else if (leadInfo?.lead_type === 'investor') {
      image = investorBanner;
    }
  }

  function handleVideoOpen() {
    setIsOpen(!isOpen);
  }

  const SalesContainerDesktop = ({ children }) => {
    return (
      <Grid
        container
        className="article"
        sx={{
          minHeight: '55vw',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {children}
      </Grid>
    );
  };

  const SalesContainerMobile = ({ children }) => {
    return (
      <Grid
        container
        className="article"
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          minHeight: '350px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {children}
      </Grid>
    );
  };

  const Container = mobileView ? SalesContainerMobile : SalesContainerDesktop;

  return (
    <Grid container disableGutters sx={{ overflow: 'hidden' }}>
      {leadInfo?.lead_type === 'manufacturer' && (
        <Grid
          container
          className="article"
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'fill',
            minHeight: { xs: '350px', md: '450px' },
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            paddingBottom: '30px',
          }}
        >
          {leadInfo?.lead_type === 'manufacturer' && (
            <>
              <Grid
                item
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
                  zIndex: 1,
                }}
              />

              <Grid
                item
                container
                xs={12}
                sx={{
                  position: 'relative',
                  padding: '50px 3rem 0 3rem',
                  zIndex: 2,
                }}
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    variant="h1"
                    className="header"
                    sx={{ color: 'white' }}
                  >
                    {ReactHtmlParser(leadInfo?.headline_title)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                  <Button
                    variant="contained"
                    startIcon={<PlayCircleOutlineIcon />}
                    sx={{ borderRadius: '50px' }}
                    onClick={handleVideoOpen}
                  >
                    {transButtons('view_video')}
                  </Button>

                  <VideoPlayer
                    modal={true}
                    open={isOpen}
                    playing={true}
                    onClose={() => setIsOpen(false)}
                    videoSource="https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/mfg_explainer_en.mp4"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
      {leadInfo?.lead_type === 'salesrep' && (
        <Container disableGutters className="hero-video">
          {mobileView ? (
            ''
          ) : (
            <Grid item sx={{ position: 'absolute' }}>
              <BackgroundVideo
                videoSource={
                  'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explainer_en.mp4'
                }
                stretch
              />
            </Grid>
          )}
          <Grid
            container
            item
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
              zIndex: 1,
            }}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction={{ xs: 'row', md: 'column' }}
          >
            <Grid
              item
              container
              xs={12}
              md={5}
              sx={{
                position: 'relative',
                paddingTop: '50px',
                zIndex: 2,
                width: '100%',
              }}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item container xs={12} md={10} justifyContent="center">
                <Typography variant="heroTitleWhite" className="header">
                  {ReactHtmlParser(
                    t('lead.personal', {
                      leadFullName: `${leadInfo?.first_name} ${leadInfo?.last_name}`,
                    }),
                  )}
                </Typography>
                <Typography variant="heroSubtitleWhite">
                  {t('lead.personal_subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} mb={5}>
                <Button
                  variant="contained"
                  startIcon={<PlayCircleOutlineIcon />}
                  sx={{ borderRadius: '50px' }}
                  onClick={handleVideoOpen}
                >
                  {transButtons('view_video')}
                </Button>

                <VideoPlayer
                  modal={true}
                  open={isOpen}
                  playing={true}
                  onClose={() => setIsOpen(false)}
                  videoSource="https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explainer_en.mp4"
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Grid>
  );
};

export { LeadHeaderContent };
