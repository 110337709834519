import { LoadingButton } from '@mui/lab';
import { CartContext } from '../../context/cart/CartContext';
import { useContext, useMemo, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const PlaceOrderButtonContainer = ({
  areTermsAccepted,
  isCardComplete,
  orderId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const axios = useAxiosPrivate();
  const { cartProducts } = useContext(CartContext);
  const { values } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);

  const eachProductHasShipping = useMemo(() => {
    return cartProducts.every((product) => product.selected_shipping);
  }, [cartProducts]);

  const handlePlaceOrder = async () => {
    console.log('cartProducts on placeOrder', cartProducts);

    try {
      setIsLoading(true);

      console.log('creating customer....', values);

      const payload = {
        email: values.billingIndividual.email,
        name: `${values.billingIndividual.first_name} ${values.billingIndividual.last_name}`,
        phone: values.mobile.value,
        shipping: {
          name: `${values.shippingIndividual.first_name} ${values.shippingIndividual.last_name}`,
          address: {
            line1: values.shippingAddress.line1,
            line2: values.shippingAddress.line2,
            city: values.shippingAddress.city,
            state: values.shippingAddress.state,
            postal_code: values.shippingAddress.postcode,
            country: values.shippingAddress.country.label,
          },
          phone: values.mobile.value,
        },
        address: {
          line1: values.billingAddress.line1,
          line2: values.billingAddress.line2,
          city: values.billingAddress.city,
          state: values.billingAddress.state,
          postal_code: values.billingAddress.postcode,
          country: values.billingAddress.country.label,
        },
      };

      const { data, status } = await axios.post(
        `/api/stripe/customers/public/create`,
        payload,
      );

      console.log('back from customers/public/create', data, status);
      if (status !== 200 && status !== 241) {
        console.log('throwing error here');
        throw new Error('error creating customer');
      }

      console.log('about to confirm payment....');
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://epic-features-local.in2tec.io:3000/checkout-success?order_id=${orderId}&user_id=${data.data.userId}&email=${data.data.email}`,
        },
      });

      if (error) {
        console.error('error', error);
      } else {
        console.log('in else clause but doesnt run on success');
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      loading={isLoading}
      disabled={!eachProductHasShipping || !areTermsAccepted || !isCardComplete}
      onClick={handlePlaceOrder}
    >
      Place Order
    </LoadingButton>
  );
};

export default PlaceOrderButtonContainer;
