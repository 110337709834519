import { useContext, useState } from 'react';
import React from 'react';
import {
  Autocomplete,
  ListSubheader,
  Skeleton,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '../../helpers/toTitleCase.js';

import { ApplicationsContext } from '../../context/applications/ApplicationsContextProvider.jsx';
import FormikFieldWrapper from '../formik/FormikFieldWrapper.jsx';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import { NewApplicationDialog } from './NewApplicationsDialog.jsx';
import { CustomPaperComponentAutoCompleteDropDown } from '../mui/CustomPaperComponents.jsx';

const ApplicationsFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'applications', // Define the Fields Name and ID
  autocompleteID = 'applications', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ApplicationsField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined', // Set the Variant type standard or outlined
}) => {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const {
    setNewApplicationDialogOpen,
    setApplicationNew,
    newApplicationList,
    setNewApplicationList,
    applicationOptions,
  } = useContext(ApplicationsContext);

  const filter = createFilterOptions();

  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const handleApplicationChange = (e, options, reason, element) => {
    if (reason === 'createOption') {
      // Add new application when user hits return button
      setNewApplicationDialogOpen(true);
      setApplicationNew({
        application_name: toTitleCase(element.option),
        industry_id: '',
      });
    } else if (
      reason === 'selectOption' &&
      element.option.application_name?.search('Add ') >= 0
    ) {
      // Add new Application when user presses Add button
      setNewApplicationDialogOpen(true);
      setApplicationNew({
        application_name: toTitleCase(element.option.inputValue),
        industry_name: '',
      });
    } else if (reason === 'removeOption') {
      const elementToRemove = element.option;
      let filtered = newApplicationList.filter(
        (item) => item.application_name !== elementToRemove.application_name,
      );
      setFieldValue('applications', options);
      setNewApplicationList(filtered);
    } else {
      setFieldValue('applications', options);

      // Get New List of Unique Industries
      let distinctIndustries = [
        ...new Set(options.map((item) => item.industry_id)),
      ];

      const uniqueIndustries = distinctIndustries.map((item) => {
        const foundIndustryOption = options.find(
          (option) => option.industry_id === item,
        );
        return {
          industry_id: foundIndustryOption.industry_id,
          industry_name: foundIndustryOption.industry_name,
        };
      });

      // console.log('Distinct Industries', distinctIndustries);
      // console.log('Unique Industry Options', uniqueIndustries);
      setFieldValue('industries', uniqueIndustries);
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          multiple
          freeSolo
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          filterSelectedOptions
          disableCloseOnSelect
          value={getNestedValue(values, autocompleteID)}
          required={required}
          disabled={disabled || isLoading?.status ? true : false}
          id={fieldID}
          name={fieldID}
          options={applicationOptions?.sort((a, b) => {
            if (a.industry_name === b.industry_name) {
              return -b.application_name.localeCompare(a.application_name);
            }
            return -b.industry_name.localeCompare(a.industry_name);
          })}
          getOptionLabel={(option) => option?.application_name}
          groupBy={(option) => option?.industry_name}
          className="form-select-field"
          onChange={handleApplicationChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '' && filtered.length === 0) {
              filtered.push({
                inputValue: params.inputValue,
                application_name: `${t('add')} "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant}
              label={!disabled ? t('label') : null}
              placeholder={!disabled ? t('placeholder') : null}
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
          renderGroup={(params) => {
            return (
              <React.Fragment key={`group-${params.group}-${params.key}`}>
                <ListSubheader
                  key={`header-${params.group}-${params.key}`}
                  className="custom-group-header"
                >
                  {params.group}
                </ListSubheader>
                {params.children}
              </React.Fragment>
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.application_id}>
                {option.application_name}
              </li>
            );
          }}
          PaperComponent={(props) => (
            <CustomPaperComponentAutoCompleteDropDown
              {...props}
              onClose={handleClose}
              transNS={transNS}
              transPrefix={transPrefix}
            />
          )}
        />
      </FormikFieldWrapper>
      <NewApplicationDialog />
    </>
  );
};

const ApplicationsField = (props) => {
  return <ApplicationsFieldContent {...props} />;
};

export default ApplicationsField;
