import { useEffect, useState } from 'react';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';

const CarriersBox = ({ selectedCarrier, setSelectedCarrier }) => {
  const axios = useAxiosPrivate();
  const [carriers, setCarriers] = useState([]);

  const getCarriers = async () => {
    try {
      const res = await axios.get('/api/ship-station/carriers');
      setCarriers(res.data.data);
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getCarriers();
  }, []);

  const handleCarrierChange = (event) => {
    setSelectedCarrier(event.target.value);
  };

  if (!carriers.length) return null;
  return (
    <Box display="flex" alignItems="center" justifyContent="space-evenly">
      <InputLabel id="carrier-select">Select a shipping service:</InputLabel>
      <Select
        labelId="carrier-select"
        label={'Select a shipping service:'}
        name="carrier"
        value={selectedCarrier}
        onChange={handleCarrierChange}
        sx={{ minWidth: 190 }}
      >
        {carriers.map((c) => (
          <MenuItem key={c.shippingProviderId} value={c}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CarriersBox;
