import { useContext } from 'react';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';
import { Grid } from '@mui/material';
import StripeBalance from './balanace/StripeBalanace';
import PayoutAccountsList from './payoutAccounts/PayoutAccountsList';
import { AccountRequirements } from './AccountRequirements';
import PersonsList from './person/PersonsList';
import StripeBalanceTransactionsList from './balanace/StripeBalanceTransactionsList';
import ChargesList from './charges/ChargesList';

const MerchantSettingsDashboard = () => {
  const { stripeUserData } = useContext(StripeContext);
  // console.log('Stripe User Data ->', stripeUserData);

  const requirements = stripeUserData?.stripe_connect_data?.currently_due || [];

  console.log('Stripe Customer Data ->', stripeUserData);
  return (
    <Grid>
      {/* Account Balanace */}
      <StripeBalance stripeUserData={stripeUserData} />

      {/* Account Transactions */}
      <StripeBalanceTransactionsList />

      {/* Account Requirements */}
      <AccountRequirements requirements={requirements} />

      {/* List of Payout Accounts */}
      <PayoutAccountsList />

      {/* List of Persons */}
      <PersonsList />
    </Grid>
  );
};

export { MerchantSettingsDashboard };
