import { createContext, useEffect, useMemo, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [manufacturers, setManufacturers] = useState(new Map());

  const cartSubTotal = useMemo(() => {
    if (!cartProducts || !cartProducts.length) return 0;
    return cartProducts.reduce(
      (acc, curr) => acc + curr.quantity * curr.product.list_price,
      0,
    );
  }, [cartProducts]);

  const cartTotal = useMemo(() => {
    if (!cartProducts || !cartProducts.length) return 0;
    return cartProducts.reduce(
      (acc, curr) =>
        acc +
        curr.quantity * curr.product.list_price +
        (curr.selected_shipping
          ? curr.selected_shipping.shipmentCost +
            curr.selected_shipping.otherCost
          : 0),
      0,
    );
  }, [cartProducts]);

  useEffect(() => {
    const manufacturersMap = new Map();
    cartProducts.forEach((product) => {
      const manufacturerId = product.product.author.manufacturer;
      const manufacturerData = {
        _id: manufacturerId,
        name: product.product.author.manufacturer_name,
        address: product.product.author.manufacturer_address,
        stripe_acc_id: product.product.author.stripe_acc_id,
      };

      if (manufacturersMap.has(manufacturerId)) {
        const currentProducts = manufacturersMap.get(manufacturerId).products;
        currentProducts[product.product._id] = {
          product: product.product,
          quantity: product.quantity,
        };
      } else {
        manufacturersMap.set(manufacturerId, {
          ...manufacturerData,
          products: {
            [product.product._id]: {
              product: product.product,
              quantity: product.quantity,
              appointedRep: product.appointedRep,
            },
          },
        });
      }
    });
    setManufacturers(manufacturersMap);
  }, [cartProducts]);

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        setCartProducts,
        cartSubTotal,
        cartTotal,
        manufacturers,
        setManufacturers,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
