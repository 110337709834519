import i18next from 'i18next';
import * as Yup from 'yup';

const AddPaymentMethodValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    card_number: Yup.mixed().nullable(),
    expiration: Yup.mixed().nullable(),
    name: Yup.string().required(t('name.required')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short'))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short'))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short'))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short'))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
  });
};

export { AddPaymentMethodValidationSchema };
