import { UserPublicProfileContent } from '../../components/users/UserPublicProfileContent';
import IntuPage from '../../components/layout/IntuPage';
import CustomerMapProvider from '../../context/providers/maps/CustomerMapProvider';
import { UserPublicProfileProvider } from '../../context/providers/profile/UserPublicProfileProvider';
import { PublicProfileProvider } from '../../context/providers/profile/PublicProfileProvider';
function UserPublicProfile() {
  return (
    <PublicProfileProvider>
      <UserPublicProfileProvider>
        <CustomerMapProvider>
          <IntuPage lessMT>
            <UserPublicProfileContent />
          </IntuPage>
        </CustomerMapProvider>
      </UserPublicProfileProvider>
    </PublicProfileProvider>
  );
}

export default UserPublicProfile;
