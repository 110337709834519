import { axiosPrivate } from '../../hooks/axios/axios.js';

const getPaymentMethodsForCustomer = async (stripeCustomerId) => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/stripe/payment-methods/customer/${stripeCustomerId}`,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const detachPaymentMethod = async (paymentMethodId) => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/stripe/payment-methods/detach/${paymentMethodId}`,
    );
    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

export { getPaymentMethodsForCustomer, detachPaymentMethod };
