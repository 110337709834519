import {
  Paper,
  ListSubheader,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// Response & Loading Handlers
import CloseIcon from '@mui/icons-material/Close';

const CustomPaperComponentAutoCompleteDropDown = ({
  children,
  transNS,
  transPrefix,
  onClose,
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  return (
    <Paper sx={{ margin: 0, padding: 0 }}>
      <Grid sx={{ margin: 0 }}>
        <Grid item>
          <ListSubheader
            sx={{
              padding: 0,
              position: 'sticky', // Ensure the header stays on top
              top: 0, // Sticky position at the top
              zIndex: 1, // Make sure it's above other elements
            }}
          >
            <Grid
              container
              alignItems="center" // Vertically align items in the center
              justifyContent="space-between" // Spread content evenly
              sx={{
                padding: '1rem 1rem 1rem 1rem',
              }}
            >
              <Grid item xs={10}>
                <Typography>{t('select')}</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  marginLeft: 'auto',
                }}
              >
                <IconButton
                  onClick={onClose}
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListSubheader>
        </Grid>
        <Grid item sx={{ margin: 0 }}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export { CustomPaperComponentAutoCompleteDropDown };
