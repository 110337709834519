import { axiosPrivate } from '../../hooks/axios/axios.js';
import i18n from '../../i18n.js';

// List Payout Accounts
async function listBalanceTransactions(query) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/stripe/balance-transactions/list';

  try {
    const listPersons = await axiosPrivate.get(url, {
      query,
      signal,
    });

    console.log('Balance Transactions List ->', listPersons);
    return listPersons;
  } catch (error) {
    console.error('error', error.response);
    return error.response;
  } finally {
    controller.abort(signal);
  }
}

export { listBalanceTransactions };
