import { useTranslation } from 'react-i18next';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import Rocket from '../../assets/accelerate.png';
import ProfileCompletionStatusItem from '../../components/status/ProfileCompletionStatusItem.jsx';
import { CompanyProfileContext } from '../../context/company/CompanyProfileProvider.jsx';
import { StripeContext } from '../../context/providers/stripe/StripeContextProvider.jsx';
import { UserProfileContext } from '../../context/users/UserProfileProvider.jsx';

function UserDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userProfile, setUserProfile, getUserProfile } =
    useContext(UserProfileContext);
  const { companyProfile } = useContext(CompanyProfileContext);
  const {
    stripeUserData,
    isStripeLoading,
    stripeErrorMessage,
    createSubscription,
  } = useContext(StripeContext);

  const handleCompleteStripeAccount = () => {
    window.open(stripeUserData.account_link, '_blank', 'noreferrer');
  };

  const location = useLocation();

  const handleGetUserProfile = async () => {
    const profile = await getUserProfile();
    setUserProfile(profile);
  };

  useEffect(() => {
    if (location.state?.from === 'SignUpCompleteForm') {
      handleGetUserProfile();
    }
  }, []);

  const handlePlanSelect = async (tier) => {
    // await createSubscription(tier);
    console.log('Payment Tier -> ', stripeUserData?.payment_links);
    window.open(stripeUserData.payment_links[tier], '_blank', 'noreferrer');
  };

  return (
    <DashboardLayoutComponent
      dashboardTitle={t('context.users.dashboard.title')}
      dashboardDescription={t('context.users.dashboard.acct_complete')}
    >
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignContent="flex-start"
        alignItems="flex-start"
        textAlign={{ xs: 'center', md: 'left' }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{ paddingBottom: '1rem', position: 'relative' }}
        >
          <Typography variant="body">
            {t('context.users.dashboard.acct_complete')}
          </Typography>
          <Typography variant="body">
            {t('context.users.dashboard.early_access')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: '2rem' }}>
          {userProfile?.profile_completion !== 100 &&
            companyProfile?.profile_completion !== 100 && (
              <Typography variant="body" align="left">
                {t('context.users.dashboard.advise')}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} md={8} container direction="column">
          {/* Personal Profile */}
          {userProfile?.profile_completion < 100 && (
            <ProfileCompletionStatusItem
              message={t('context.users.dashboard.complete_profile')}
              value={userProfile.profile_completion}
              cta={() => navigate('/app/user/profile')}
              missingFields={userProfile.missing_fields}
              type={'user'}
            />
          )}

          {/* Company Profile */}
          {companyProfile?.profile_completion < 100 &&
            companyProfile.isAdmin && (
              <ProfileCompletionStatusItem
                message={t('context.users.dashboard.complete_company_profile')}
                value={companyProfile.profile_completion}
                cta={() => navigate('/app/company/profile')}
                missingFields={companyProfile.missing_fields}
                type={'company'}
              />
            )}

          {/* Stripe */}
          {stripeUserData.account_link ? (
            <Grid
              item
              xs={12}
              container
              sx={{ marginBottom: '2rem', position: 'relative' }}
            >
              <Paper
                elevation={20}
                sx={{
                  background: 'transparent',
                  padding: '1rem',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                <Grid container direction="column">
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {isStripeLoading ? (
                      <Grid
                        item
                        xs
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Typography variant="body" mb={2}>
                          {t('context.users.dashboard.stripe_requirements')}
                        </Typography>
                        <LinearProgress sx={{ width: '100%' }} />
                      </Grid>
                    ) : !stripeUserData.account_complete &&
                      stripeUserData.account_link ? (
                      <>
                        <Grid item xs>
                          <Typography variant="body" align="left">
                            {t('context.users.dashboard.complete_stripe')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCompleteStripeAccount}
                          >
                            {t('buttons.complete')}
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Typography variant="body" align="left">
                        {stripeErrorMessage}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null}
        </Grid>

        {/* Rocket Image */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box
            component="img"
            sx={{
              width: 250,
              maxWidth: { md: 250, xs: 150 },
              alignSelf: 'flex-end',
              mt: 'auto',
            }}
            alt="Accellerate."
            src={Rocket}
          />
        </Grid>
      </Grid>
    </DashboardLayoutComponent>
  );
}

export default UserDashboard;
