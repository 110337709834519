import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getIndustries } from '../../api/industriesRoutes';

const NewApplicationDialog = ({ isOpen, handleConfirm, handleClose }) => {
  const { t } = useTranslation();
  const [newApplication, setNewApplication] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [industries, setIndustries] = useState([]);

  const handleInputChange = (e) => {
    setNewApplication(e.target.value);
  };

  useEffect(() => {
    getIndustryOptions();
  }, []);

  const getIndustryOptions = async () => {
    const { data, statusCode } = await getIndustries(true);
    if (statusCode === 200) {
      setIndustries(data);
    }
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>New Application</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <Select onChange={handleIndustryChange}>
            {industries.map((industry) => {
              return (
                <MenuItem key={industry.industry_id} value={industry}>
                  {industry.industry_name}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            label="New Application"
            variant="outlined"
            size="small"
            name="applicationValue"
            value={newApplication}
            onChange={handleInputChange}
            // error={
            //   newApplication.applicationValue < 0 ||
            //   newApplication.applicationValue > 100
            // }
            // helperText={'Value must be between 0 and 100'}
            sx={{ my: 2 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button onClick={handleClose} color="secondary" variant="contained">
          {t('buttons.close')}
        </Button>
        <Button
          onClick={() =>
            handleConfirm({
              industry: selectedIndustry,
              newApplication: newApplication,
            })
          }
          color="primary"
          type="submit"
          disabled={!newApplication || !selectedIndustry}
          variant="contained"
        >
          {t('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewApplicationDialog;
