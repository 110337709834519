import { useState, useEffect, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { Trans, useTranslation } from 'react-i18next';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import IntuIconButton from '../buttons/IntuIconButton.jsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListLoader from '../status/ListLoader.jsx';

const createProductColumns = (t, handleEditClick, handleViewClick) => {
  return [
    {
      field: 'product_name',
      headerName: t('form.products.name_label'),
      editable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'mpn',
      headerName: t('form.products.mpn_label'),
      editable: false,
      flex: 0.3,
      minWidth: 50,
    },
    {
      field: 'product_group',
      headerName: 'Product Type',
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        if (!params.row.product_types || !params.row.product_types.length)
          return '';

        return params.row.product_types.map((type) => type.name_en).join(', ');
      },
    },
    {
      field: 'list_price',
      headerName: t('form.products.list_price'),
      editable: false,
      renderCell: (params) => {
        return (
          <span>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(params.row.list_price)}
          </span>
        );
      },
    },
    {
      field: 'status',
      headerName: t('form.status.status'),
      editable: false,
    },
    {
      field: 'edit',
      headerName: t('buttons.edit'),
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            onClick={() => handleEditClick(params)}
            tooltipTitle={t('buttons.edit')}
          />
        );
      },
    },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={'View'}
            onClick={() => handleViewClick(params)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
  ];
};

const CompanyProductList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isListLoading, setIsListLoading] = useState(false);

  const handleEditClick = (params) => {
    navigate(`/app/edit-product/${params.id}`);
  };

  const handleDeleteClick = async (params) => {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.delete_product'),
    });

    try {
      // We only need to make 2 calls if files or images exist
      if (params.row.images.length || params.row.files.length) {
        const bucketPayload = {
          folder: `products/${params.id}/`,
        };
        const apiRequests = [
          axiosPrivate.delete(`/api/products/${params.id}`),
          axiosPrivate.post('/api/aws/content/delete-folder', bucketPayload, {
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        ];

        const [deleteDocumentResponse, deleteDOResponse] =
          await Promise.all(apiRequests);

        if (
          deleteDocumentResponse.status === 200 &&
          deleteDOResponse.status === 200
        ) {
          setProducts((prevState) =>
            prevState.filter((product) => product._id !== params.id),
          );
        }
      } else {
        const { status } = await axiosPrivate.delete(
          `/api/products/${params.id}`,
        );
        if (status === 200) {
          setProducts((prevState) =>
            prevState.filter((product) => product._id !== params.id),
          );
        }
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  const handleViewClick = (params) => {
    navigate(`/product/${params.row._id}`);
  };

  const productColumns = useMemo(() => {
    return createProductColumns(t, handleEditClick, handleViewClick);
  }, [t, handleEditClick, handleViewClick]);

  const getProducts = async () => {
    setIsListLoading(true);
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const { data, status } = await axiosPrivate.get('/api/products/', {
        signal,
      });

      if (status === 200) {
        setProducts(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
      controller.abort();
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const createProduct = async () => {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.new_product'),
    });

    try {
      const { data, status } = await axiosPrivate.post(
        '/api/products/create-empty',
      );

      if (status === 200) {
        navigate(`/app/new-product/${data.data.insertedId}`);
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            variant="contained"
            type="submit"
            endIcon={<RocketLaunchIcon />}
            onClick={createProduct}
            sx={{ minWidth: 150, maxHeight: 40 }}
          >
            {t('buttons.new_product')}
          </Button>
        </Box>
        {isListLoading ? (
          <ListLoader columns={8} rows={5} />
        ) : !products?.length ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h4">
              <Trans t={t} i18nKey="form.message.no_products_alt" />
            </Typography>
          </Grid>
        ) : (
          <Box height="auto" width="100%">
            <DataGrid
              isCellEditable={(params) => false}
              getRowId={(row) => row._id}
              rows={products}
              columns={productColumns}
              columnVisibilityModel={{
                list_price: !isMobileView,
                attributes: !isMobileView && !isMediumView,
                applications: !isMobileView && !isMediumView,
                product_group: !isMobileView && !isMediumView,
                mpn: !isMobileView,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [{}],
                },
              }}
              pageSizeOptions={[5, 10, 100]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
              slots={{
                Row: MemoizedRow,
                ColumnHeaders: MemoizedColumnHeaders,
              }}
              sx={{
                borderRadius: '12px',
                boxShadow: 6,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CompanyProductList;
