// Provides context to the Intutec Leads List and Edit Form

import { createContext, useContext, useState } from 'react';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker.jsx';

import UserContext from '../../users/UserInfoProvider.jsx';
import ProcessingContext from '../ProcessingProvider.jsx';
import { getIntutecEmployees } from '../../../api/employeeRoutes.js';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../../hooks/axios/useAxiosPrivate.js';
import { searchIndustries } from '../../../api/industriesRoutes.js';
import { formatDateToMMDDYYYY } from '../../../helpers/formatDate.js';
import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor.js';
import { toTitleCase } from '../../../helpers/toTitleCase.js';
import { convertFileToBase64 } from '../../../helpers/convertFileToBase64.js';
import { IntuCompanyContext } from '../IntuCompanyInfoProvider.jsx';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { blankLeadForm } from './blankLeadForm.js';

export const ManageLeadContext = createContext({});

export const ManageLeadProvider = ({ children }) => {
  // Axios
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  // Translator & Language
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');

  // Context
  const { lng, host_url } = useContext(UserContext);
  const { setIsLoading } = useContext(ProcessingContext);

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // States
  const [leads, setLeads] = useState([]);

  const [activityDialog, setActivityDialog] = useState({
    openDialog: false,
    leadID: '',
    activityDialogAction: '',
    activityDialogTitle: '',
    activityDialogText: '',
    activityPrimaryButtonText: '',
    activitySecondaryButtonText: '',
    activityLog: [],
    activityContent: {
      _id: '',
      user: '',
      date: '',
      activity: '',
      comment: '',
    },
  });

  const { employeeOptions } = useContext(IntuCompanyContext);

  // Global States shared accross components
  const leadForm = blankLeadForm({ lng });
  const [leadInfo, setLeadInfo] = useState(leadForm);
  const [leadDetailsDialog, setLeadDetailsDialog] = useState({
    open: false,
    lead_type: '',
    title: 'Title',
    text: 'Description',
    primaryButtonText: '',
    secondaryButtonText: '',
    dialogAction: '',
  });

  // google page view tracking tracking
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Get Leads
  const getLeads = async () => {
    setIsLoading({
      status: true,
      type: 'skeleton',
      text: transMessages('spinner.fetch', { type: transTypes('leads') }),
    });

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      // Get Employees
      const payload = {
        priviliges: ['sales'],
      };

      // Get Employees
      const getEmployeesList = await getIntutecEmployees(payload);
      const employeesList = getEmployeesList.data || [];

      // Get Leads
      const response = await axios.get('/api/intutec/leads/list', { signal });

      const { data, status } = response;

      if (status === 200) {
        let leadInfo = data.data;

        // Structure User Data to comply with data grid requirements
        let leadsArray = [];

        for (let i = 0; i < leadInfo.length; i++) {
          const lead = leadInfo[i];

          leadsArray.push({
            _id: lead._id,
            activity: lead?.activity ? lead.activity : [],
            associate:
              employeesList.find((item) => item._id === lead.associate) || null,
            associate_label: (
              employeesList.find((item) => item._id === lead.associate) || {
                name: '',
              }
            ).name,
            company: lead?.company,
            lead_type: lead?.lead_type,
            lead_type_label: lead?.lead_type
              ? toTitleCase(lead.lead_type)
              : null,
            first_name: lead.first_name,
            last_name: lead.last_name,
            address: lead?.address,
            industries: lead.industries,
            origin: lead.origin,
            origin_label: lead?.origin && toTitleCase(lead.origin),
            status: lead.status,
            status_label: toTitleCase(lead.status),
          });
        }
        const sortedArrayByDate = leadsArray.sort((a, b) => {
          const latestDateA = Math.max(
            ...a.activity.map((item) => new Date(item.date)),
          );
          const latestDateB = Math.max(
            ...b.activity.map((item) => new Date(item.date)),
          );
          return latestDateB - latestDateA;
        });
        setLeads(sortedArrayByDate);
      }
    } catch (err) {
      if (err.name === 'CanceledError') return;

      console.error(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
      controller.abort();
    }
  };

  // Get Lead Details
  const getLeadDetails = async (id) => {
    // Guard CLause
    if (!id) {
      throw new Error();
    }

    setIsLoading({
      status: true,
      type: 'skeleton',
      text: transMessages('spinner.fetch', { type: transTypes('leads') }),
    });

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      // Get Employees
      const provolegesPayload = {
        priviliges: ['sales'],
      };

      // Get Employees
      const getEmployeesList = await getIntutecEmployees(provolegesPayload);
      const employeesList = getEmployeesList.data || [];

      // Get Leads
      const leadPayload = {
        key: '_id',
        values: id,
      };
      const url = '/api/intutec/leads/details';
      const request = await axios.post(url, leadPayload, {
        signal,
      });

      const response = request?.data;

      if (request.status === 200) {
        const [lead] = response.data;

        // If an image is available, fetch and convert it
        let imageBlob;

        if (lead?.company_banner_url) {
          const controller = new AbortController();
          const signal = controller.signal;
          const imageResponse = await fetch(lead.company_banner_url, {
            signal,
          });

          if (!imageResponse.ok) {
            lead.company_banner_url = '';
          }

          const imageFile = await imageResponse.blob();

          // Create a promise to wrap the FileReader logic
          const loadImageBlob = () => {
            return new Promise((resolve) => {
              const reader = new FileReader();

              reader.onloadend = () => {
                imageBlob = reader.result;
                resolve(imageBlob);
              };

              reader.readAsDataURL(imageFile);
            });
          };

          // Call the loadImageBlob function and await the result
          imageBlob = await loadImageBlob();
        }

        // Get Industry Labels
        let userIndustriesList = [];
        if (lead?.industries?.length > 0) {
          userIndustriesList = await searchIndustries(lead.industries);
        }

        setLeadInfo({
          _id: lead._id,
          active: lead.active,
          language: lead?.language,
          activity: lead?.activity ? lead.activity : [],
          associate:
            employeesList.find((item) => item._id === lead.associate) || null,
          associate_label: (
            employeesList.find((item) => item._id === lead.associate) || {
              name: '',
            }
          ).name,
          associate_activity: lead?.associate_activity
            ? lead.associate_activity
            : [],
          clicked: lead?.clicked ? lead.clicked : false,
          code: lead.code,
          company: lead?.company,
          website: lead?.website,
          company_type: lead?.company_type,
          lead_type: lead?.lead_type,
          company_banner_blob: imageBlob,
          company_banner_url: lead.company_banner_url,
          created: formatDateToMMDDYYYY(lead.created.date),
          email: lead?.email ? lead.email : '',
          email_sent: lead?.email_sent ? lead.email_sent : false,
          first_name: lead.first_name,
          last_name: lead.last_name,
          address: lead?.address,
          phone: lead?.phone,
          headline_title: lead?.headline_title,
          headline_body: lead?.headline_body,
          headline_body_rte:
            lead?.headline_body &&
            getMuiRichTextEditorContent(lead.headline_body),
          industries: userIndustriesList,
          intro_type: lead.intro_type ? lead.intro_type : '',
          intro_subject: lead?.intro_subject ? lead?.intro_subject : '',
          intro_message: lead?.intro_message ? lead?.intro_message : '',
          origin: lead.origin,
          origin_label: lead?.origin && toTitleCase(lead.origin),
          send_email: lead?.send_email ? lead.send_email : false,
          status: lead.status,
          status_label: toTitleCase(lead.status),
          lead_type_label: toTitleCase(lead.lead_type),
        });

        return lead;
      }
    } catch (err) {
      if (err.name === 'CanceledError') return;

      console.error(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
      controller.abort();
    }
  };

  // Save Lead
  async function saveLead(action, values, formik) {
    // Guard Clause
    if (!action) {
      return;
    }

    // Sey Loading State
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    // If an image is provided, process it
    async function saveCompanyBanner() {
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const base64File = await convertFileToBase64(values.company_banner);
        let bannerURL;

        // Upload Post Image to aws bucket to make it public
        const url = '/api/aws/content/add';

        const requestData = {
          file: base64File,
          file_type: values.company_banner.type,
          file_name: values.company_banner.name.replace(' ', '-'),
          folder: `leads`,
        };

        const saveBanner = await axiosData.post(
          url,
          JSON.stringify(requestData),
          {
            signal,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );

        const response = saveBanner?.data;

        if (saveBanner.status === 200) {
          bannerURL = response.data;
        } else {
          // error while uploading and saving the banner
          handleErrorResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        }
        return bannerURL;
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        controller.abort(signal);
      }
    }

    // Add or update lead to database
    let industriesByID = [];
    if (values?.industries?.length > 0) {
      industriesByID = values.industries.map(
        (industry) => industry.industry_id,
      );
    }

    // get Associate ID
    async function saveLeadToDatabase() {
      let url;
      let payload = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        lead_type: values?.lead_type,
        address: values?.address,
        company_type: values?.company_type,
        company: values?.company,
        email: values?.email,
        website: values?.website,
        phone: values?.phone,
        active: values?.active,
        language: values?.language,
        industries: industriesByID,
        intro_type: values?.intro_type,
        intro_subject: values?.intro_subject,
        intro_message: values?.intro_message,
        headline_title: values?.headline_title,
        headline_body: values?.headline_body,
        associate: values?.associate?._id,
        company_banner_url: values.company_banner_new
          ? await saveCompanyBanner()
          : values.company_banner_url,
        send_email: values?.send_email,
        associate_activity: values?.associate_activity,
        host_url,
      };
      let eventMessage;

      if (action === 'add') {
        url = '/api/intutec/leads/create';
        payload = { ...payload, origin: 'associate' };
        eventMessage = 'New Lead Created';
      } else {
        url = '/api/intutec/leads/update';
        payload = { ...payload, _id: values._id };
        eventMessage = 'New Lead Updated';
      }

      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const request = await axios.post(url, payload, {
          signal,
        });
        const response = request?.data;

        if (request.status === 200 || request.status === 201) {
          let leadInfo = response.data;

          // Track Event on Google
          gaEventTracker({
            category: 'leads',
            action: 'event',
            label: eventMessage,
          });

          let imageBlob;

          if (leadInfo?.company_banner_url) {
            const controller = new AbortController();
            const signal = controller.signal;
            const imageResponse = await fetch(leadInfo.company_banner_url, {
              signal,
            });

            if (!imageResponse.ok) {
              throw new Error(
                'Network response was not ok' + imageResponse.statusText,
              );
            }

            const imageFile = await imageResponse.blob();

            // Create a promise to wrap the FileReader logic
            const loadImageBlob = () => {
              return new Promise((resolve) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                  imageBlob = reader.result;
                  resolve(imageBlob);
                };

                reader.readAsDataURL(imageFile);
              });
            };

            // Call the loadImageBlob function and await the result
            imageBlob = await loadImageBlob();
          }

          // Get Users Industry Labels using the industry_id
          let userIndustriesList = [];
          if (leadInfo?.industries?.length > 0) {
            userIndustriesList = await searchIndustries(leadInfo.industries);
          }

          await getLeads();
          setLeadInfo({
            ...leadInfo,
            ...values,
            associate: employeeOptions.find(
              (item) => item._id === leadInfo.associate,
            ),
            industries: userIndustriesList,
            created: formatDateToMMDDYYYY(leadInfo.created.date),
            company_banner_blob: imageBlob,
            headline_body_rte: getMuiRichTextEditorContent(
              leadInfo.headline_body,
            ),
            origin_label: leadInfo?.origin && toTitleCase(leadInfo.origin),
            status_label: toTitleCase(leadInfo.status),
            lead_type_label: toTitleCase(leadInfo.lead_type),
          });

          const label = leadInfo?.lead_type === 'investor' ? 'pitch' : 'lead';
          setLeadDetailsDialog({
            ...leadDetailsDialog,
            primaryButtonText: transButtons('update', {
              type: transTypes(label),
            }),
            dialogAction: 'update',
          });

          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        } else {
          // handle all other responses
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        controller.abort(signal);
      }
    }

    async function handleSubmit() {
      formik.setSubmitting(true);
      await saveLeadToDatabase();
      setIsLoading({ status: false, type: 'skeleton', text: '' });
      formik.setSubmitting(false);
    }

    handleSubmit();
  }

  return (
    <ManageLeadContext.Provider
      value={{
        leadInfo,
        getLeadDetails,
        setLeadInfo,
        activityDialog,
        setActivityDialog,
        leadDetailsDialog,
        setLeadDetailsDialog,
        leads,
        getLeads,
        saveLead,
      }}
    >
      {children}
    </ManageLeadContext.Provider>
  );
};
