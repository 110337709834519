import React, { useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import { CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { StripeElementWrapper, StripeTextField } from '../../mui/inputStyles';

const CreditCardNumberField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'card_number', // Defines the ID and Name of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'CreditCardNumberField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', // Design standard or
  isLoadingManual = false, // Manually control the Loading State
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidations } = useTranslation('validations');
  const elements = useElements();

  // Context
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <StripeTextField
        disabled={disabled}
        id={fieldID}
        name={fieldID}
        variant={variant}
        required={required}
        label={t('label')}
        InputLabelProps={{ shrink: true }}
        value={fieldValue}
        onBlur={() => {
          const cardNumberElement = elements.getElement('cardNumber');
          if (cardNumberElement._empty) {
            setFieldError(
              'card_number',
              transValidations('card_number.required'),
            );
          } else if (cardNumberElement._invalid) {
            setFieldError(
              'card_number',
              transValidations('card_number.invalid'),
            );
          } else {
            setFieldError('card_number', ''); // Clear errors if valid
          }
          setFieldTouched('card_number', true);
        }}
        InputProps={{
          inputComponent: StripeElementWrapper,
          inputProps: {
            Component: CardNumberElement,
            options: {
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  letterSpacing: '0.025em',
                  '::placeholder': { color: '#aab7c4' },
                },
                invalid: { color: '#9e2146' },
              },
            },
          },
        }}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default CreditCardNumberField;
