import { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddCommissionDialog = ({
  commissions,
  isOpen,
  handleConfirm,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [newCommission, setNewCommission] = useState({
    max_amount: null,
    commission: null,
    cost: null,
  });

  const handleInputChange = (e) => {
    setNewCommission((prevState) => {
      return {
        ...prevState,
        [e.target.name]: Number(e.target.value),
      };
    });
  };

  const handleConfirmClick = () => {
    handleConfirm({
      ...newCommission,
      commission: newCommission.commission * 0.01,
    });

    setNewCommission({
      max_amount: null,
      commission: null,
      cost: null,
    });
  };

  const handleCloseClick = () => {
    setNewCommission({
      max_amount: null,
      commission: null,
      cost: null,
    });
    handleClose();
  };

  const isDisabled = useMemo(() => {
    const commissionExists = commissions.some(
      (commission) => commission.commission === newCommission.commission,
    );
    const amountExists = commissions.some(
      (commission) => commission.max_amount === newCommission.max_amount,
    );

    return !newCommission.max_amount ||
      !newCommission.commission ||
      newCommission.commission > 100 ||
      newCommission.commission < 0 ||
      commissionExists ||
      amountExists
      ? true
      : false;
  }, [commissions, newCommission]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('components.commission.add')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Enter a max amount"
            variant="outlined"
            size="small"
            name="max_amount"
            value={
              newCommission.max_amount === 0 || !newCommission.max_amount
                ? ''
                : newCommission.max_amount
            }
            onChange={handleInputChange}
            error={newCommission.max_amount < 0}
            helperText={
              newCommission.max_amount < 0
                ? 'Value must be be greater than 0'
                : ''
            }
            sx={{ mb: 2 }}
          />
          <TextField
            type="number"
            label="Enter a commission rate"
            variant="outlined"
            size="small"
            name="commission"
            value={
              newCommission.commission === 0 || !newCommission.commission
                ? ''
                : newCommission.commission
            }
            onChange={handleInputChange}
            error={
              newCommission.commission < 0 || newCommission.commission > 100
            }
            helperText={'Value must be between 0 and 100'}
            FormHelperTextProps={{
              sx: {
                color: 'primary.dark',
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
        >
          {t('buttons.close')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          type="submit"
          disabled={isDisabled}
          variant="contained"
        >
          {t('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCommissionDialog;
