import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

const renderWarrantyTerms = (blocks) => {
  return blocks.map((block, index) => (
    <Typography key={index} variant="body1" paragraph>
      {block.text}
    </Typography>
  ));
};

const WarrantyTerms = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const warrantyTerms = useMemo(() => {
    if (!product || !product.tow || !product.tow.blocks) return [];
    return product.tow.blocks;
  }, [product]);
  return (
    <>
      <Typography variant="h6">Terms of Warranty</Typography>
      <Box display="flex" alignItems="center">
        {product.tow_months ? (
          <Typography variant="body1" mr={2}>
            Months Covered: {product.tow_months}
          </Typography>
        ) : null}
        <Button variant="text" onClick={handleClickOpen}>
          View Warranty Terms
        </Button>
      </Box>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogContent>{renderWarrantyTerms(warrantyTerms)}</DialogContent>
      </Dialog>
    </>
  );
};

export default WarrantyTerms;
