import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { CompanyCampaignsList } from '../../components/company/campaigns/CompanyCampaignsList';
import IntuPage from '../../components/layout/IntuPage';

function CompanyCampaigns() {
  const { t } = useTranslation();

  return (
    <IntuPage>
      <IntuForm
        form="Campaign Dashboard"
        formTitle={t('pages.company.companyCampaigns.title')}
        lessMT
      >
        <CompanyCampaignsList />
      </IntuForm>
    </IntuPage>
  );
}

export default CompanyCampaigns;
